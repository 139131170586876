import { StorageManager } from 'src/app/common/storage-manager.class';
import { environment } from 'src/environments/environment';

export class FitosanitariosEnvironmentDefinition {
    // ----------------------------------------------------------
    // General
    // ----------------------------------------------------------
    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public serverUrl = window.location.hostname === 'localhost'
        ? ''
        : environment.serverUrl;
    
    public auxServerUrl = environment.serverUrl;
    //-------------------------------------------------------------
    // ------------------------------------------------------------


    // ----------------------------------------------------
    // Actors
    // ----------------------------------------------------
    public hasClients = environment.features.hasClients;
    public clienteName = environment.features.clienteName;
    public hasFincas = environment.features.hasFincas;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public sectoresName = environment.features.sectoresName;
    public parcelasName = environment.features.parcelasName;
    // -----------------------------------------------------
    // -----------------------------------------------------


    // -----------------------------------------------------------------------------
    // Filters
    // -----------------------------------------------------------------------------
    public mostrarTodosTratamientos = environment.features.mostrarTodosTratamientos;
    public showRegistroTratamiento = environment.features.showRegistroTratamiento;
    public tareasFitoFilterBySector = environment.features.tareasFitoFilterBySector;
    public userCanSeeAllRecords = environment.features.userCanSeeAllRecords
        ? true
        : (StorageManager.getUser().rol === 'admin');
    public userCanSeeClientes = environment.features.userCanSeeClientes
        ? true
        : (StorageManager.getUser().rol === 'admin');
    public filterAplicadoresByClient = environment.features.filterAplicadoresByClient;
    // ------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------


    // -----------------------------------------------------------------------------
    // Default Values
    // -----------------------------------------------------------------------------
    public horaFechaFinActualPorDefecto = environment.features.horaFechaFinActualPorDefecto;
    public showTareasMto = environment.features.showTareasMto;
    // ------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------
    

    // ------------------------------------------------------------------------------------------------
    // Fields
    // ------------------------------------------------------------------------------------------------
    public showTareasFitoPuntoKM = environment.features.showTareasFitoPuntoKM; // formFieldsKM

    public showClimatologia = environment.features.showClimatologia;
    public showPhCaldo = environment.features.showPhCaldo;
    public showTareasFincas = environment.features.showTareasFincas;
    public showTareasSector = environment.features.showTareasSector;
    public showTareasFitoSectorMultiple = environment.features.showTareasFitoSectorMultiple;
    public showTareasParcela = environment.features.showTareasParcela;
    public showFitoMomentoAplicacion = environment.features.showFitoMomentoAplicacion;
    public showFitoCosteTratamiento = environment.features.showFitoCosteTratamiento;
    public showFitoTipoTratamiento = environment.features.showFitoTipoTratamiento;
    public showAplicadoSwitch = environment.features.showAplicadoSwitch;
    public showTipoPlaga = environment.features.showTipoPlaga;
    public showMotivoFito = environment.features.showMotivoFito;
    public showTareasFitoObservaciones = environment.features.showTareasFitoObservaciones;
    public showDesplazamientoObra = environment.features.showDesplazamientoObra;
    public showTareasFitoSoleado = environment.features.showTareasFitoSoleado;
    public showTareasFitoCarretera = environment.features.showTareasFitoCarretera;
    public showTareasFitoLitrosAplicados = environment.features.showTareasFitoLitrosAplicados;
    public showTareasFitoHoras = environment.features.showTareasFitoHoras;
    public timeStartStopTareas = environment.features.timeStartStopTareas;
    public showTareasFitoMotivoJustificacion = environment.features.showTareasFitoMotivoJustificacion;
    public showFitoValidado = environment.features.showFitoValidado
        ? (StorageManager.getUser().rol === 'admin')
        : false;
    public showTareasFitoIT = environment.features.showTareasFitoIT;
    public showTareasFitoLote = environment.features.showTareasFitoLote;
    public showTareasFitoMochila = environment.features.showTareasFitoMochila;
    public showTareasFitoAplicacion = environment.features.showTareasFitoAplicacion;
    public showTractorVelocidad = environment.features.showTractorVelocidad;
    public showTractorMarcha = environment.features.showTractorMarcha;
    public showTractorBoquillas = environment.features.showTractorBoquillas;
    public showTractorRPM = environment.features.showTractorRPM;
    public showTractorPresion = environment.features.showTractorPresion;
    public showTractorKmRecorridos = environment.features.showTractorKmRecorridos;
    public showTractorConfig = (
        this.showTractorMarcha ||
        this.showTractorPresion ||
        this.showTractorRPM ||
        this.showTractorBoquillas ||
        this.showTractorVelocidad ||
        this.showTractorKmRecorridos
    );
    public showTareasFitoPuntoKm = environment.features.showTareasFitoPuntoKm;
    public showTareasProcura = environment.features.showTareasProcura;
    public showMomentoAplicacion = environment.features.showMomentoAplicacion;
    public textoSuperficie = environment.features.textoSuperficie;
    public showClientesNotas = environment.features.showClientesNotas;
    public showTareasFitoMaquinarias = environment.features.showTareasFitoMaquinarias;
    public showAssignUserTasksFertilizante = environment.features.showAssignUserTasksFertilizante;
    public showAssignUserTasksFitosanitario = environment.features.showAssignUserTasksFitosanitario;
    public showAssignUserInAllTasks = environment.features.showAssignUserInAllTasks;
    public tareasHorasTotales = environment.features.tareasHorasTotales;
    public showCostes = environment.features.showCostes;
    public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
        ? 'Sup.\xa0Cultivada'
        : 'S.\xa0Sigpac';
    // -------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------


    // ------------------------------------------------------------------------------------------------
    // Form Table
    // ------------------------------------------------------------------------------------------------
    public showProductosMaxAplicacion = environment.features.showProductosMaxAplicacion;
    // -------------------------------------------------------------------------------
    // ------------------------------------------------------------------------------

    public showBtnTratamiento = environment.features.showBtnTratamiento;
    public showAplicados = environment.features.showAplicados;
    public logoUrl: string = environment.logoUrl;
    public showEnviarWhatsapp = environment.features.showEnviarWhatsapp;
    public showEnviarTelegram = environment.features.showEnviarTelegram;
    public showEnviarCorreo = environment.features.showEnviarCorreo;
    public showPrescripcionTratamiento = environment.features.showPrescripcionTratamiento;
    public showRecetaTratamiento = environment.features.showRecetaTratamiento;
    public showRecetaBuitech = environment.features.showRecetaBuitech;
    public showFichaTratamiento = environment.features.showFichaTratamiento;
    public showRegistroTratamientoShort = environment.features.showRegistroTratamientoShort;
    public showRegistroHernandorena = environment.features.showRegistroHernandorena;
    public showRecetaVGiner = environment.features.showRecetaVGiner;
    public showRecetaGlobalGAP = environment.features.showRecetaGlobalGAP;
        
    public showRecetaPascualCabedo = environment.features.showRecetaPascualCabedo;
    public showRecetaLaplana = environment.features.showRecetaLaplana;
    public showRecetaFirmas = environment.features.showRecetaFirmas;
    public showCampaign = environment.features.showTareasFitosanitariasCampaign;
    public betweenDates = environment.features.showTareasFitoBetweenDates;
    
    public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;
    
    public parcelaName = environment.features.parcelaName;
    public empresaName = environment.features.empresaName;
    public canAddTareas = environment.features.canAddTareas;
    public canDuplicateTareas = environment.features.canDuplicateTareas;
    public showTareasBio = environment.features.showTareasBio;
    public showTareasFertilizantes = environment.features.showTareasFertilizantes;
    public showTareasMaquinaria = environment.features.showTareasMaquinaria;
    public showTareasTratamientos = environment.features.showTareasTratamientos;
    public showTareasMateriaActiva = environment.features.showTareasMateriaActiva;
    public tareasTratamientosLabel = environment.features.tareasTratamientosLabel;
    public showFitoFechaReceta = environment.features.showFitoFechaReceta;
    
    public showFitoEficacia = environment.features.showFitoEficacia;
    public showFitoProductosNombres = environment.features.showFitoProductosNombres;
    public showTareasFitoDuracion = environment.features.showTareasFitoDuracion;
    
    public showUsuariosFirma = environment.features.showUsuariosFirma;
    public showClientesFirma = environment.features.showClientesFirma;
    public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;

    public nameReceta: string = environment.features.showRecetaPascualCabedo && 
        this.appName !== 'pascualcabedo' ? 
        'Parte de trabajo' : 
        'Generar Receta';
}// class;