<div id="logo">
    <div *ngIf="env === 'bravosol'" style="background-color: white; padding: 1em;">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'jobarma'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agrogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'ferticinca'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'fitogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'fitogesta3sdi'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px" alt="">
    </div>
    <div *ngIf="env === 'momparler'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px" alt="">
    </div>
    <div *ngIf="env === 'viverogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px" alt="">
    </div>
    <div *ngIf="env === 'malvesia'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            background-color: white;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'gestata'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'donjardin'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            background-color: white;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'gardengest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            background-color: white;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'agromainsa'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'covidai'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'viverosblanco'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'amoros'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'almassora'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'torrepacheco'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agricar'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'lacooperativa'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'onda'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'pyf'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'oliverfutur'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'palomares'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'prosanzcu'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'brio'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'ortizlavado'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'simat'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'lafast'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'icod'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'melia'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'ebro'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'hernandorena'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'pascualcabedo'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px;
            background-color: white;
            padding: .5rem;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'saviatropical'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'manezylozano'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'previlsa'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'buitech'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 250px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'laplana'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px;
            border-radius: 10px;" alt="">
    </div>
    <div *ngIf="env === 'dqvademecum'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px" alt="">
        <br>
        <div class="w-100 d-flex justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="font-size: 1.8rem; text-shadow: 0 0 7px #a5a5a5;">
                dq VADEMECUM
            </div>
        </div>
    </div>
    <div *ngIf="env === 'esparragosgranada'" class="my-2 d-flex justify-content-between align-items-center" style="width: 200px; height: 200px; background-color: white;">
        <img [src]="logoUrl" style="
            position: static;
            display: block;
            margin: 0 auto;
            width: 80%;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'vginer'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'naturfrut'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'innovia'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 300px" alt="">
    </div>
    <div *ngIf="env === 'citrusvol'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'upm'" style="
        margin: 1rem auto;
        padding: 10px;
        border-radius: 3px;
        width: 80vw;
        max-width: 250px;
        background-color: white;
        text-align: center;">
        <img src="assets/logo_upm.png" style="width: 100%; position: static; margin-bottom: 1.3rem;" alt="">
        <img [src]="logoUrl" style="width: 75%; position: static; margin-bottom: 1.4rem;" alt="">
    </div>
    <div *ngIf="env === 'abonosfolques'">
        <img [src]="logoUrl" style="
        display: block;
        margin: 0 auto;
        padding: 10px;
        width: 80vw;
        max-width: 250px;
        background-color: white;" alt="">
    </div>
    <div *ngIf="env === 'armentia'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'ava'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;" alt="">
        <br>
        <div class="w-100 d-flex justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="font-size: 1.8rem; text-shadow: 0 0 7px #a5a5a5;">
                Associació Valenciana d'Agricultors
            </div>
        </div>
    </div>
    <div *ngIf="env === 'avafitogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;" alt="">
        <br>
        <div class="w-100 d-flex justify-content-center">
            <div class="d-flex justify-content-center align-items-center" style="font-size: 1.8rem; text-shadow: 0 0 7px #a5a5a5;">
                Associació Valenciana d'Agricultors
            </div>
        </div>
    </div>
    <div *ngIf="env === 'burriana'">
        <img [src]="logoUrl" style="
                width: 360px;
                position: relative;
                left: 0;" alt="">
    </div>
    <div *ngIf="env === 'ctl'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'fotoagricultura'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'lluchychulbi'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agropecuariaramirogruas'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'viverospereira'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'viverospereirafitosgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'pedrosaez'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'campanaragricola'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'campanaragricolafitosgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'valencianagestionagraria'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'valencianaagrogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'hopsteiner'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'cartagenafresh'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'plantanova'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'bambunova'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'jardinpn'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'gragon'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'verdurasbelloch'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'yagoaznar'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agufert'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agropedbel'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'prodalbar'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'sequiador'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'gfcartera'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'brimelfitosgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'brimelagrogest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'ingenia'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agrosol'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'bravosolfitosgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'intasa'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'coliberica'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'mantengarden'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agrosalvi'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'demogardengest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'mbcpomelos'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'seteo'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'salf'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'servalbert'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'sirga'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'agroleza'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'tousagricola'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'benibal'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'SIEX'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'valdepalma'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'valdepalmafitosgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'democropgest'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'crm'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'gruesnadal'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
    <div *ngIf="env === 'herbesdelmoli'">
        <img [src]="logoUrl" style="
            display: block;
            margin: 0 auto;
            width: 80vw;
            max-width: 200px" alt="">
    </div>
</div>

<div class="login-body">
    <div *ngIf="!showRecuperarContrasena" class="login-page">
        <div class="form text-left">

            <label for="username" class="mb-0 text-secondary">Usuario</label>
            <input *ngIf="!loginWithDropdownUsers.includes(env)" [(ngModel)]="userList.selected" type="text" pInputText name="username" [placeholder]="(env === 'melia') ? 'Usuario (CIF sin guiones)...' : 'Usuario...'" (keyup)="onkeyup($event)">

            <div *ngIf="loginWithDropdownUsers.includes(env)">
                <select *ngIf="userList?.values" [(ngModel)]="userList.selected" #select (change)="setUser()" name="username">
                    <option *ngFor="let user of (userList?.values || [])"
                            [value]="user.value?.id">
                        {{user?.label}}
                    </option>
                </select>
            </div>

            <label for="pass" class="mb-0 text-secondary">Contraseña</label>
            <input [(ngModel)]="password" type="password" pInputText name="pass" placeholder="Contraseña..." (keyup)="onkeyup($event)" class="mb-0">

            <button *ngIf="canRecoverPassword" class="btn btn-link p-0 mb-3" style="font-size: .75rem;" (click)="showRecuperarContrasena = true">
                Quiero recuperar mi contraseña
            </button>

            <p *ngIf="error" class="login-error">Usuario o contraseña incorrectos</p>
            <div *ngIf="canRegister" class="mt-3 small d-flex flex-wrap justify-content-center align-items-baseline">
                <span class="mr-1 text-secondary">¿No está registrado/a?</span>
                <button class="btn btn-link btn-sm p-0" (click)="crearCuenta()">
                    Cree una cuenta
                </button>
            </div>
            <div class="my-2 d-flex justify-content-center">
                <button class="btn btn-dark" (click)="doLogin()" [disabled]="isDoLoginLoading || success">
                    <span *ngIf="!isDoLoginLoading">
                        {{success ? 'Redirigiendo...' : 'Entrar'}}
                    </span>
                    <span *ngIf="isDoLoginLoading"><i class="fa fa-spinner fa-spin" aria-hidden="true"></i> Cargando...</span>
                </button>
            </div>
            <div *ngIf="success" class="m-0 p-0 alert alert-light small text-left">
                Si no se redirige automáticamente, por favor, haga clic
                <a [routerLink]="['dashboard', 'inicio']">aquí</a>.
            </div>
        </div>
    </div>

    <div *ngIf="showRecuperarContrasena" class="card card-outline-secondary" style="width: 400px; margin: 0 auto 60px;">
        <div class="card-header">
            <h5 class="mb-0">Recuperar contraseña</h5>
        </div>
        <div class="card-body">
            <div *ngIf="!codeHasBeenChecked">
                <div class="form-group">
                    <span class="mb-2 form-text small text-muted">
                        Indique su email y le enviaremos un código para que pueda generar una nueva contraseña.
                    </span>
                    <input #passwordRec type="email" class="form-control" id="inputResetPasswordEmail" placeholder="direccion@email.com">
                </div>

                <div class="form-group">
                    <button [disabled]="isSendingEmail" class="btn btn-sm btn-success" (click)="sendPasswordRecovery(passwordRec.value)">
                        <div *ngIf="isSendingEmail" class="spinner-border spinner-border-sm"></div>
                        {{isSendingEmail ? 'Enviando...' : hasSentEmail ? 'Volver a enviar' : 'Enviar email'}}
                    </button>
                    <div *ngIf="hasSentEmail" style="font-size: .7rem;" class="my-2 text-info">
                        Se ha enviado un código al email especificado.
                    </div>
                </div>
            </div>

            <div *ngIf="!codeHasBeenChecked" class="mt-3">
                <label for="code-input" class="form-text small text-muted">
                    ¿Ya tiene su código? Por favor, introdúzcalo aquí:
                </label>
                <div class="form-group">
                    <input #codeInput type="text" id="code-input" class="my-2 form-control" placeholder="Código..." maxlength="8">
                </div>
                <div class="my-3">
                    <button [disabled]="isCheckingCode" class="btn btn-sm btn-success" (click)="sendCode(codeInput.value)">
                        <div *ngIf="isCheckingCode" class="spinner-border spinner-border-sm"></div>
                        {{isCheckingCode ? 'Generando...' : 'Generar'}}
                    </button>
                </div>
                <div *ngIf="codeHasError" style="font-size: .7rem;" class="my-2 text-danger">
                    El código no es válido o ya ha sido utilizado.
                </div>
            </div>

            <div *ngIf="codeHasBeenChecked">
                <form class="form form-login">
                    <label for="pass-input1">Escriba su nueva contraseña:</label>
                    <br />
                    <input #pass1 pInputText type="password" id="pass-input1" />
                    <br />
                    <label for="pass-input2">Repita la contraseña:</label>
                    <br />
                    <input #pass2 pInputText type="password" id="pass-input2" />
                    <br />
                    <br />

                    <button [disabled]="isSendingPassword" class="btn btn-success btn-block" (click)="changePassword(pass1.value, pass2.value)">
                        <div *ngIf="isSendingPassword" class="spinner-border spinner-border-sm"></div>
                        {{isSendingPassword ? 'Enviando...' : 'Cambiar contraseña'}}
                    </button>
                </form>

            </div>

            <div class="my-3 w-100 d-flex justify-content-center">
                <button class="btn btn-sm btn-link" (click)="showRecuperarContrasena = false">Volver</button>
            </div>
        </div>
    </div>
</div>
    <!-- Watermark -->
    <div *ngIf="showWatermark" style="text-align: right;position: fixed;z-index:9999999;bottom: 1%;width: auto;right: 1%;
    cursor: pointer;line-height: 0;display:block !important; opacity: 0.8;">
    <a title="Aplicación creada por Locatec. Todos los derechos reservados." target="_blank" href="https://locatec.es/">
        <img [src]="urlLogoLocatec" alt="Locatec.es">
    </a>
</div>