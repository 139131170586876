import { StorageManager } from './../../../../../common/storage-manager.class';
import { Component, ViewChild } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { RequestButtonComponent } from '../../../../../common/components/request-button/request-button.component';
import { AppFormRequest } from '../../../../../view/app-common/form-request/app-form-request';
import { list, pool, build } from '../../../../../common/classes/request-builder';
import { InputType } from '../../../../../common/components/form-builder/form-builder.component';
import { Filtering } from '../../../../../service/filtering/filtering';
import { ActivatedRoute, Router } from '@angular/router';
import { FormCommonApiService } from '../../../../../service/api/formcommon-api.service';
import { FincasApiService } from '../../../../../service/api/fincas-api.service';
import { DashboardService } from '../../../../../service/events/dashboard.service';
import { BaseForm } from '../../../../../view/base-form';
import { CamposApiService } from '../../../../../service/api/campos-api.service';
import { VisitasVariedadMesApiService } from '../../../../../service/api/visitas_variedad_mes-api.service';
import { Utils } from '../../../../../common/utils';
import { MapaParcelasApiService } from '../../../../../service/api/mapa-parcelas-api.service';

import type { OnInit } from '@angular/core';
import type { 
    FilteredValuesInterface, 
    ItemInterface, TypeAheadInterface 
} from '../../../../../common/components/form-builder/form-builder.component';
import { FullMapModel } from 'src/app/models/form-common/full-map.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
@Component({
    selector: 'app-campos-form',
    templateUrl: './campos-form.component.html',
    styleUrls: ['./campos-form.component.scss']
})
export class CamposFormComponent extends BaseForm<ParcelasModel> implements OnInit {

    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<ParcelasModel>();

    public parcelaName = environment.features.parcelaName;
    public parcelasName = environment.features.parcelasName;
    public showMapas = environment.features.showMapas;
    public uds_medida = StorageManager.getUser().uds_medida;
    public mapaAux = {} as FullMapModel;

    public override model = {} as ParcelasModel;
    public modelCultivo = {} as VisitasVariedadMesModel;
    public submittingCultivo = false;
    public cultivosExistentes: FilteredValuesInterface<VisitasVariedadMesModel>[] = [];
    public cultivoExistente = {} as FilteredValuesInterface<VisitasVariedadMesModel> | null;

    public formRequest = new AppFormRequest<ParcelasModel>();

    public fincas: TypeAheadInterface<FincasModel> = list();
    public parcelas: TypeAheadInterface<ParcelasModel> = list();
    public cultivos: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public variedades: TypeAheadInterface<VisitasVariedadMesModel> = list();

    public patron: TypeAheadInterface<string> = list(
        ['...', null]
    );

    public patronAlmendro: TypeAheadInterface<string> = list(
        ['...', null],
        'Franco de semilla'
    );
    public patronManzano: TypeAheadInterface<string> = list(
        ['...', null],
        // Fuji
        'Kiku',
        'Fubrax',
        'Zhen Aztec',
        'San',
        'September Wonder',
        // Gala
        'Brookfield',
        'Scniga',
        'Vinus',
        'Schnicored',
        // Golden'
        'Delicious',
        'Smoothee',
        'Reinders',
        'Parsi',
        // Granny Smith
        'Challenger',
        // Red Delicious
        'Starking',
        'Golden Rose',
        'Jeromine',
        'Scarlet Spur',
        // Reineta
        'Blanca',
        'Parda',
        // Otros patrones
        'M12',
        'M7',
        'M9',
        'MM106'
    );
    public patronMelocotonero: TypeAheadInterface<string> = list(
        ['...', null],
        'GF-677', 'GxN Garnem', 'Monclart'
    );
    public patronCerezo: TypeAheadInterface<string> = list(
        ['...', null],
        'Adara', 'Avium', 'Cab6', 'Col', 'Marina-Adara', 'Maxma', 'Santa Lucía', 'Santa Lucía 64'
    );
    public patronCiruelo: TypeAheadInterface<string> = list(
        ['...', null],
        'Mariana', 'Melocotonero', 'Mirobolán C'
    );
    public patronCitricos: TypeAheadInterface<string> = list(
        ['...', null],
        'Carrizo', 'Cleopatra', 'Forner Alcaide', 'Macrophylla', 'Naranjo Borde', 'Volkameriana',
    );
    public patronPera: TypeAheadInterface<string> = list(
        ['...', null],
        'BA-29', 'EM-C', 'Franco', 'Membrillero'
    );
    public patronVina: TypeAheadInterface<string> = list( // Portainjerto
        ['...', null],
        'R110', '1103/P', 'RU/140', 'SO4', 'M1', 'M2', 'M3', 'M4'
    );

    public formFieldsDatosParcela: ItemInterface<object>[] = [
        {
            field: 'nombre',
            label: 'Nombre',
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'superficie_sigpac',
            label: 'Sup.\xa0Sigpac\xa0' + this.uds_medida,
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'superficie_cultivada',
            label: 'Sup. Cultivada\xa0' + this.uds_medida,
            inputType: {type: InputType.EDIT_TEXT},
            required: true
        },
        {
            field: 'id_cultivo',
            label: 'Cultivo',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.cultivos,
            valuePrimaryKey: 'id',
            filter: Filtering.distinctBy.bind({field: 'label'}),
            required: true
        },
        {
            field: 'id_variedad',
            label: 'Variedad',
            inputType: {type: InputType.DROPDOWN},
            values: this.variedades,
            valuePrimaryKey: 'id',
            filter: (it: { value: VisitasVariedadMesModel}) => {
                const match = it.value.cultivo === (this.cultivos.selected || {}).cultivo;

                if (match) {
                    this.cultivos.selected = this.cultivos.filtered
                        .filter(c => c && c.value)
                        .map(c => c.value)
                        .find(cultivo => cultivo?.cultivo === it.value.cultivo) as VisitasVariedadMesModel;
                }

                return match;
            },
            required: true
        },
        {
            field: 'marco',
            subField1: 'marco_ancho',
            subField2: 'marco_largo',
            label: 'Marco\xa0(ancho\xa0x\xa0largo)',
            inputType: {type: InputType.TWO_FIELDS_CONCAT},
            separator: 'x',
            required: true
        },
        {
            field: 'anyo_plantacion',
            label: 'Año\xa0de\xa0plantación',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'plantas',
            label: 'Nº\xa0Árboles/Plantas',
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'patron', 
            label: (this.cultivos.selected || {}).cultivo === 'VIÑA' ? 'Portainjertos' : 'Patrón',
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.patron
        }
    ];

    public formFieldsDatosSigpac: ItemInterface<object>[] = [
        {
            field: 'cod_provincia', 
            label: 'Cod. Provincia', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'municipio', 
            label: 'Municipio', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'poligono', 
            label: 'Polígono', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'numero', 
            label: 'Nº Parcela', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            field: 'recinto', 
            label: 'Recinto', 
            inputType: {type: InputType.EDIT_TEXT}
        }
    ];

    public formFieldsCultivos: ItemInterface<object>[] = [
        {
            field: 'cultivo',
            label: 'Cultivo',
            inputType: {type: InputType.EDIT_TEXT},
            required: true,
        },
        {
            field: 'variedad',
            label: 'Variedad',
            inputType: {type: InputType.EDIT_TEXT},
            required: true,
        },
    ];

    public formFieldsVariedades: ItemInterface<object>[] = [
        {
            field: 'id_cultivo',
            label: 'Cultivo',
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.cultivos,
            valuePrimaryKey: 'id',
            filter: Filtering.distinctBy.bind({field: 'label'}),
            required: true
        },
        {
            field: 'variedad',
            label: 'Variedad',
            inputType: {type: InputType.EDIT_TEXT},
            required: true,
        },
    ];

    public showAddCultivos = false;

    public mapaAbierto = false;
    public origin = 'parcelas-form';
    public finca = 'finca';
    public localidad = 'localidad';
    public cultivo = 'cultivo';
    public variedad = 'variedad';
    public parcelaNombre = 'nombre Campo';
    public mapaOpened = false;

    constructor(
        public override route: ActivatedRoute,
        public override router: Router,
        public formApi: FormCommonApiService,
        public fincasApi: FincasApiService,
        public camposApi: CamposApiService,
        public mapaParcelasApi: MapaParcelasApiService,
        public override dashboard: DashboardService,
        public visitasVariedadMesApi: VisitasVariedadMesApiService
    ) {
        super(
            route,
            router,
            dashboard,
            camposApi.campos.PUT,
            camposApi.campos.POST,
            'campos',
            'Editar Campo',
            'Crear Campo'
        );
    }

    ngOnInit() {
        this.postRequest.response(res => {
            if (res && res[0] && this.mapaAux.coordenadas) {
                this.mapaAux.id_parcela = res[0].id;
                this.mapaAux.id_finca = '1';
                this.mapaParcelasApi.mapaParcelasPOST.toPromise([this.mapaAux]);
            }
        });

        this.putRequest.response(res => {
            if (res && res[0] && this.mapaAux.coordenadas) {
                this.mapaAux.id_parcela = res[0].id;
                this.mapaAux.id_finca = '1';
                if (this.mapaAux.id) {
                    this.mapaParcelasApi.mapaParcelasPUT.toPromise(this.mapaAux);
                } else {
                    this.mapaParcelasApi.mapaParcelasPOST.toPromise([this.mapaAux]);
                }
            }
        });

        this.formRequest
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setModel(this.model)
            .setGetRequest(this.camposApi.campos.GET)
            .setPostRequest(this.camposApi.campos.POST)
            .setPutRequest(this.camposApi.campos.PUT)
            .setFormFields(Array.prototype
                .concat(
                    this.formFieldsDatosParcela,
                    this.formFieldsDatosSigpac
                )
            )
            .setFieldsToSend((['id', 'id_cliente', 'marco_ancho', 'marco_largo'])
                .concat(
                    this.formFieldsDatosParcela.map(it => it.field ?? ''),
                    this.formFieldsDatosSigpac.map(it => it.field ?? '')
                )
            );

        this.formRequest.afterLoad(resolve => {
            if (localStorage.getItem('TEMP_CAMPO_DATA')) {
                this.model = JSON.parse(localStorage.getItem('TEMP_CAMPO_DATA') ?? '');
                localStorage.removeItem('TEMP_CAMPO_DATA');
            }
            if (this.model.marco) {
                const parts = this.model.marco.split('x');
                this.model.marco_ancho = parts[0] ?? '';
                this.model.marco_largo = parts[1] ?? '';
                this.formRequest.update();
            }
            if (this.model.uds_medida) {
                this.uds_medida = this.model.uds_medida;
                this.formFieldsDatosParcela.map( campo => {
                    if (campo.field === 'superficie_sigpac') {campo.label = 'Sup.\xa0Sigpac\xa0' + this.uds_medida; }
                    if (campo.field === 'superficie_cultivada') {campo.label = 'Sup. Cultivada\xa0' + this.uds_medida; }
                });
            }
            resolve(true);
        });
        this.formRequest.beforeSend(resolve => {
            const clientId = (StorageManager.getClient() || {}).id;
            const clientIdFromFinca = (this.fincas.selected || {}).id_cliente;
            const clientIdFromForm = this.model.id_cliente;

            this.model.id_cliente =
                clientIdFromFinca ? clientIdFromFinca :
                    clientIdFromForm ? clientIdFromForm :
                        clientId ? clientId : ''; // DEFAULT

            const userId = StorageManager.getUser().id;
            const userIdFromFinca = (this.fincas.selected || {}).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            if (this.model.mapa && this.model.mapa[0]) {
                this.mapaAux = this.model.mapa[0];
            }
            resolve(true);
        });

        this.initFormData();

        this.formRequest.load();
        
        this.softInit(this.getType());
    }


    public submit() {
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }
        this.formRequest.send();
    }

    public submitCultivo() {
        if (this.modelCultivo.cultivo) {
            this.submittingCultivo = true;
            if (!this.modelCultivo.variedad) {
                this.modelCultivo.variedad = this.modelCultivo.cultivo.toLocaleUpperCase() + '1';
            }

            this.visitasVariedadMesApi.visitasVariedadMes.POST.toPromise({
                cultivo: this.modelCultivo.cultivo.toUpperCase(),
                variedad: this.modelCultivo.variedad[0]?.toUpperCase() + this.modelCultivo.variedad.slice(1)
            } as never)
                .then(() => {
                    localStorage.setItem('TEMP_CAMPO_DATA', JSON.stringify(this.model));
                    window.location.reload();
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
        }
    }

    public formChanges(tag: string) {
        this.requestButton.error = '';

        if (tag === 'id_cultivo') {
            const idxPatron = this.formFieldsDatosParcela.map(it => it.field).indexOf('patron');
            const idxCultivo = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_cultivo');

            const patron = this.formFieldsDatosParcela[idxPatron];
            const cultivo = (((
                this.formFieldsDatosParcela[idxCultivo]?.values as 
                    TypeAheadInterface<VisitasVariedadMesModel>).selected)?.cultivo || '').toLowerCase();
            
            if (patron) {
                patron.values = this.getPatron(cultivo);
                this.modelCultivo.cultivo = (cultivo || '').toUpperCase();
                if (cultivo === 'viña') {
                    patron.label = 'Portainjertos';
                } else {
                    patron.label = 'Patrón';
                }
            }
        }

        if (tag === 'marco') {
            this.model.marco = this.model.marco_ancho + 'x' + this.model.marco_largo;
        }
    }

    public formChangesCultivo(tag: string) {
        if (tag === 'cultivo') {
            if ((this.modelCultivo.cultivo || '').length > 1) {
                this.cultivosExistentes = this.cultivos.filtered.slice(0)
                    .filter(it => (it && Utils.removeAccents(it.label)
                        .includes(Utils.removeAccents(this.modelCultivo.cultivo.toUpperCase())))
                    );
            } else {
                this.cultivosExistentes = [];
            }
        }
    }

    public selectCultivoExistente(existente?: FilteredValuesInterface<VisitasVariedadMesModel>) {
        if (existente) { this.cultivoExistente = existente; }
        if (this.cultivoExistente && this.formFieldsVariedades[0]?.values !== undefined) {
            this.modelCultivo.cultivo = this.cultivoExistente.value?.cultivo ?? '';
            (this.formFieldsVariedades[0].values as TypeAheadInterface<VisitasVariedadMesModel>).selected = this.cultivoExistente.value;
            this.cultivoExistente = null;
            this.cultivosExistentes = [];
        }
    }

    public getPatron(cultivo: string) {
        switch (cultivo) {
        case 'cerezo':          return this.patronCerezo;
        case 'ciruelo':         return this.patronCiruelo;
        case 'mandarinos':      return this.patronCitricos;
        case 'naranjos':        return this.patronCitricos;
        case 'lima':            return this.patronCitricos;
        case 'limoneros':       return this.patronCitricos;
        case 'pomelo':          return this.patronCitricos;
        case 'pomelos':         return this.patronCitricos;
        case 'manzano':         return this.patronManzano;
        case 'pera':            return this.patronPera;
        case 'peral':           return this.patronPera;
        case 'viña':            return this.patronVina;
        case 'almendro':        return this.patronAlmendro;
        case 'melocotonero':    return this.patronMelocotonero;
        default:                return this.patron;
        }
    }

    public abreMapa(){
        this.mapaOpened = true;

        if (
            !this.model.nombre ||
            !this.model.id_cultivo ||
            !this.model.id_variedad
        ) {
            alert ('Debe rellenar los campos obligatorios antes de abrir el Mapa.');
            return;
        }

        this.mapaAbierto = !this.mapaAbierto;
        this.finca = this.model.nombre || '';

        if (this.formFieldsDatosParcela[3]) {
            this.cultivo = (this.formFieldsDatosParcela[3].values as TypeAheadInterface<VisitasVariedadMesModel>).selected?.cultivo || '';
            this.variedad = (this.formFieldsDatosParcela[3].values as TypeAheadInterface<VisitasVariedadMesModel>).selected?.variedad || '';
        }

        this.parcelaNombre = this.model.nombre || '';
    }

    public getFromMapa(datos: string){
        const data = JSON.parse(datos);

        const ratio = StorageManager.getUser().ratio_ha;
        if (ratio != null && ratio !== 1) { 
            data.ha = data.ha * (ratio || 1);
            data.ha = Utils.decimalFormat(data.ha, 2);
        }


        this.model.cod_provincia = data.provincia;
        this.model.municipio = data.municipio;
        this.model.poligono = data.poligono;
        this.model.numero = data.parcela;
        this.model.recinto = data.recinto;
        this.model.superficie_sigpac = data.ha;
        this.model.id_cultivo = data.cultivo;
        this.model.id_variedad = data.variedad;
        this.model.sigpac = data.refpar;

        if (!this.model.mapa) {
            this.model.mapa = [{} as FullMapModel];
        }
        if (this.model.mapa[0]) {
            this.model.mapa[0].id_finca = this.model.id_finca ?? '';
            this.model.mapa[0].id_usuario = StorageManager.getUser().id;
            this.model.mapa[0].coordenadas = data.coord;
            this.model.mapa[0].color = data.color;
    
            if (this.parcelaNombre !== '' && this.parcelaNombre != null) {
                this.model.nombre = this.parcelaNombre;
            }
            else {
                this.model.nombre = `${this.parcelaName} ${data.parcela}`;
            }
    
        }
        
        this.mapaAbierto = false;
    }
    
    private initFormData(): Promise<boolean> {
        return pool([
            build(this.fincasApi.fincasGET, this.fincas, ['nombre']),
            build(this.fincasApi.parcelasGET, this.parcelas, ['nombre']),
            build(this.formApi.cultivosGET, this.cultivos, 'cultivo', true),
            build(this.formApi.variedadesGET, this.variedades, ['variedad'], true)
        ], () => {
            setTimeout(() => {
                if (this.model.id_cultivo) {
                    const idxPatron = this.formFieldsDatosParcela.map(it => it.field).indexOf('patron');
                    const idxCultivo = this.formFieldsDatosParcela.map(it => it.field).indexOf('id_cultivo');

                    const patron = this.formFieldsDatosParcela[idxPatron];
                    const cultivo: string = ((
                        this.formFieldsDatosParcela[idxCultivo]?.values as TypeAheadInterface<VisitasVariedadMesModel>)
                        .selected?.cultivo || '')
                        .toLowerCase();

                    if (patron) {
                        patron.values = this.getPatron(cultivo);
                    }
                    this.formRequest.update();
                }
            }, 100);
            this.formRequest.update();
        });
    }
}