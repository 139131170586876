<div class="d-flex justify-content-center" style="font-size: 45px">
  <button (click)="visibleSignature = true;" style="border: none; background-color: none;">
      <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
  </button>
</div>

<p-dialog header="Firma" [(visible)]="visibleSignature" (onShow)="ngAfterViewInit()">
  <div class="signature-container text-center">
    <canvas #signaturePad [width]="canvasWidth" [height]="canvasHeight"></canvas>
  </div>

  <div class="d-flex justify-content-center mt-3">
    <button class="btn btn-dark mx-2" (click)="clearPad()">Limpiar</button>
    <button class="btn btn-success mx-2" (click)="saveSign()">Guardar</button>
    <button class="btn btn-secondary mx-2" (click)="visibleSignature = false">Cerrar</button>
  </div>
</p-dialog>
