import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '../../service/events/dashboard.service';
import { environment } from '../../../environments/environment';
import { ClientesApiService } from '../../service/api/clientes-api.service';
import { StorageManager } from '../../common/storage-manager.class';
import { LoginApiService } from '../../service/api/login-api.service';
import { Filtering } from '../../service/filtering/filtering';
import { InputType } from '../../common/components/form-builder/form-builder.component';
import { AppFormRequest } from '../app-common/form-request/app-form-request';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { list } from '../../common/classes/request-builder';
import moment from 'moment';
import { UserActivityApiService } from '../../service/api/user_activity-api.service';
import SignaturePad from 'signature_pad';
import { UploadApiService } from '../../service/api/upload-api.service';
//import { Chip } from 'primeng/chip';
import { ProductosApiService } from '../../service/api/productos-api';
import { FormCommonApiService } from '../../service/api/formcommon-api.service';
import { FincasApiService } from '../../service/api/fincas-api.service';
import { MenuItemModel } from '../../models/menu-item';
import packageJson from '../../../../package.json';

import type { OnInit } from '@angular/core';
import type {
    ItemInterface,
    TypeAheadInterface,
} from '../../common/components/form-builder/form-builder.component';
import { ClienteModel } from 'src/app/models/cliente.model';
import { catchError, firstValueFrom, map, of } from 'rxjs';
import { BaseResponseModel } from 'src/base-response.model';
import { AnuncioModel } from 'src/app/models/anuncio.model';
import { TieredMenu } from 'primeng/tieredmenu';
import { FileUploadAnswer } from 'src/app/common/utils';
import { VademecumActivity } from 'src/app/models/usuarios/vademecum-activity';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { Uso } from 'src/app/models/productos/uso.model';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    providers: [MessageService],
})
export class DashboardComponent implements OnInit {
  @ViewChild('signaturePadElement', { static: true })
      signaturePadElement!: SignaturePad;

  public currentUser = this.loginApi.getCurrentUser();

  public currentClient = StorageManager.getClient();

  public user = this.currentUser.nombre;
  public user_role = this.currentUser.rol;
  public appName = environment.appName;
  public applicationType = environment.features.applicationType;
  public logoUrl = environment.logoUrl;
  public serverUrl = environment.serverUrl;
  public urlLogoLocatec =
      environment.serverUrl + 'assets/logo-locatec-footer.png';
  public showSidebar = environment.features.showSidebar;
  public showMainMenu = environment.features.showMainMenu;
  public showWatermark = environment.features.showWatermark;
  public showPerfil = environment.features.showPerfil;
  public showPerfilUnidades = environment.features.showPerfilUnidades;
  public showPerfilFincas = environment.features.showPerfilFincas;
  public sdiModuleActive = environment.features.sdiModuleActive;
  public clienteName = environment.features.clienteName;
  public clientesName = environment.features.clientesName;
  public hasClients = environment.features.hasClients;
  public showBackup = environment.features.showBackup;
  public showVademecumActivity = environment.features.showVademecumActivity;
  public clientesMostrarTodosDefault =
      environment.features.clientesMostrarTodosDefault;
  public showUsuariosFirma = environment.features.showUsuariosFirma;
  public urlBlackList = environment.features.urlBlackList;
  public urlRedirect = environment.features.urlRedirect;
  public showVademecumListadoProductos =
      environment.features.showVademecumListadoProductos;
  public userCanSeeClientes = environment.features.userCanSeeClientes
      ? true
      : this.currentUser.rol === 'admin' ||
      this.currentUser.rol === 'responsable';
  public showUsuariosLogs = environment.features.showUsuariosLogs;

  public hasSignature = false;
  public modelSignature: string | null = null;
  public urlSignature =
      this.serverUrl +
    'firmas_usuarios/' +
    (StorageManager.getUser().id || 0) +
    '.png';

  public signaturePadOptions: object = {
      minWidth: 1,
      canvasWidth: 300,
      canvasHeight: 150,
      allowTaint: true,
  };

  // Este comentario es mejor

  public clientHeaderDialog = '';
  public showClientDialog = false;
  public showClientProfile = false;
  public showAdvertisingForm = false;
  public anuncioTexto = '';
  public anuncioImagen: string | ArrayBuffer | null | undefined = null;
  public mostrarAnuncioEmergente = false;
  public showBackupDialog = false;
  public showVademecumDialog = false;
  public showVademecumListadoDialog = false;
  public hasAutocompleted = false;
  public isDownloadingCsv = false;
  public isGeneratingCsv = false;
  public charset = 'windows-1252';

  public visibleItems = 0;
  public validationErrors = '';

  public userActivity: VademecumActivity[] = [];

  public formRequest = new AppFormRequest<VademecumActivity>();
  public fechaEdited = false;
  public actualizando = false;
  public isOnline = false;

  public fincas = list(['...', null]);
  public unidades = list('Hectáreas', 'Hanegadas', 'Taullas');
  public listadoProductos: {
    id?: null | string;
    referencias?: string;
    productos?: string[];
  } = {
          id: null,
          referencias: '',
          productos: [''],
      };
  public productosCSV = StorageManager.getListadoProductosCSV();
  public cabecerasCSV = '';
  public iconRefresh = 'fa fa-refresh';

  public isComunero = false;

  public formFields: ItemInterface<object>[] = [
      {
          label: 'Nombre/Razón social',
          field: 'nombre',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Apellidos',
          field: 'apellidos',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Tipo de carnet',
          field: 'tipo_carnet',
          inputType: { type: InputType.DROPDOWN },
          values: list(
              ['...', null],
              'BÁSICO',
              'CUALIFICADO',
              'FUMIGADOR',
              'PILOTO',
              'ASESOR'
          ),
      },
      {
          label: 'Fecha de caducidad',
          field: 'fecha_caducidad',
          inputType: { type: InputType.CALENDAR },
      },
      {
          label: 'CIF/NIF',
          field: 'nif',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'ROPO',
          field: 'ropo',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'IMEI',
          field: 'imei',
          inputType: { type: InputType.EDIT_TEXT },
          visible: environment.appName === 'manezylozano',
      },
      {
          label: 'Tipo de mando',
          field: 'tipo_mando',
          inputType: { type: InputType.DROPDOWN },
          values: list(
              ['...', null],
              'SISTRONIC',
              'TRONIC',
              'SISTROMATIC',
              'OZONO'
          ),
          visible: environment.appName === 'manezylozano',
      },
  ];
  public formFields2: ItemInterface<object>[] = [
      {
          label: 'Unidad de medida',
          field: 'uds_medida',
          inputType: { type: InputType.DROPDOWN },
          values: this.unidades,
          visible: this.showPerfilUnidades,
      },
      {
          label: 'Relación con una Hectáreas',
          field: 'ratio_ha',
          inputType: { type: InputType.EDIT_NUMERIC },
          placeholder: '1 Hectáreas = X unidades',
          visible: this.showPerfilUnidades,
      },
      {
          label: 'Finca asociada',
          field: 'id_finca',
          valuePrimaryKey: 'id',
          inputType: { type: InputType.DROPDOWN },
          values: this.fincas,
          visible: this.showPerfilFincas,
      },
  ];

  public formClient: ItemInterface<object>[] = [
      {
          label: 'Nombre/Razón social',
          field: 'nombre',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Apellidos',
          field: 'apellidos',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Dirección',
          field: 'direccion',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Código postal',
          field: 'codigo_postal',
          inputType: { type: InputType.EDIT_NUMERIC },
      },
      {
          label: 'Población',
          field: 'localidad',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Provincia',
          field: 'provincia',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'Telefono',
          field: 'telefono',
          inputType: { type: InputType.EDIT_NUMERIC },
      },
      {
          label: 'CIF/NIF',
          field: 'nif',
          inputType: { type: InputType.EDIT_TEXT },
      },
      {
          label: 'ROPO',
          field: 'ropo',
          inputType: { type: InputType.EDIT_TEXT },
      },
  ];

  public popupItems = [
      {
          label: 'Mi perfil',
          icon: 'fa fa-user',
          command: (): void => {
              this.showClientProfile = true;
              if (this.showUsuariosFirma) {
                  this.fetchSignature();
              }
          },
          visible:
        (this.showPerfil && this.currentUser.rol === 'admin') ||
        this.applicationType === 'cropgest'
            ? true
            : false,
      },

      {
          separator: true,
          visible: this.showPerfil && this.currentUser.rol === 'admin',
      },

      {
          label: 'Consulta logs',
          icon: 'fa fa-file',
          command: (): void => {
              this.router.navigate(['/dashboard/colsultas-logs']);
          },
          visible: this.showUsuariosLogs,
      },

      {
          label: 'Publicidad',
          icon: 'fa fa-paper-plane',
          command: (): void => {
              this.showAdvertisingForm = true;
          },
          visible: this.currentUser.rol === 'admin' && this.appName === 'agroleza',
      },
      {
          label:
        this.applicationType === 'cropgest'
            ? 'Descargar datos'
            : 'Copia de seguridad',
          icon: 'fa fa-download',
          command: (): void => {
              this.showBackupDialog = true;
          },
          visible:
        this.showBackup &&
        ((this.applicationType !== 'cropgest' &&
          this.currentUser.rol === 'admin') ||
          (this.applicationType === 'cropgest' &&
            this.currentUser.tipo === 'comunero')),
      },
      {
          label: 'Sincronizar datos',
          icon: this.iconRefresh,
          command: (): void => {
              this.initSyncDataCoop();
          },
          visible: this.appName === 'almassora',
      },
      {
          separator: true,
          visible: this.showBackup && this.currentUser.rol === 'admin',
      },
      {
          label: 'Actividad',
          icon: 'fa fa-list-alt',
          command: (): void => {
              this.showVademecumDialog = true;
              this.getVademecumActivity();
          },
          visible: this.showVademecumActivity && this.currentUser.rol === 'admin',
      },
      {
          separator: true,
          visible: this.showVademecumActivity && this.currentUser.rol === 'admin',
      },
      {
          label: 'Mis productos',
          icon: 'fa fa-flask',
          command: (): void => {
              this.productosApi.listadoVademecum.GET.response((res) => {
                  if (res && res[0] && res[0].id) {
                      this.listadoProductos = res[0];
                      this.listadoProductos.productos = (res[0].referencias || '').split(
                          ';'
                      );
                  }
              });
              this.productosApi.listadoVademecum.GET.safePerform({
                  id_usuario: StorageManager.getUser().id,
              } as never);
              this.showVademecumListadoDialog = true;
          },
          visible:
        this.showVademecumListadoProductos && this.currentUser.rol === 'admin',
      },
      {
          separator: true,
          visible:
        this.showVademecumListadoProductos && this.currentUser.rol === 'admin',
      },
      {
          label: 'Cerrar sesión',
          icon: 'fa fa-sign-out',
          command: (): void => {
              StorageManager.deleteUser();
              StorageManager.deleteClient();
              this.router.navigate(['login']);
          },
      },
      {
          label: `Version: ${packageJson.version}`,
          icon: 'fa fa-code-fork',
      },
  ];

  public clientes: TypeAheadInterface<ClienteModel> = {
      selected: null,
      filtered: [{ label: 'Cargando...', value: null }],
      values: [],
  };

  public header: {
    title: string;
    subtitle: string;
  } = { title: 'Inicio', subtitle: '' };

  public auxContador = 0;

  constructor(
    private router: Router,
    private clientesApi: ClientesApiService,
    public dashboardEvent: DashboardService,
    private userActivityApi: UserActivityApiService,
    private loginApi: LoginApiService,
    private fincasApi: FincasApiService,
    public http: HttpClient,
    private messageService: MessageService,
    private uploadApi: UploadApiService,
    public productosApi: ProductosApiService,
    private formApi: FormCommonApiService
  ) {}

  public static createNavigation(router: Router, route: string = 'inicio') {
      router.navigate(['dashboard', route]);
  }

  ngOnInit() {
      this.visibleItems = this.dashboardEvent.menuItemData.filter(
          (it) => it.visible
      ).length;
      this.isComunero =
      StorageManager.getUser().tipo === 'comunero' ? true : false;

      if (environment.appName === 'donjardin') {
          const swap = (
              val1: MenuItemModel,
              val2: MenuItemModel,
              arr: MenuItemModel[]
          ) => {
              if (!arr.includes(val1) || !arr.includes(val2)) {
                  return;
              }
              const val1Index = arr.indexOf(val1);
              const val2Index = arr.indexOf(val2);
              arr.splice(val1Index, 1, val2);
              arr.splice(val2Index, 1, val1);
          };
          const moduleTask = this.dashboardEvent.menuItemData.filter(
              (item) => item.id === 'tareas'
          );
          swap(
              moduleTask[0] ?? new MenuItemModel(),
              this.dashboardEvent.menuItemData[2] ?? new MenuItemModel(),
              this.dashboardEvent.menuItemData
          );
      }

      if (this.appName === 'coliberica') {
      // Asegúrate de que el índice existe en el array para evitar errores
          if (this.dashboardEvent.menuItemData.length > 19) {
              // Extrae el elemento en la posición 19
              const item = this.dashboardEvent.menuItemData.splice(19, 1)[0];
              // Añade el elemento extraído al final del array
              this.dashboardEvent.menuItemData.push(item ?? new MenuItemModel());
          }
      }

      if (!this.showSidebar || this.visibleItems <= 1) {
          document.getElementById('main-content')?.classList.add('ml-0');
      }

      if (this.sdiModuleActive) {
          firstValueFrom(
              this.http.get(this.serverUrl + 'ws/sdi/update/clientes_update.php')
          )
              .then(() => {
                  console.log('Clientes actualizados desde el ERP.');
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
          firstValueFrom(
              this.http.get(this.serverUrl + 'ws/sdi/update/productos_update.php')
          )
              .then(() => {
                  console.log('Productos actualizados desde el ERP.');
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
      }

      if (this.appName === 'agroleza' && this.user_role === 'user') {
          firstValueFrom(
              this.http.get<BaseResponseModel<AnuncioModel>>(
                  `${this.serverUrl}ws/index.php?p1=generic&p2=anuncios`
              )
          )
              .then((result) => {
                  if (result) {
                      this.anuncioImagen =
              (result.data as AnuncioModel[])[0]?.imagen ?? '';
                      this.anuncioTexto = (result.data as AnuncioModel[])[0]?.texto ?? '';
                  }

                  this.mostrarAnuncioEmergente = true;
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
      }
      this.formRequest.setFormFields(this.formFields.concat(this.formFields2));

      this.setOnlineService();
      this.initClientes();
      if (this.showPerfilFincas) {
          this.initFincas();
      }

      this.dashboardEvent.onChangeClient().subscribe(() => {
          this.clientes.selected = StorageManager.getClient();
          this.initClientes();
      });

      this.dashboardEvent.onDialogChanges().subscribe((open) => {
          if (open) {
              this.changeClient();
          } else {
              this.closeClientDialog();
          }
      });

      this.dashboardEvent.onProfileChanges().subscribe((open) => {
          if (open) {
              this.showClientProfile = true;
          } else {
              this.closeClientProfile();
          }
      });

      if (this.hasClients) {
          if (!StorageManager.getClient()) {
              this.clientHeaderDialog =
          'No hay ningún ' + this.clienteName + ' seleccionado';
              this.showClientDialog =
          environment.appName !== 'gruesnadal' ? true : false;
          } else {
              this.clientHeaderDialog = 'Cambiar ' + this.clienteName;
          }
      }

      if (this.appName === 'gruesnadal') {
          const tablasMaestras = this.getModuleItem();
          tablasMaestras.forEach((tabla: any) => this.popupItems.push(tabla));
      }
  }

  public onNavItemClick(id: string) {
      this.dashboardEvent.onMenuItemClick(id);
  }

  public onSubNavItemClick(id: string) {
      this.dashboardEvent.onSubmenuItemClick(id);
  }

  public changeClient() {
      this.showClientDialog = true;
  }

  public updateClient() {
      StorageManager.saveClient(this.clientes.selected);
      this.dashboardEvent.emitClientChange();
  }

  public toggleMenu(menu: TieredMenu, $event: MouseEvent) {
      menu.toggle($event);
  }

  public closeClientDialog() {
      this.showClientDialog = false;
      this.clientHeaderDialog = 'Cambiar ' + this.clienteName;
  }

  public closeClientProfile() {
      this.showClientProfile = false;
      this.currentUser = StorageManager.getUser();
  }

  public closeAdvertisingForm() {
      this.showAdvertisingForm = false;
  }

  public closeBackupDialog() {
      this.showBackupDialog = false;
  }

  public saveProfileEdit() {
      if (!this.validateForm()) {
          alert(this.validationErrors);
          return;
      }

      this.actualizando = true;

      const headers = new HttpHeaders();
      headers.append('Content-Type:', 'application/json');
      headers.append('Request-Method', 'PUT');

      if (this.isComunero) {
          delete this.currentClient.tipo_carnet;

          firstValueFrom(
              this.http.put(
                  `${this.serverUrl}ws/index.php?p1=generic&p2=clientes&p3=${this.currentUser.id}`,
                  JSON.stringify(this.currentClient),
                  { headers: headers }
              )
          )
              .then(() => {
                  StorageManager.saveClient(this.currentClient);
                  this.actualizando = false;
                  this.showClientProfile = false;
                  this.showSuccess();
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
      } else {
          if (this.fechaEdited) {
              this.currentUser.fecha_caducidad = moment(
                  this.currentUser.fecha_caducidad
              ).format('DD/MM/YYYY');
              this.fechaEdited = false;
          }

          firstValueFrom(
              this.http.put(
                  `${this.serverUrl}ws/index.php?p1=generic&p2=usuarios&p3=${this.currentUser.id}`,
                  JSON.stringify(this.currentUser),
                  { headers: headers }
              )
          )
              .then(() => {
                  StorageManager.saveUser(this.currentUser);
                  this.actualizando = false;
                  this.showClientProfile = false;
                  this.showSuccess();
                  return;
              })
              .then(() => {
                  if (this.showUsuariosFirma) {
                      this.uploadApi.firmasPOST.toPromise({
                          id: StorageManager.getUser().id,
                          dir: 'firmas_usuarios',
                          firma: this.modelSignature ?? '',
                      });
                  }
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
      }
  }

  public postAdvertisement() {
      const headers = new HttpHeaders();
      headers.append('Content-Type:', 'application/json');
      headers.append('Request-Method', 'POST');

      const anuncio = {
          imagen: this.anuncioImagen,
          texto: this.anuncioTexto,
      };
      firstValueFrom(
          this.http.post(
              `${this.serverUrl}ws/index.php?p1=generic&p2=anuncios`,
              JSON.stringify(anuncio),
              { headers: headers }
          )
      )
          .then(() => {
              this.showAdvertisingForm = false;
              this.showSuccess();
              return;
          })
          .catch((e) => {
              console.log('catch en getPosition: ' + e);
          });
  }

  public fileChange(event: Event) {
      const input = event.target as HTMLInputElement;

      if (input.files && input.files.length > 0) {
          const fileList: FileList = input.files;
          if (fileList.length > 0) {
              const file: File = fileList[0] ?? new File([], '');
              const formData: FormData = new FormData();
              console.log(file);
              formData.append('uploadFile', file, file.name);
              const headers = new HttpHeaders();
              headers.append('Content-Type', 'multipart/form-data');
              headers.append('Accept', 'application/json');

              const name: string[] = file.name.split('.');
              const ext = name[name.length - 1];
              const accepted = ['gif', 'jpeg', 'jpg', 'jiff', 'png', 'svg', 'tiff'];
              let cond = false;

              for (const e of accepted) {
                  if (e === ext) {
                      cond = true;
                  }
              }

              if (cond) {
                  this.http
                      .post<FileUploadAnswer>(
                          `${environment.serverUrl}ws/recordatorio/upload_file.php`,
                          formData,
                          { headers: headers }
                      )
                      .pipe(
                          map((data) => {
                              const res = data;
                              this.anuncioImagen = res.fileName;
                          }),
                          catchError((error) => {
                              console.log(error);
                              return of(null);
                          })
                      )
                      .subscribe();
              } else {
                  alert('Formato de imágen no válido.');
                  this.anuncioImagen = '';
              }
          }
      }
  }

  // Adjuntos
  public readUrl(input: HTMLInputElement) {
      input.click();
      if (input.files && input.files[0]) {
          const reader = new FileReader();
          reader.onload = (e) => {
              this.anuncioImagen = e.target?.result ?? '';
          };
          reader.readAsDataURL(input.files[0]);
          const r = this.http
              .post<FileUploadAnswer>(
                  environment.serverUrl + 'ws/recordatorio/upload_file.php',
                  this.anuncioImagen
              )
              .subscribe((res) => {
                  const file = res;
                  this.anuncioImagen = file.fileName;
                  r.unsubscribe();
              });
      }
  }

  public verAdjunto() {
      const myWindow = window.open(
          'about:blank',
          '_blank',
          'width=600, height=600'
      );
      myWindow?.location.assign(
          environment.serverUrl + 'imagenes_publicitarias/' + this.anuncioImagen
      );
      myWindow?.focus();
  }

  public downloadBackup() {
      this.isDownloadingCsv = true;

      const headers = new HttpHeaders();
      headers.append('Content-Type:', 'application/json');
      headers.append('Request-Method', 'POST');

      const formdata = new FormData();
      const auth1 =
      this.currentUser.id +
      '_' +
      this.currentUser.nombre +
      '_' +
      window.btoa('admin');
      const auth2 = window.btoa(auth1);

      formdata.append('user', JSON.stringify(this.currentUser));
      formdata.append('auth', auth2);

      const sub$ = this.http
          .post<{ fileCreated: string; url: string }>(
              `${this.serverUrl}fito_backup/exporttocsv.php`,
              formdata,
              { headers: headers }
          )
          .subscribe((res) => {
              const result = res;
              if (result.fileCreated) {
                  window.location.replace(`${this.serverUrl}fito_backup/${result.url}`);
                  this.showBackupDialog = false;
              } else {
                  this.showFailure();
              }
              sub$.unsubscribe();
              this.isDownloadingCsv = false;
          });

      setTimeout(() => {
          this.isDownloadingCsv = false;
      }, 1500);
  }

  public getVademecumActivity() {
      this.userActivityApi.vademecumActivityGET.safePerform();
      this.userActivityApi.vademecumActivityGET.response(
          (res: VademecumActivity[]) => {
              this.userActivity = res.reverse();
          }
      );
  }

  public downloadVademecumActivity() {
      this.isDownloadingCsv = true;
      let csv =
      'data:text/csv;charset=' +
      this.charset +
      ',\ufeffUsuario;Producto;Referencia;Fecha;Búsqueda;Acción\n';
      this.userActivity.forEach((row) => {
          delete row.id;
          delete row.id_usuario;
          delete row.id_cliente;
          csv += Object.values(row).join(';') + '\n';
      });

      const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;
      csvLink.download = 'actividad_vademecum.csv';
      csvLink.href = encodeURI(csv);
      csvLink.click();

      this.isDownloadingCsv = false;
  }

  public showSuccess() {
      this.messageService.add({
          severity: 'success',
          summary: 'Perfil actualizado',
          detail: 'Se ha actualizado su perfil con éxito.',
      });
  }

  public showFailure() {
      this.messageService.add({
          severity: 'danger',
          summary: 'Error en la descarga',
          detail: 'No se ha podido generar el archivo.',
      });
  }

  public formChanges(tag: string) {
      if (tag === 'fecha_caducidad') {
          this.fechaEdited = true;
      }
      if (tag === 'uds_medida' && this.currentUser.uds_medida === 'Hectáreas') {
          this.currentUser.ratio_ha = 1;
      }
  }

  public validateForm(): boolean {
      this.validationErrors = '';

      // Sólo aplicar si el usuario tiene el campo `uds_medida` activo.
      if (this.showPerfilUnidades) {
          if (
              this.currentUser.uds_medida !== 'Hectáreas' &&
        !this.currentUser.ratio_ha
          ) {
              this.validationErrors +=
          '\n- Debe especificar la proporción de ' +
          this.currentUser.uds_medida +
          ' respecto a una Hectáreas.';
              return false;
          }
      }

      return true;
  }

  public scrollToTop() {
      const elements = document.getElementsByClassName(
          'ui-dialog-content ui-widget-content'
      );
      if (elements && elements[0]) {
          setTimeout(() => {
              if (elements[0]) {
                  elements[0].scrollTop = 0;
              }
          }, 100);
      }
  }

  // Sigpad
  public drawComplete(sigpad: SignaturePad) {
      this.modelSignature = sigpad.toDataURL('image/png');
  }
  public clearSigPad(sigpad: SignaturePad) {
      sigpad.clear();
      this.modelSignature = null;
  }
  public async fetchSignature() {
      const resSignature = await fetch(this.urlSignature);
      if (resSignature.status === 200) {
          this.hasSignature = true;
          const resBlob = await resSignature.blob();
          const urlCreator = window.URL;
          this.signaturePadElement?.fromDataURL(
              urlCreator.createObjectURL(resBlob)
          );
      }
      return;
  }

  /*public autocompleteProducto(pChips: Chip, event: KeyboardEvent) {
        const numRegistros: string[] = [];
        const element: HTMLInputElement = pChips._chipProps.label;

        const found: string = numRegistros.find(it => it.indexOf(element.value) === 0) ?? '';
        let totalLength = 0;
        if (found && element.value && (event.key !== 'Backspace')) {
            totalLength = found.length;
            const autocompletedString = found.substring(element.value.length, totalLength - element.value.length);

            element.value = found;
            element.setSelectionRange(totalLength - autocompletedString.length, totalLength);

            this.hasAutocompleted = true;
        } else {
            this.hasAutocompleted = false;
        }
    }*/

  public saveListadoProductos() {
      this.listadoProductos.referencias = (
          this.listadoProductos.productos || []
      ).join(';');
      if (this.listadoProductos.id) {
          this.productosApi.listadoVademecum.PUT.safePerform({
              id: this.listadoProductos.id,
              referencias: this.listadoProductos.referencias,
          } as never);
      } else {
          this.productosApi.listadoVademecum.POST.response((res) => {
              if (res && res[0]) {
                  this.listadoProductos.id = res[0].id ?? '';
              }
          });
          this.productosApi.listadoVademecum.POST.safePerform({
              referencias: this.listadoProductos.referencias,
          } as never);
      }
  }

  public pasteListadoProductos() {
      window.navigator.clipboard
          .readText()
          .then((value: string) => {
              if (
                  confirm(
                      'Por favor, asegúrese de que está copiando las referencias correctas y' +
              ' de que éstas estén separadas por una coma (,), un punto y coma (;), un solo espacio o' +
              ' que cada referencia esté en' +
              ' una línea separada (por ejemplo, si copia una columna de un archivo Excel).' +
              '\n\n' +
              'Texto copiado:' +
              '\n\n' +
              value
                  )
              ) {
                  const separadores = [',', ';', ' ', '\n'];
                  const masRepetido = {
                      separador: '',
                      veces: 0,
                  };

                  separadores.forEach((separador) => {
                      const regex = new RegExp(separador, 'g');
                      const count = ((value || '').match(regex) || []).length;

                      if (count > masRepetido.veces) {
                          masRepetido.separador = separador;
                          masRepetido.veces = count;
                      }
                  });

                  if (masRepetido.veces > 0) {
                      const productosAnadidos: string[] = (value || '')
                          .split(masRepetido.separador)
                          .filter((it: string) => it)
                          .map((it: string) => it.trim());
                      this.listadoProductos.productos = (
                          this.listadoProductos.productos || Array.prototype
                      ).concat(productosAnadidos);
                  }
              }
              return;
          })
          .catch((e) => {
              console.log('catch en getPosition: ' + e);
          });
  }

  public printListadoProductos() {
      this.isDownloadingCsv = true;
      this.isGeneratingCsv = true;

      const referenciasCSV = (this.productosCSV || []).map((it) => it.referencia);
      this.listadoProductos.productos?.forEach((prod: string) => {
          if (!referenciasCSV.includes(+prod)) {
              this.formApi.productosMasUsosGET
                  .toPromise({ p3: '"' + prod + '"' } as never)
                  .then((res) => {
                      if (res) {
                          if (res[0]) {
                              this.productosCSV.push({
                                  referencia: prod,
                                  usos: res,
                              } as never);
                          } else {
                              this.productosCSV.push({ referencia: prod, usos: [] } as never);
                          }
                      }
                      if (
                          this.productosCSV.length ===
                this.listadoProductos.productos?.length &&
              this.isDownloadingCsv
                      ) {
                          this.isGeneratingCsv = false;
                          this.saveListadoProductosCSV();
                      }
                      return;
                  })
                  .catch((e) => {
                      console.log('catch en getPosition: ' + e);
                  });
          }
      });
  }

  public saveListadoProductosCSV() {
      if (!this.productosCSV || this.productosCSV.length === 0) {
          alert('No se ha guardado ningún producto.');
          return;
      }
      let csvContent = 'data:text/csv;charset=' + this.charset + ',\ufeff';
      const ignoredFields = ['id', 'codigo_actualizacion', 'fecha_ficha'];
      const productosToSave = this.productosCSV.map((it) => it.usos);
      const patt = new RegExp('^\\d\\d?-\\d\\d?$');

      Object.keys(productosToSave[0]?.[0] ?? {}).forEach((key) => {
          if (!ignoredFields.includes(key)) {
              csvContent += key + ';';
          }
      });
      csvContent = csvContent.substring(0, csvContent.length - 1) + '\n';

      productosToSave.forEach((preRow) => {
          (preRow as Uso[]).forEach((row: Uso) => {
              Object.keys(row).forEach((key) => {
                  if (!ignoredFields.includes(key)) {
                      if (patt.test(row[key]?.toString() ?? '')) {
                          row[key] = '\'' + row[key];
                      }
                      csvContent +=
              '"' + (row[key] || '').toString().replace(/"/g, '\\"') + '";';
                  }
              });
              csvContent = csvContent.substring(0, csvContent.length - 1) + '\n';
          });
      });

      const csvLink = document.getElementById('csvLink') as HTMLAnchorElement;

      csvLink.download = 'productos.csv';
      csvLink.href = encodeURI(csvContent);
      csvLink.click();

      this.isDownloadingCsv = false;
  }

  // Pyf
  public verFirma() {
      const myWindow = window.open(
          'about:blank',
          '_blank',
          'width=600, height=600'
      );
      myWindow?.location.assign(
          environment.serverUrl + 'firmas_asesor/' + this.currentUser.firma
      );
      myWindow?.focus();
  }

  public async initSyncDataCoop() {
      this.iconRefresh = 'fa fa-refresh fa-spin';
      return firstValueFrom(
          this.http.get(environment.serverUrl + 'ws/sincro_inges/sincroniza.php')
      )
          .then((resp) => {
              console.log({ resp });

              alert('Se han actualizado los datos de clientes,fincas,parcelas');
              return;
          })
          .then(async () => {
              await firstValueFrom(
                  this.http.get(
                      environment.serverUrl +
              'ws/sincro_inges/tratamientos_fito_update.php'
                  )
              );
              alert(
                  'Se han actualizado los datos tareas fitosanitarias y tratamientos fitosanitarias'
              );
              this.iconRefresh = 'fa fa-refresh';
              return;
          })
          .catch(() => {
              console.log('Error en la conexión');
              this.iconRefresh = 'fa fa-refresh';
          });
  }

  private initClientes() {
      if (
          StorageManager.getClient() !== null ||
      StorageManager.getClient() !== undefined
      ) {
          const cliente = StorageManager.getClient();
          this.clientes.selected = cliente;
      }

      const user = StorageManager.getUser();

      if (user.tipo === 'comunero' && !StorageManager.getClient()) {
          this.clientesApi.clientesGET
              .toPromise({ p3: user.id_cliente } as never)
              .then((cliente) => {
                  if (cliente[0]) {
                      cliente[0].id_usuario = user.id;
                      this.clientes.selected = cliente[0];
                      this.updateClient();
                  }
                  return;
              })
              .catch((e) => {
                  console.log('catch en getPosition: ' + e);
              });
      } else {
          this.clientesApi.clientesGET.response((value) => {
              if (value) {
                  if (
                      user.rol === 'admin' ||
            user.rol === 'responsable' ||
            this.clientesMostrarTodosDefault
                  ) {
                      this.clientes.filtered = value
                          .filter((it: ClienteModel) => it)
                          .map((it: ClienteModel) => ({
                              label:
                  it.razon_social ||
                  it.nombre + (it.apellidos != null ? ' ' + it.apellidos : ''),
                              value: it,
                          }))
                          .sort(Filtering.sort('label', 1));
                  } else {
                      if (this.appName === 'innovia') {
                          this.clientes.filtered = value
                              .filter((it: ClienteModel) => it)
                              .filter((it: ClienteModel) => [it.id].includes(user.id_cliente))
                              .map((it: ClienteModel) => ({
                                  label:
                    it.razon_social ||
                    it.nombre +
                      (it.apellidos != null ? ' ' + it.apellidos : ''),
                                  value: it,
                              }))
                              .sort(Filtering.sort('label', 1));
                      } else {
                          this.clientes.filtered = value
                              .filter((it: ClienteModel) => it)
                              .filter((it: ClienteModel) =>
                                  [it.id_usuario_asignado, it.id_usuario_asignado2].includes(
                                      user.id
                                  )
                              )
                              .map((it: ClienteModel) => ({
                                  label:
                    it.razon_social ||
                    it.nombre +
                      (it.apellidos != null ? ' ' + it.apellidos : ''),
                                  value: it,
                              }))
                              .sort(Filtering.sort('label', 1));
                      } // if();
                  } // if();
                  if (
                      StorageManager.getUser().rol.localeCompare('user') === 0 &&
            (environment.appName.localeCompare('pascualcabedo') === 0 ||
              environment.appName.localeCompare('innovia') === 0)
                  ) {
                      this.closeClientDialog();
                  } else {
                      this.clientes.filtered.unshift({
                          label: 'TODOS LOS ' + this.clientesName.toUpperCase(),
                          value: null,
                      });
                  } // if();
              }
          });
      }

      setTimeout(() => {
          if (
              StorageManager.getUser().rol.localeCompare('user') === 0 &&
        (environment.appName.localeCompare('pascualcabedo') === 0 ||
          environment.appName.localeCompare('innovia') === 0)
          ) {
              const cliente =
          this.clientes.filtered[0]?.value ?? ({} as ClienteModel);
              this.clientes.selected = cliente;

              if (cliente == null) {
                  this.updateClient();
              } else {
                  if (cliente.id == null) {
                      this.updateClient();
                  } else {
                      if (this.auxContador === 0) {
                          this.auxContador++;
                          this.updateClient();
                      }
                  }
              }
          } else {
              this.clientes.filtered.unshift({
                  label: 'TODOS LOS ' + this.clientesName.toUpperCase(),
                  value: null,
              });

              if (StorageManager.getClient()?.id !== this.clientes.selected?.id) {
                  this.clientes.selected = StorageManager.getClient();
                  this.updateClient();
              }
          }
          this.clientesApi.clientesGET.safePerform();
      }, 100);
  }

  private initFincas() {
      this.fincasApi.fincasGET.response((fincas) => {
          fincas.unshift({ label: '...', value: null } as never);
          this.fincas = {
              filtered: fincas.map((it: FincasModel) => ({
                  label: it.nombre,
                  value: it,
              })),
              selected: fincas.find(
                  (it: FincasModel) => +it.id === this.currentUser.id_finca
              ),
              values: [],
          };
          const idx = this.formFields2.map((it) => it.field).indexOf('id_finca');
          (this.formFields2[idx] as ItemInterface<object>).values = this.fincas;
      });
      this.fincasApi.fincasGET.safePerform();
  }

  private setOnlineService() {
      this.isOnline = navigator.onLine;

      window.addEventListener('online', () => {
          this.isOnline = true;
      });

      window.addEventListener('offline', () => {
          this.isOnline = false;
      });
  }

  private getModuleItem(): any {
      const modules = [
          {
              label: 'Albaranes',
              icon: 'bi bi-truck',
              command: (): void => {
                  this.router.navigate(['dashboard/albaranes']);
              },
          },
      ];
      if (this.user_role === 'admin') {
          modules.push(
              {
                  label: 'Chofers',
                  icon: 'bi bi-truck',
                  command: (): void => {
                      this.router.navigate(['dashboard/chofer']);
                  },
              },
              {
                  label: 'Clientes',
                  icon: 'bi bi-person',
                  command: (): void => {
                      this.router.navigate(['dashboard/albaran-cliente']);
                  },
              },
              {
                  label: 'Maquinaria',
                  icon: 'bi bi-tools',
                  command: (): void => {
                      this.router.navigate(['dashboard/albaran-maquinaria']);
                  },
              },
              {
                  label: 'Obras',
                  icon: 'bi bi-cone',
                  command: (): void => {
                      this.router.navigate(['dashboard/obras']);
                  },
              }
          );
      }

      return modules;
  }
}
