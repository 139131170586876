<div>
    <br>
    <div style="width: auto; overflow: auto">
        <app-common-list-table
                [request]="trabajadoresApi.partes"
                [minWidth]="800"
                [cols]="cols"
                [filtering]="filter"
                (emitSearchText)="updateFooter()"
                [globalConditionalStyle]="globalConditionalStyle"
                [globalVisualTransform]="globalVisualTransform">

            <ng-template #header>
                <div class="d-flex justify-content-between align-items-center">
                    <p-datePicker [ngStyle]="{marginLeft: '10px'}"
                                [(ngModel)]="date"
                                appendTo="body"
                                [locale]="es"
                                [yearNavigator]="true"
                                view="month"
                                dateFormat="MM, yy"
                                yearRange="2020:2029"
                                (onSelect)="buscar()">
                    </p-datePicker>
                    <div>
                        <button style="margin-left: 10px"
                                class="btn btn-sm btn-primary"
                                [disabled]="!date"
                                (click)="buscar()">
                            <i class="fa fa-undo"></i>
                        </button>
                    </div>

                    <div *ngIf="appName === 'naturfrut'" class="d-flex justify-content-start align-items-center">
                        <span class="m-1">
                            Almacén
                        </span>
                        <div style="font-size: 0.7rem" class="btn-group">
                            <button (click)="formChanges('es-almacen')"
                                [ngClass]="almacenFilter === 'es-almacen' ? 'btn btn-primary' : 'btn btn-light'"
                                style="
                                    height: 28px;
                                    width: 50px;
                                    border: 1px solid grey;
                                    border-right: 1px solid grey">
                                SÍ
                            </button>
                            <button (click)="formChanges('no-es-almacen')"
                                [ngClass]="almacenFilter === 'no-es-almacen' ? 'btn btn-primary' : 'btn btn-light'"
                                style="
                                    height: 28px;
                                    width: 50px;
                                    border: 1px solid grey;
                                    border-right: 1px solid grey">
                                NO
                            </button>
                        </div>
                    </div>
                </div>
            </ng-template>

            <ng-template #thEnd>
                <th>
                    <p class="mb-0 d-flex justify-content-center">Registro</p>
                </th>
            </ng-template>

            <ng-template #tdEnd let-data>
                <td>
                    <div class="d-flex justify-content-center align-items-center">
                        <button class="d-flex justify-content-center align-items-center"
                             (click)="generarRegistro(data)"
                             style="
                                height: 20px;
                                width: 20px;
                                border: none;
                                border-radius: 3px;
                                background-color: #007b00;
                                margin-right: 10px;
                                color: white;
                                cursor: pointer;">
                            <i class="fa fa-file-pdf-o"></i>
                        </button>
                    </div>
                </td>
            </ng-template>

            <ng-template #footer let-col>
                <div [ngSwitch]="col.field">
                    <div *ngSwitchCase="'finca'" style="font-size: 0.75em" class="text-left">
                        TOTAL:
                    </div>
                    <div *ngSwitchCase="'precio_mes'" style="font-size: 0.75em" class="text-center">
                        {{col.footer}} €
                    </div>
                </div>
            </ng-template>
        </app-common-list-table>
    </div>
</div>


<!--  -->
<p-dialog header="Resumen mensual" [(visible)]="showRegistro" (onShow)="scrollToTop()">

    <div *ngIf="showRegistro" class="bg-white" style="width: 80vw !important;">
        <div id="html2canvasDiv" class="mx-auto" style="max-width: 1000px; padding: 3vw;">
            <table class="mb-3 registro-table td-50 table-hover">
                <tr>
                    <td colspan="2" class="p-2 font-weight-bold text-center"
                        style="font-size: 16px; border: none;">
                        Listado Resumen mensual del registro de jornada
                    </td>
                </tr>
                <tr>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>Empresa:</div>
                            <div>{{empresaName}}</div>
                        </div>
                    </td>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>Trabajador:</div>
                            <div>{{registro.nombre}}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>C.I.F./N.I.F.:</div>
                            <div>{{empresaCIF}}</div>
                        </div>
                    </td>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>NIF:</div>
                            <div>{{registro.nif}}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="p-1">Centro de Trabajo:</td>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>Nº Afiliación:</div>
                            <div>{{registro.numss}}</div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>C.C.C.:</div>
                            <div>{{empresaCCC}}</div>
                        </div>
                    </td>
                    <td class="p-1">
                        <div class="d-flex justify-content-between">
                            <div>Mes y Año:</div>
                            <div>
                                {{
                                    (((registro.fecha?.months || 0) + 1) < 10 ? '0' : '') +
                                    '' + ((registro.fecha?.months ?? 0) + 1)
                                    + '/' + registro.fecha?.years
                                }}
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
            <div style="text-align: right;">
                <p style="font-size: 12px;" *ngIf="appName==='covidai'">* De 9:30 a 10:00 parada bocadillo</p>
            </div>

            <table class="mb-3 text-center registro-table table-hover">
                <tr class="thead-like">
                    <td rowspan="2" class="p-2">Día</td>
                    <td rowspan="2" class="p-2">Entrada</td>
                    <td rowspan="2" class="p-2">Salida</td>
                    <td rowspan="2" class="p-2">Entrada</td>
                    <td rowspan="2" class="p-2">Salida</td>
                    <td rowspan="2" class="p-2">Horas ordinarias</td>
                    <td colspan="2" class="p-1">Horas complementarias</td>
                </tr>
                <tr class="thead-like">
                    <td class="p-1">Pactadas</td>
                    <td class="p-1">Voluntarias</td>
                </tr>
                <tbody *ngIf="registro?.fichajes">
                    
                    <tr *ngFor="let i of diasMes">
                        <td class="p-1">{{i}}</td>
                        <td class="p-1">
                            <span *ngIf="registro.fichajes[+i]">
                                {{registro.fichajes[+i]?.horaEntrada}}
                            </span>
                        </td>
                        <td class="p-1">
                            <span *ngIf="registro.fichajes[+i]">
                                {{registro.fichajes[+i]?.horaSalida}}
                            </span>
                        </td>
                        <td class="p-1">
                            <span *ngIf="registro.fichajes[+i]">
                                {{registro.fichajes[+i]?.horaEntradaTarde}}
                            </span>
                        </td>
                        <td class="p-1">
                            <span *ngIf="registro.fichajes[+i]">
                                {{registro.fichajes[+i]?.horaSalidaTarde}}
                            </span>
                        </td>
                        <!-- <td class="p-1">{{['0', 0, Infinity, NaN, false].includes(registro[i]) ? '' : registro[i]}}</td> -->
                        <td class="p-1" *ngIf="!showFormatHourMinute ">{{( registro[i] || ''.includes('.')) ? decimalFormat(+(registro[i] || 0), 1) : registro[i]}} </td>
                        <!-- <td class="p-1" *ngIf="showFormatHourMinute">{{ registro[i]  }} </td> -->
                        <td class="p-1" *ngIf="showFormatHourMinute">{{ registro.fichajes[+i]?.['totalHoras']  }} </td>

                        <!--<td class="p-1" *ngIf="showFormatHourMinute && this.appName!=='fotoagricultura'">{{'dias_aux'+registro[i]  }} </td>-->
                        <td class="p-1"></td>
                        <td class="p-1"></td>
                    </tr>
                </tbody>
                <tfoot>
                    <td class="p-2">TOTAL</td>
                    <td class="p-2"></td>
                    <td class="p-2"></td>
                    <td class="p-2"></td>
                    <td class="p-2"></td>
                    <td class="p-2" *ngIf="!showFormatHourMinute">{{['0', 0, 'Infinity', 'NaN', false].includes(registro.total ?? '') ? '' : registro.total}}</td>
                    <td class="p-2" *ngIf="showFormatHourMinute">{{hoursTest}}</td>
                    <td class="p-2"></td>
                    <td class="p-2"></td>
                </tfoot>
            </table>

            <div class="d-flex justify-content-around" style="font-size: .8rem; min-height: 25vh;">
                <span>
                    Firma de la empresa:
                    <br>
                    <img *ngIf="cunyoUrl" [src]="cunyoUrl" alt="Cuño de la empresa" style="width: 190px; margin-top: 50px;"/>
                </span>
                <span>
                    Firma del trabajador:
                </span>
            </div>

            <div class="mb-5 w-100 d-flex justify-content-between" style="font-size: .8rem;">
                <div style="flex: 2" class="d-flex justify-content-between">
                    <span>En </span>
                    <span class="mx-2" style="flex: 4; border-bottom: 1px solid #0c0c0c;">
                        {{empresaLocation || ''}}
                    </span>
                    <span style="flex: 1">, a</span>
                </div>
                <div style="flex: 3" class="d-flex justify-content-between">
                    <span class="text-center" style="flex: 2; border-bottom: 1px solid #0c0c0c;">
                        1
                    </span>
                    <span class="mx-2">de</span>
                    <span class="text-center" style="flex: 3; border-bottom: 1px solid #0c0c0c;">
                        {{registro.month}}
                    </span>
                    <span class="mx-2">de</span>
                    <span class="pl-2" style="flex: 2; border-bottom: 1px solid #0c0c0c;">
                        {{registro.year}}
                    </span>
                </div>
            </div>

            <div class="mb-5" style="font-size: .55rem;">
                Registro realizado en cumplimiento de la letra h) del artículo 1 del R.D.-Ley 16/2013, 
                de 20 de diciembre por el que se modifica el artículo 12.5 del E.T., por el que se establece 
                que “La jornada de los trabajadores a tiempo parcial se registrará día a día y se totalizará 
                mensualmente, entregando copia al trabajador, junto con el recibo de salarios, del resumen de 
                todas las horas realizadas en cada mes, tanto de las ordinarias como de las complementarias 
                en sus distintas modalidades.
                <br><br>
                El empresario deberá conservar los resúmenes mensuales de los registros de jornada durante un 
                período mínimo de cuatro años. El incumplimiento empresarial de estas obligaciones de registro 
                tendrá por consecuencia jurídica la de que el contrato se presuma celebrado a jornada completa, 
                salvo prueba en contrario que acredite el carácter parcial de los servicios.
            </div>

        </div>

        <div class="d-flex justify-content-center">
            <button class="btn btn-dark mx-2" (click)="showRegistro = false;">Cerrar</button>
            <button class="btn btn-primary mx-2" (click)="imprimirRegistro();"
                [disabled]="imprimiendoRegistro">
                Imprimir
            </button>
        </div>
    </div>
    <br>

</p-dialog>

<a href="#" id="downloadReceta" style="visibility: hidden; position: fixed;"></a>

