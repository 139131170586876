import { CustomHTMLElement, Utils } from '../../../../common/utils';
import { Component } from '@angular/core';
import moment from 'moment';
import { Router } from '@angular/router';
import { DashboardService } from '../../../../service/events/dashboard.service';
import { RequestHandler } from '../../../../service/OffService/request-handler';
import { environment } from 'src/environments/environment';
import { StorageManager } from '../../../../common/storage-manager.class';
import { firstValueFrom, Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseView } from 'src/app/view/base-view';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { TareasApiService } from 'src/app/service/api/tareas-api.service';

import type { OnInit, AfterViewInit } from '@angular/core';
import { CalendarOptions, EventClickArg, EventMountArg } from '@fullcalendar/core';
import { CalendarioTareaModel } from 'src/app/models/tareas/calendario-tarea.model';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { TareaFitoModel } from 'src/app/models/tareas/tarea-fito.model';
import { TareaFertiModel } from 'src/app/models/tareas/tarea-ferti.model';
import { TareasBioModel } from 'src/app/models/tareas/tareas-bio.model';
import { BaseResponseModel } from 'src/base-response.model';

@Component({
    selector: 'app-calendario-tareas',
    templateUrl: './calendario-tareas.component.html',
    styleUrls: ['./calendario-tareas.component.scss']
})
export class CalendarioTareasComponent extends BaseView<CalendarioTareaModel> implements OnInit, AfterViewInit {

    public hasClients = environment.features.hasClients;
    public hasFincas = environment.features.hasFincas;
    public clienteName = environment.features.clienteName;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;

    public tareasFitosanitarias = environment.features.showTareasFitosanitarias;
    public tareasFertilizantes = environment.features.showTareasFertilizantes;
    public tareasSiembra = environment.features.showTareasSiembra;
    public tareasBio = environment.features.showTareasBio;
    public tareasGenericas = environment.features.showTareasOtros;

    public appName = environment.appName;    
    public isAdmin = (StorageManager.getUser().rol === 'admin');
    public deleteRequestFito: RequestHandler<TareaFitoModel> = this.tareasApi.fitosanitariosDELETE;
    public deleteRequestFerti: RequestHandler<TareaFertiModel> = this.tareasApi.fertilizantes.DELETE;
    public deleteRequestBio: RequestHandler<TareasBioModel> = this.tareasApi.bio.DELETE;
    
    public tareaSeleccionada: CalendarioTareaModel = {};
    public tareasArray: CalendarioTareaModel[] = [];
    public tareasArrayFiltered: CalendarioTareaModel[] = [];
    public tareas: CalendarioTareaModel[] = [];

    public clientChange: Subscription = new Subscription();
    public isMobile = false;

    public date: Date = new Date();
    public fechaSel = '';
    public tareaDia = [];
    public showEditTarea = false;
    public showEliminaTarea = false;

    public cambiaEstadoLabel = '';
    public cambiaEstadoColor = '';
    public cambiandoEstado = false;
    
    public selector: Element | undefined;

    public options: CalendarOptions = {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        locale: esLocale,
        height: 640,
        aspectRatio: 0.8,
        editable: true,
        eventClick: (event: EventClickArg) =>  { 
            this.irAlEvento(event);
        },
        dateClick: () => {
            this.irAlDia();
        },
        eventDidMount: function (info: EventMountArg) {
            if ((info.el as CustomHTMLElement).fcSeg.eventRange.def.title) {
                const a = info.el.getElementsByClassName('fc-event-title');
    
                if (a[0]) {
                    a[0].innerHTML = (info.el as CustomHTMLElement).fcSeg.eventRange.def.title;
                }
                
                if (info.event._def.extendedProps['filter']) {
                    const filtro = StorageManager.getCalendarFilter();
                    if (filtro && filtro !== 'Todos' && filtro !== info.event._def.extendedProps['filter']) {
                        info.event.setProp('display', 'none');
                    }
                }
            }
        }
    };

    public options2 = {
        height: 400,
        width: 400,
        aspectRatio: 0.4
    };

    public months = [
        {name: 'Enero', value: 1},
        {name: 'Febrero', value: 2},
        {name: 'Marzo', value: 3},
        {name: 'Abril', value: 4},
        {name: 'Mayo', value: 5},
        {name: 'Junio', value: 6},
        {name: 'Julio', value: 7},
        {name: 'Agosto', value: 8},
        {name: 'Septiembre', value: 9},
        {name: 'Octubre', value: 10},
        {name: 'Noviembre', value: 11},
        {name: 'Diciembre', value: 12},
    ];
    public years: {name: string, value: number}[] = [];
    
    public tipos = [
        {name: 'Agro', value: 'Agro'},
        {name: 'Garden', value: 'Garden'},
        {name: 'Todos', value: 'Todos'},
    ];
    public selectedMonth = '';
    public selectedYear = '';
    public selectedTipo = '';
    
    constructor(
        private tareasApi: TareasApiService,
        private dashboard: DashboardService,
        private router: Router,
        public http: HttpClient
    ) {
        super(dashboard, tareasApi.calendarioGET);
        for (let i = 2017; i <= 2025; i++) {
            this.years.push( {name: i.toString(), value: i} );
        }
    }

    ngOnInit() {
        this.isMobile = (
            ((navigator.userAgent.match('iPad') || []).length > 0) ||
            ((navigator.userAgent.match('iPhone') || []).length > 0) ||
            ((navigator.userAgent.match('iPod') || []).length > 0) || 
            ((navigator.userAgent.match('Android') || []).length > 0)
        );

        this.selectedTipo = StorageManager.getCalendarFilter();
        this.dashboard.setSubmenuItem('calendario-tareas');

        this.dashboard.clientChanges$.subscribe(() => {
            this.filtrarPorTipoCliente();
        });

        this.dashboard.changeClientActive = true;

        if (localStorage.getItem('tarea_temp')) {
            this.tareaSeleccionada = JSON.parse(localStorage.getItem('tarea_temp') ?? '')[0];
            localStorage.removeItem('tarea_temp');
        }

        this.getTareas();
    }

    ngAfterViewInit() {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('fc-view');

        const elementsArray = Array.from(elements);
  
        for (const element of elementsArray) {
            element.classList.add('no-zindex');
        }
    }

    getTareas() {
        this.tareasApi.calendarioGET.safePerform();
        this.tareasApi.calendarioGET.response(data => {

            const contador = 1;

            this.tareas = data;
            this.tareasArray = data
                .map((it: CalendarioTareaModel) => {
                    let color = '';

                    const start = moment(
                        Utils.toDate(it.fecha_inicio ?? '')
                    ).format('YYYY-MM-DD');

                    let end = '';
                    if (this.appName === 'agrosalvi') { 
                        end = moment(
                            Utils.toDate(it.fecha_fin ?? '')
                        ).format('YYYY-MM-DD');
                    }



                    switch (it.tipo_tarea) {
                    case 'fito':
                        if (it.estado === 'orden') {
                            color = '#ed7979';
                        } else {
                            color = '#f44242';
                        }
                        break;
                    case 'ferti':
                        if (it.estado === 'orden') {
                            color = '#f7b95c';
                        } else {
                            color = '#ff9a02';
                        }
                        break;
                    case 'bio':
                        if (it.estado === 'orden') {
                            color = '#83e689';
                        } else {
                            color = '#3be246';
                        }
                        break;
                    case 'siembra':
                        if (it.estado === 'orden') {
                            color = '#5fa8f5';
                        } else {
                            color = '#4198f4';
                        }
                        break;
                    case 'genérica':
                        if (it.estado === 'orden') {
                            color = '#e96bff';
                        } else {
                            color = '#d900ff';
                        }
                        break;
                    }


                    if (!this.hasClients) {
                        let descriptorEvento = 'Tarea: ' + it.nombre_tarea + '</br>' + 
                                'Trabajadores: ' + it.trabajadores + '</br>';
                        if (it.maquinarias) {
                            descriptorEvento += 'Maquinaria: ' + it.maquinarias  + '</br>';
                        }
                        descriptorEvento += 'Parcelas: ' + it.parcelas;
                        return {
                            id: contador.toString(),
                            title: descriptorEvento,
                            filter: '',
                            id_tarea: it.id_tarea ?? '',
                            tipo_tarea: it.tipo_tarea ?? '',
                            start,
                            end,
                            color
                        };
                    } else {
                        return {
                            id: contador.toString(),
                            title: 'Tarea: ' + it.tipo_tarea + '</br>' + 
                                'Estado: ' + it.estado + '</br>' +
                                'Cliente: ' + it.nombre_cliente + '</br>' + 
                                'Finca: ' + it.nombre_finca,
                            filter: it.tipo_cliente,
                            id_tarea: it.id_tarea ?? '',
                            tipo_tarea: it.tipo_tarea ?? '',
                            tipo_cliente: it.tipo_cliente ?? '',
                            start,
                            end,
                            color
                        };
                    }

                });
            this.filterArray();
            this.tareasApi.calendarioGET.unsuscribe();
        });
    }
    
    showSelectedDate(calendar: FullCalendarComponent) {
        const day = new Date().getDate();
        const month = this.selectedMonth ? this.selectedMonth : new Date().getMonth() + 1;
        const year = this.selectedYear ? this.selectedYear : new Date().getFullYear();
        const date = new Date(`${year}-${month}-${day}`);

        this.tareasApi.calendarioGET.safePerform({mes: (((month as string).length === 1) ? ('0' + month) : month) + '/' + year} as never);

        calendar.getApi().gotoDate(date.getTime());
    }


    filtrarPorTipoCliente () {
        StorageManager.saveCalendarFilter(this.selectedTipo);
        window.location.reload();
    }

    filterArray() {
        const clienteActual = StorageManager.getClient();
        
        this.tareasArrayFiltered = this.tareasArray.filter(it => {
            if (this.hasClients) {
                if (clienteActual && clienteActual.nombre && it.title) {
                    return it.title.includes(clienteActual.nombre);
                } else {
                    return it;
                }
            }
            return it;
        });
    }

    irAlDia() {
    }

    irAlEvento(e: EventClickArg) {
        const idTarea = e.event._def.extendedProps['id_tarea'];

        if (idTarea) {
            switch (e.event._def.extendedProps['tipo_tarea']) {
            case 'bio':
                this.router.navigate(['dashboard', 'editar-bio', idTarea, true ]);
                break;
            case 'fito':
                try {
                    (firstValueFrom(this.http.get<BaseResponseModel<TareaFitoModel>>( 
                        environment.serverUrl + 'ws/index.php?p1=tareas&p2=fitosanitarios_get&id=' + idTarea 
                    )))
                        .then( (tarea: BaseResponseModel<TareaFitoModel> ) => {
                            this.router.navigate(['dashboard', 'editar-fitosanitario', idTarea, (tarea.data as TareaFitoModel)[0]]);
                            return;
                        }).catch ((error: string) => {
                            console.log('catch en getPosition: ' + error);
                        }
                        );
                } catch (error) {
                    console.log(error);
                }
                break;
            case 'ferti':
                this.router.navigate(['dashboard', 'editar-fertilizante-tarea', idTarea, true ]);
                break;
            case 'siembra':
                this.router.navigate(['dashboard', 'tareas-editar-siembra', idTarea, true ]);
                break;
            case 'genérica':
                this.router.navigate(['dashboard', 'tareas-editar-otra', idTarea, true ]);
                break;
            }
        }
        
        for (const tarea of  this.tareas) {
            if (tarea === idTarea) {
                this.tareaSeleccionada = tarea;
                break;
            }
        }
    }
   

    reloadCalendario(){
        location.reload();
    }
    
}
