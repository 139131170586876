import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import moment from "moment";
import { FileSelectEvent } from "primeng/fileupload";
import { ColStylesInterface } from "src/app/common/components/common-list-table/col-styles.interface";
import { ColInterface } from "src/app/common/components/common-list-table/col.interface";
import {
  ItemInterface,
  InputType,
} from "src/app/common/components/form-builder/form-builder.component";
import { RequestButtonTypes } from "src/app/common/components/request-button/request-button.component";
import { createCsv } from "src/app/common/createCsv";
import { StorageManager } from "src/app/common/storage-manager.class";
import { Utils } from "src/app/common/utils";
import { MaquinariaAlbaranModel } from "src/app/models/ventas/tablas-maestras.model";
import { AlbaranApiService } from "src/app/service/api/albaran-api.service";
import { MaquinariaApiService } from "src/app/service/api/tablas-maestras-api.service";
import { DashboardService } from "src/app/service/events/dashboard.service";
import { Filtering } from "src/app/service/filtering/filtering";
import { RequestHandler } from "src/app/service/OffService/request-handler";
import { BaseView } from "src/app/view/base-view";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-albaran-maquinaria",
  templateUrl: "./maquinaria.component.html",
  styleUrls: ["./maquinaria.component.scss"],
})
export class AlbaranesMaquinariaComponent
  extends BaseView<MaquinariaAlbaranModel>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild("passport", { static: true }) passport: ElementRef | undefined;

  public showBtnTratamiento = environment.features.showBtnTratamiento;
  public years = [{ label: "...", value: 0 }];
  public selectedYears = 0;
  public allFitoProducts: any[] = [];
  public showClimatologia = environment.features.showClimatologia;
  public showAllTareas: boolean = false;
  public distributionOrderFormTratamientos = [2, 3, 2];

  public rolUser = StorageManager.getUser().rol;
  public hiddenButtonDelete = false;

  public appName: string = environment.appName;
  public serverUrl = environment.serverUrl;
  public logoUrl: string = environment.logoUrl;
  public showRecetaBuitech = environment.features.showRecetaBuitech;
  public showFichaTratamiento = environment.features.showFichaTratamiento;
  public showRegistroTratamiento = environment.features.showRegistroTratamiento;
  public showRegistroTratamientoShort =
    environment.features.showRegistroTratamientoShort;
  public showRegistroHernandorena =
    environment.features.showRegistroHernandorena;
  public showRecetaVGiner = environment.features.showRecetaVGiner;
  public showRecetaGlobalGAP = environment.features.showRecetaGlobalGAP;
  public showFitoValidado = environment.features.showFitoValidado
    ? environment.features.showFitoValidado &&
      StorageManager.getUser().rol === "admin"
    : false;

  public showTractorMarcha = environment.features.showTractorMarcha;
  public showTractorPresion = environment.features.showTractorPresion;
  public showTractorRPM = environment.features.showTractorRPM;
  public showTractorBoquillas = environment.features.showTractorBoquillas;
  public showTractorVelocidad = environment.features.showTractorVelocidad;
  public showTractorKmRecorridos = environment.features.showTractorKmRecorridos;
  public showTractorConfig =
    this.showTractorMarcha ||
    this.showTractorPresion ||
    this.showTractorRPM ||
    this.showTractorBoquillas ||
    this.showTractorVelocidad ||
    this.showTractorKmRecorridos;

  public tooltipForce = false;
  public tooltipClass = this.tooltipForce
    ? "telegram-passport-tooltip telegram-passport-tooltip-shown"
    : "telegram-passport-tooltip";

  public showTipoPlaga = environment.features.showTipoPlaga;
  public showMotivoFito = environment.features.showMotivoFito;
  public showTareasFitoObservaciones =
    environment.features.showTareasFitoObservaciones;
  public showRecetaPascualCabedo = environment.features.showRecetaPascualCabedo;
  public showRecetaLaplana = environment.features.showRecetaLaplana;
  public showPhCaldo = environment.features.showPhCaldo;
  public showRecetaFirmas = environment.features.showRecetaFirmas;
  public showCampaign = environment.features.showTareasFitosanitariasCampaign;
  public clienteName = environment.features.clienteName;
  public showTareasFitoPuntoKm = environment.features.showTareasFitoPuntoKm;
  public showTareasProcura = environment.features.showTareasProcura;
  public showMomentoAplicacion = environment.features.showMomentoAplicacion;
  public textoSuperficie = environment.features.textoSuperficie;
  public betweenDates = environment.features.showTareasFitoBetweenDates;

  public showTareasBioExportCsv = environment.features.showTareasBioExportCsv;

  public fincaName = environment.features.fincaName;
  public fincasName = environment.features.fincasName;
  public sectorName = environment.features.sectorName;
  public sectoresName = environment.features.sectoresName;
  public parcelaName = environment.features.parcelaName;
  public parcelasName = environment.features.parcelasName;
  public empresaName = environment.features.empresaName;
  public canAddTareas = environment.features.canAddTareas;
  public canDuplicateTareas = environment.features.canDuplicateTareas;
  public showTareasBio = environment.features.showTareasBio;
  public showTareasFertilizantes = environment.features.showTareasFertilizantes;
  public hasClients = environment.features.hasClients;
  public userCanSeeClientes =
    this.hasClients &&
    (environment.features.userCanSeeClientes
      ? true
      : StorageManager.getUser().rol === "admin");
  public showTareasFincas = environment.features.showTareasFincas;
  public showTareasSector = environment.features.showTareasSector;
  public showTareasFitoSectorMultiple =
    environment.features.showTareasFitoSectorMultiple;
  public showTareasParcela = environment.features.showTareasParcela;
  public showTareasMaquinaria = environment.features.showTareasMaquinaria;
  public showTareasTratamientos = environment.features.showTareasTratamientos;
  public showTareasMateriaActiva = environment.features.showTareasMateriaActiva;
  public tareasTratamientosLabel = environment.features.tareasTratamientosLabel;
  public showFitoFechaReceta = environment.features.showFitoFechaReceta;
  public showFitoMomentoAplicacion =
    environment.features.showFitoMomentoAplicacion;
  public showFitoCosteTratamiento =
    environment.features.showFitoCosteTratamiento;
  public showFitoTipoTratamiento = environment.features.showFitoTipoTratamiento;
  public showTareasFitoIT = environment.features.showTareasFitoIT;
  public showTareasFitoLote = environment.features.showTareasFitoLote;
  public showTareasFitoMaquinarias =
    environment.features.showTareasFitoMaquinarias;
  public showTareasFitoHoras = environment.features.showTareasFitoHoras;
  public showAplicadoSwitch = environment.features.showAplicadoSwitch;
  public rol = StorageManager.getUser().rol;

  public showFitoEficacia = environment.features.showFitoEficacia;
  public showDesplazamientoObra = environment.features.showDesplazamientoObra;
  public showTareasFitoCarretera = environment.features.showTareasFitoCarretera;
  public showTareasFitoPuntoKM = environment.features.showTareasFitoPuntoKM;
  public showTareasFitoDuracion = environment.features.showTareasFitoDuracion;
  public showTareasFitoMotivoJustificacion =
    environment.features.showTareasFitoMotivoJustificacion;

  public showUsuariosFirma = environment.features.showUsuariosFirma;
  public showClientesFirma = environment.features.showClientesFirma;
  public showTrabajadoresFirma = environment.features.showTrabajadoresFirma;
  public showTareasFitoSoleado = environment.features.showTareasFitoSoleado;
  public showTareasFitoLitrosAplicados =
    environment.features.showTareasFitoLitrosAplicados;
  public showTareasFitoMochila = environment.features.showTareasFitoMochila;
  public showTareasFitoAplicacion =
    environment.features.showTareasFitoAplicacion;
  public showClientesNotas = environment.features.showClientesNotas;

  public isGeneratingCanvas: boolean = false;
  public firmaPath: string = "";

  public getRequest: RequestHandler<MaquinariaAlbaranModel[]> =
    this.maquinariaApi.maquinariaGET;
  public deleteRequest: RequestHandler<MaquinariaAlbaranModel[]> =
    this.maquinariaApi.maquinariaDELETE;
  public pathToForm = "crear-albaran-maquinaria";
  public menuItemId = "albaran-maquinaria";
  public aplicado = {
    selected: "",
  };
  public validado = {
    selected: "",
  };

  public deleteRequestButtonType: RequestButtonTypes =
    RequestButtonTypes.DELETE;

  public override canShow: { [key: string]: any } = {};
  public override model: any = {};

  public showReceta = false;
  public isShowingGlobalGAP = false;
  public receta: any;
  public arrayProdsNom: any[] = [];
  public arrayProdsUds: any[] = [];
  public arrayCantsReceta: any[] = [];
  public arrayUdsMedida: string[] = [];
  public ps = 0;
  public currentUser = StorageManager.getUser();
  public clienteSelected = "";
  public telefClienteSelected = "";
  public observaciones = "";
  public empresa = "Locatec Aplicaciones Informáticas";

  public mostrarTratamientosAplicados = true;
  public override filtering: Filtering<any> = new Filtering<any>();
  public currentYear: string = "";

  public btnAnadir = "Añadir Maquinaria";

  public distributionOrder = [4];

  public nameReceta: string =
    environment.features.showRecetaPascualCabedo &&
    this.appName !== "gruesnadal"
      ? "Parte de trabajo"
      : "Generar Maquinaria";

  public sm = localStorage.getItem("sm") ? true : false;

  public maquinariaInfo: ItemInterface<any>[] = [
    {
      field: "nombre",
      name: "nombre",
      label: "Nombre",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "ref_proveedor",
      name: "ref_proveedor",
      label: "Ref. Proveedor",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "proveedor",
      name: "proveedor",
      label: "Proveedor",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "familia",
      name: "familia",
      label: "Familia",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "pvp",
      name: "pvp",
      label: "P.V.P",
      inputType: { type: InputType.TEXT },
    },
    {
      field: "pvp_iva_inc",
      name: "pvp_iva_inc",
      label: "P.V.P IVA INC",
      inputType: { type: InputType.TEXT },
    },
  ];
  public totalHoras: any = "00:00";
  public auxTotalHoras: any = "00:00";

  public cols = [
    {
      field: "nombre",
      header: "Nombre",
      filter: "",
      width: "160",
      visible: this.appName !== "pyf" && this.appName !== "donjardin",
    },
  ];
  public str: string = "";

  public modalShowCampaign = false;

  public digitosDecimalesDosis: number =
    environment.appName === "vginer" ? 3 : 2;
  public parts: any[] = [];

  public fecha: { inicio: any; fin: any } = {
    inicio: "",
    fin: "",
  };
  public showImportar: boolean = false;
  showImportError: boolean = false;
  importError: string = "";
  urlUpload: string = this.serverUrl + "ws/albaran/upload_files/fileUpload.php?type=maquinaria";
  constructor(
    public maquinariaApi: MaquinariaApiService,
    private dashboard: DashboardService,
    private albaranApi: AlbaranApiService,
    private router: Router // private messageService: MessageService
  ) {
    super(dashboard, maquinariaApi.maquinariaGET);
  }

  ngOnInit() {
    if (environment.features.showTareasMto) {
      if (this.rolUser !== "admin") {
        this.hiddenButtonDelete = true;
      }
    }
    if (this.appName === "ava") {
      this.aplicado.selected = "no-aplicados";
    }

    this.dashboard.setSubmenuItem(this.menuItemId);

    this.expandFormFields();

    this.appName = environment.appName;

    if (this.empresaName !== "") {
      this.empresa = this.empresaName;
    }

    if (this.appName === "melia") {
      this.empresa = this.currentUser.apellidos;
    }

    this.dashboard.setHeaderTitle("Maquinaria");
  }

  ngAfterViewInit() {
    this.maquinariaApi.maquinariaGET.response(() => {
      this.updateFooter();
      this.sum();
    });
  }

  getFechaInicio(event: any) {
    this.fecha.inicio = event;
    this.filtering.filter();
    this.updateFooter();
  }
  getFechaFin(event: any) {
    this.fecha.fin = event;
    this.filtering.filter();
    this.updateFooter();
  }

  decode(utftext: string) {
    let string = "",
      i = 0,
      c = 0,
      c2 = 0,
      c3 = 0;

    while (i < utftext.length) {
      c = utftext.charCodeAt(i);
      if (c < 128) {
        string += String.fromCharCode(c);
        i++;
      } else if (c > 191 && c < 224) {
        c2 = utftext.charCodeAt(i + 1);
        // eslint-disable-next-line no-bitwise
        string += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
        i += 2;
      } else {
        c2 = utftext.charCodeAt(i + 1);
        c3 = utftext.charCodeAt(i + 2);
        // eslint-disable-next-line no-bitwise
        string += String.fromCharCode(
          ((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63)
        );
        i += 3;
      }
    }

    return string;
  }

  modalCampaign() {
    this.modalShowCampaign = true;
  }

  closeModalShowCampaign() {
    this.modalShowCampaign = false;
  }

  selectedCampaign() {
    this.filtering.filter();
  }

  compareDates(row: any) {
    const auxFecha = (row["fecha_inicio"] as string).split("/");
    const nuevaFecha = moment(
      auxFecha[2] + "-" + auxFecha[1] + "-" + auxFecha[0]
    );
    const fechaComparar = moment(
      "20" + this.selectedYears.toString() + "-07" + "-01"
    );
    const fechaMax = moment(
      "20" + (this.selectedYears + 1).toString() + "-06" + "-30"
    );

    if (nuevaFecha > fechaComparar && nuevaFecha < fechaMax) {
      return true;
    } else {
      return false;
    }
  }

  /**
   *  Rellena el listado de campañas
   */
  fillCampaign() {
    const fechaActual = new Date().getFullYear();
    let fechaDosDigitos = 0;

    for (let index = 2020; index <= fechaActual; index++) {
      fechaDosDigitos = parseInt(index.toString().substring(2, 4), 10);
      this.years.push({
        label:
          environment.features.applicationType === "cropgest"
            ? index.toString()
            : index.toString() + "-" + (fechaDosDigitos + 1).toString(),
        value: fechaDosDigitos,
      });
    }
  }

  public filtrarTratamientos(value: string, from = "switch_aplicados") {
    if (from === "switch_aplicados") {
      this.aplicado.selected = this.aplicado.selected === value ? "" : value;
    } else if (from === "switch_validados") {
      if (this.showFitoValidado) {
        this.validado.selected = this.validado.selected === value ? "" : value;
      }
    }

    this.filtering.filter();
    if (["donjardin", "coliberica", "fotoagricultura"].includes(this.appName)) {
      this.sum();
    }
  }

  public add() {
    this.router.navigate(["dashboard", this.pathToForm]);
  }

  public edit(data: any) {
    this.router.navigate([
      "dashboard",
      "editar-albaran-maquinaria",
      data.data.id,
    ]);
  }

  public duplicate(data: any) {
    this.router.navigate([
      "dashboard",
      "duplicar-albaran-maquinaria",
      data.data.id,
    ]);
  }

  public override deleteRegister() {
    var aux = { maquinaria: this.model.id } as any;
    if (this.model["id"]) {
      this.albaranApi.albaranesGET
        .safePerform(aux)
        .toPromise()
        .then((res) => {
          if (res.length === 0) {
            this.deleteRequest.safePerform(this.model);
            this.deleteRequest.response(() => {
              if (!this.deleteRequest.hasError) {
                this.getRequest.safePerform();
              }
            });
          } else {
            alert(
              "Esta máquina esta ligada a un Albarán, eliminalo antes de borrar el registro"
            );
          }
        });
    } else {
      this.deleteRequest.hasError = true;
      throw new Error("Trying to DELETE a register without primary key");
    }
  }

  public override show(modal: string, data: any) {
    super.show(modal, data.data);
  }

  public override hide(modal: string) {
    this.model = null;
    this.canShow[modal] = false;
    this.deleteRequest.reset();
  }

  public override ngOnDestroy() {
    Utils.unsuscribe([
      this.maquinariaApi.maquinariaGET,
      this.maquinariaApi.maquinariaDELETE,
    ]);
  }

  public enviarCorreo() {
    this.isGeneratingCanvas = true;
    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    )
      .then((canvas) => {
        this.isGeneratingCanvas = false;
        const pdf = new jsPDF(config.orientation, "mm", "a4");
        const data = canvas.toDataURL("image/png");

        if (canvas.width > canvas.height) {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            config.width,
            canvas.height / (canvas.width / config.width),
            "alias",
            "FAST"
          );
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            canvas.width / (canvas.height / config.height),
            config.height,
            "alias",
            "FAST"
          );
        }

        let email = "";
        if (this.receta.finca_asociada && this.receta.finca_asociada.email) {
          email = this.receta.finca_asociada.email;
        } else if (this.receta.cliente_email) {
          email = this.receta.cliente_email;
        } else {
          email = "";
        }
        console.log(email);
      })
      .then(
        () => {
          // console.log('Adjuntos generados.');
        },
        () => {
          alert(
            "Ha habido un error al enviar el email. Si este error persiste, por favor, contacte con un administrador."
          );
          // console.log(error);

          this.isGeneratingCanvas = false;
        }
      );
  }

  public enviarWhatsapp() {
    window.open("https://web.whatsapp.com/", "_blank");

    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    ).then((canvas) => {
      this.isGeneratingCanvas = false;

      const pdf = new jsPDF(config.orientation, "mm", "a4");
      const data = canvas.toDataURL("image/png");

      if (canvas.width > canvas.height) {
        if (this.appName === "pyf") {
          pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            config.width,
            canvas.height / (canvas.width / config.width),
            "alias",
            "FAST"
          );
        }
      } else {
        if (this.appName === "pyf") {
          pdf.addImage(data, "png", 0, 20, 200, 140, "alias", undefined);
        } else {
          pdf.addImage(
            data,
            "png",
            0,
            0,
            canvas.width / (canvas.height / config.height),
            config.height,
            "alias",
            "FAST"
          );
        }
      }

      const file = pdf.output("blob");

      const formData = new FormData();
      formData.append("document", file, "receta.pdf");
      formData.append("phoneNumber", "+34" + this.receta.cliente_telefono);

      function sendDocument() {
        const url = `${environment.serverUrl}ws/tareas/enviar_receta_whatsapp.php`;

        const xht = new XMLHttpRequest();

        xht.open("POST", url);
        xht.send(formData);
      }

      sendDocument();
    });

    this.showReceta = false;
  }

  public generarReceta(data: any, showAfter = true) {
    this.receta = data.data;

    this.currentYear = new Date().getFullYear().toString();
    this.receta.fecha_completa = moment(
      this.receta.fecha_fin,
      "DD/MM/YYYY"
    ).isValid()
      ? moment(this.receta.fecha_fin, "DD/MM/YYYY").format("LL")
      : "___/___/______";

    if (showAfter) {
      this.showReceta = true;
    }
  }

  public generarGlobalGAP(data: any) {
    this.generarReceta(data, false);

    if (this.showUsuariosFirma) {
      this.firmaPath =
        this.pathToFirmas + "_usuarios/" + this.receta.id_usuario + ".png";

      if (this.receta.usuario_firma !== "") {
        this.firmaPath =
          this.pathToFirmas + "_asesor/" + this.receta.usuario_firma;
      }
    }

    this.isShowingGlobalGAP = true;
  }

  public getPrintConfig() {
    const config: {
      canvasScale: number;
      orientation: "p" | "l";
      width: number;
      height: number;
    } = {
      canvasScale: 2,
      orientation: "p",
      width: 210,
      height: 290,
    };

    switch (this.appName) {
      case "laplana":
        config.canvasScale = 1.04;
        config.width = 197;
        config.height = 290;
        break;
      case "pascualcabedo":
        config.canvasScale = 0.86;
        break;
      case "vginer":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "esparragosgranada":
        config.orientation = "l";
        config.width = 290;
        config.height = 187;
        break;
      case "fitogest":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "agrogest":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "armentia":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "melia":
        config.width = 320;
        config.height = 250;
        break;
      case "saviatropical":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      case "gragon":
        config.orientation = "l";
        config.width = 290;
        config.height = 210;
        break;
      default:
        break;
    }
    return config;
  }

  public imprimirReceta() {
    this.isGeneratingCanvas = true;
    const config = this.getPrintConfig();

    html2canvas(
      document.getElementById("html2canvasDiv") ?? new HTMLElement(),
      { scale: config.canvasScale, logging: false }
    ).then((canvas) => {
      this.isGeneratingCanvas = false;

      const pdf = new jsPDF(config.orientation, "mm", "a4");
      const data = canvas.toDataURL("image/png");

      const padding = 10; // Padding de 10mm solo en horizontal

      // Ajusta el ancho disponible para la imagen restando el padding horizontal
      let imgWidth = config.width - padding * 2;
      let imgHeight = canvas.height / (canvas.width / imgWidth); // Mantiene la proporción de la imagen

      // Cálculo para centrar la imagen solo horizontalmente con padding
      const xOffset = (config.width - imgWidth) / 2; // Desplazamiento horizontal con padding
      const yOffset = 0; // No se aplica ningún desplazamiento vertical (sin padding vertical)

      if (canvas.width > canvas.height) {
        if (this.appName === "pyf") {
          pdf.addImage(
            data,
            "png",
            xOffset,
            yOffset + 20,
            imgWidth,
            140,
            "alias",
            undefined
          );
        } else {
          pdf.addImage(
            data,
            "png",
            xOffset, // Coordenada X centrada con padding horizontal
            yOffset, // Coordenada Y sin padding
            imgWidth,
            imgHeight,
            "alias",
            "FAST"
          );
        }
      } else {
        if (this.appName === "pyf") {
          pdf.addImage(
            data,
            "png",
            xOffset,
            yOffset + 20,
            imgWidth,
            140,
            "alias",
            undefined
          );
        } else {
          pdf.addImage(
            data,
            "png",
            xOffset, // Coordenada X centrada con padding horizontal
            yOffset, // Coordenada Y sin padding
            imgWidth,
            imgHeight,
            "alias",
            "FAST"
          );
        }
      }

      pdf.save(`maquinaria-${this.receta["id"]}`);
    });
  }

  public globalConditionalStyle(
    _value: string,
    col: ColInterface
  ): ColStylesInterface {
    let estiloVademecum: any = {};

    if (col.field) {
      return {
        style: {
          "text-align": "center",
        },
      };
    }

    if (col.field === "trabajadores_nombres") {
      return {
        style: {
          "max-width": "50px",
        },
      };
    }
    if (col.field === "observaciones") {
      return {
        style: {
          "max-width": "50px",
        },
      };
    }

    if (environment.features.showTareasMto) {
      if (col.field === "aplicado") {
        if (_value === "0") {
          return {
            rowStyle: {
              backgroundColor: "#f44242",
            },
          };
        } else if (_value === "1") {
          return {
            rowStyle: {
              backgroundColor: "#00ff00",
            },
          };
        }
      }
    }

    // if (Utils.isNumber(value) && col.field !== 'sector' && col.field !== 'facturado') {
    if (col.field !== "sector" && col.field !== "facturado") {
      if (col.field === "id" || col.field === "horas_totales") {
        return { textAlign: "center" };
      } else {
        return { textAlign: "right", paddingRight: "40px" };
      }
    }

    if (["id", "fecha_inicio", "fecha_fin"].includes(col.field)) {
      return { textAlign: "center" };
    }

    return estiloVademecum;
  }

  public globalVisualTransform(value: string, col: ColInterface) {
    if (col.field === "aplicado") {
      if (value === "0") {
        return "No aplicado";
      } else {
        return "Aplicado";
      }
    }

    if (col.field === "parcelas_nombres") {
      return value && value.length > 50 ? value.slice(0, 50 - 1) + "…" : value;
    }
    if (col.field === "eficacia") {
      switch (value) {
        case "1":
          return "Buena";
        case "2":
          return "Regular";
        case "3":
          return "Mala";
        default:
          return value;
      }
    }

    if (col.field === "facturado") {
      switch (value) {
        case "0":
          return "No";
        case "1":
          return "Sí";
        default:
          return "No";
      }
    }

    if (Utils.isNumber(value) && col.field !== "id") {
      if (col.field !== "finca") {
        if (col.field === "litros" && environment.features.showTareasMto) {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            4,
            ".",
            ","
          );
        } else if (col.field === "litros" && this.appName === "onda") {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            0,
            ".",
            ","
          );
        } else {
          return Utils.decimalFormat(
            (value || "").replace(",", "."),
            2,
            ".",
            ","
          );
        }
      }
    }

    return value;
  }

  public formatDate(date: string) {
    moment.locale("es");
    return moment(date, "DD/MM/YYYY").format("LL");
  }

  public createNavigation(route: string) {
    this.router.navigate(["dashboard", route]);
  }

  public toggleRowValidation(event: any, row: any) {
    this.maquinariaApi.maquinariaPUT
      .safePerform([
        {
          id: row.id,
          // validado: event.checked,
        },
      ])
      .toPromise()
      .then(() => {
        console.log(event.checked);

        this.getRequest.safePerform();
      });
  }

  public async downloadCsv() {
    const arrayFormFields = [this.maquinariaInfo];
    let campoFinal = "factura_maquinaria";

    const csvContent = createCsv(
      arrayFormFields,
      this.filtering.value,
      campoFinal
    );

    const csvLink: any = document.getElementById("csvLink");
    csvLink.download = "Fitosanitarios.csv";
    csvLink.href = encodeURI(csvContent);
    csvLink.click();
  }

  public override updateFooter() {
    this.sum();
  }

  public override sum() {
    this.totalHoras = moment(new Date("October 1, 2014 00:00:00"));
    this.auxTotalHoras = moment(new Date("October 1, 2014 00:00:00"));
    let contador = 0;

    if (contador === 0) {
      this.totalHoras = "00:00";
    }
    this.filtering.filter();
  }

  public changeShowAll() {
    if (this.showAllTareas) {
      this.maquinariaApi.maquinariaGET.toPromise().then((res: any) => {
        this.filtering.setValue(res);
      });
    } else {
      this.maquinariaApi.maquinariaGET.toPromise().then((res: any) => {
        this.filtering.setValue(res);
      });
    }
    this.filtering.filter();
  }

  private expandFormFields() {}

  public static createNavigation(router: Router, route: string = "maquinaria") {
    router.navigate(["dashboard", route]);
  }

  uploadDialog() {
    this.showImportar = true;
  }

  closeFUDialog() {
    this.showImportar = false;
    this.showImportError = false;
    location.reload();
  }

  checkFile(event: FileSelectEvent) {
    this.showImportError = false;
    const file = event.files[0]?.name ?? "";
    if (!file.includes(".xlsx") && !file.includes(".xls")) {
      this.showImportError = true;
      this.importError = "El archivo debe ser un Excel(xlsx)";
      //this.enableUpload = false;
    }
  }
}
