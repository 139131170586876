import {Component, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../../../service/events/dashboard.service';
import {InputType} from '../../../../../common/components/form-builder/form-builder.component';
import {BaseForm} from '../../../../base-form';
import {AppFormRequest} from '../../../../app-common/form-request/app-form-request';
import {build, list, stack} from '../../../../../common/classes/request-builder';
import moment from 'moment';
import {StorageManager} from '../../../../../common/storage-manager.class';
import { RequestButtonComponent } from 'src/app/common/components/request-button/request-button.component';
import { environment } from 'src/environments/environment';
import { PedidosApiService } from 'src/app/service/api/pedidos-api.service';
import { ProductosApiService } from 'src/app/service/api/productos-api';
import { FincasApiService } from 'src/app/service/api/fincas-api.service';
import { FormRequestTypes } from 'src/app/common/classes/form-request';

import type { OnInit } from '@angular/core';
import type { ItemInterface } from '../../../../../common/components/form-builder/form-builder.component';
import { PedidoModel } from 'src/app/models/visitas/pedido.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ClienteModel } from 'src/app/models/cliente.model';
import { TypeAheadInterface } from 'src/app/common/components/form-builder/form-builder.component';

@Component({
    selector: 'app-visitas-pedidos-form',
    templateUrl: './visitas-pedidos-form.component.html',
    styleUrls: ['./visitas-pedidos-form.component.scss']
})
export class VisitasPedidosFormComponent extends BaseForm<PedidoModel> implements OnInit {
    @ViewChild(RequestButtonComponent, { read: RequestButtonComponent, static: true })
        requestButton = new RequestButtonComponent<PedidoModel>();

    public appName = environment.appName;
    public hasClients = environment.features.hasClients;
    public applicationType = environment.features.applicationType;
    public serverUrl = environment.serverUrl;

    public override model: PedidoModel = {};
    public formRequest = new AppFormRequest<PedidoModel>();
    public id = 0;

    public fincas: TypeAheadInterface<FincasModel> = list();
    public nombreCliente = '';
    
    public productos1 = list();
    public productos2 = list();
    public productos3 = list();
    public productos4 = list();
    public productos5 = list();
    public productos6 = list();
    public productos7 = list();

    public signatureFile: File = new File([], '');

    public distributionOrder = [
        4, 3, 3, 3, 3, 3, 1
    ];

    public formFields: ItemInterface<object>[] = [
        {
            field: 'id_finca', 
            label: 'Finca', 
            inputType: {type: InputType.DROPDOWN}, 
            required: true, 
            values: this.fincas, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Albarán', 
            field: 'albaran', 
            inputType: {type: InputType.EDIT_TEXT}
        },
        {
            label: 'Fecha Pedido', 
            field: 'fecha_pedido', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Fecha Entrega', 
            field: 'fecha_entrega', 
            inputType: {type: InputType.CALENDAR}
        },
        {
            label: 'Producto', 
            field: 'producto1', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos1, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Cantidad', 
            field: 'cantidad1', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Lote', 
            field: 'lote1', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Producto 2', 
            field: 'producto2', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.productos2, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Cantidad 2', 
            field: 'cantidad2', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Lote 2', 
            field: 'lote2', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Producto 3', 
            field: 'producto3', 
            inputType: {type: InputType.DROPDOWN_SEARCH}, 
            values: this.productos3, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Cantidad 3', 
            field: 'cantidad3', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Lote 3', 
            field: 'lote3', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Producto 4', 
            field: 'producto4', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos4, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Cantidad 4', 
            field: 'cantidad4', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Lote 4', 
            field: 'lote4', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Producto 5', 
            field: 'producto5', 
            inputType: {type: InputType.DROPDOWN_SEARCH},
            values: this.productos5, 
            valuePrimaryKey: 'id'
        },
        {
            label: 'Cantidad 5', 
            field: 'cantidad5', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Lote 5', 
            field: 'lote5', 
            inputType: {type: InputType.EDIT_NUMERIC}
        },
        {
            label: 'Estado', 
            field: 'estado', 
            inputType: {type: InputType.DROPDOWN},
            values: list(['...', null], 'Hecho', 'En Preparación', 'Entregado')
        },
    ];

    public fieldsToSend = (['id', 'id_cliente', 'id_usuario', 'signature_img'] as string[]).concat(
        this.formFields.map(it => it.field ?? ''),
    );
    
    public adjunto = '';
    

    constructor(
        public override dashboard: DashboardService,
        public override router: Router,
        public override route: ActivatedRoute,
        public pedidosApi: PedidosApiService,
        public fincasApi: FincasApiService,
        public productosApi: ProductosApiService         
    ) {
        super(
            route,
            router,
            dashboard,
            pedidosApi.pedidosPUT,
            pedidosApi.pedidosPOST,
            'visitas-pedidos',
            'Actualizar Pedido',
            'Realizar Pedido',
            'Duplicar Pedido'
        );
    }

    ngOnInit() {
        this.formRequest
            .isGeneric(false)
            .setType(this.getType())
            .setRegisterId(this.getRegisterId())
            .setGetRequest(this.pedidosApi.pedidosGET)
            .setPostRequest(this.pedidosApi.pedidosPOST)
            .setPutRequest(this.pedidosApi.pedidosPUT)
            .setModel(this.model)
            .setFormFields(this.formFields)
            .setFieldsToSend(this.fieldsToSend);


        this.formRequest.beforeLoad(resolve => {
            this.initFormData()
                .then(() => resolve(true)).catch (e => {
                    console.log('catch en initFormData: ' + e);
                }
                );
        });

        this.formRequest.afterLoad(resolve => {
            if (this.formRequest.type !== FormRequestTypes.EDIT) {
                this.model.albaran = null;
                this.pedidosApi.pedidosGET.safePerform();
                this.pedidosApi.pedidosGET.response((res: PedidoModel[]) => {
                    this.model.fecha_pedido = new Date();
                    let albaran = this.model.fecha_pedido.toLocaleDateString().slice(-2) + '-';
                    
                    if (res.length > 0) {
                        this.id = this.model.siguiente ?? 0;
                        albaran += ('0000' + this.id).slice(-4);
                    } else {
                        this.id = 1;
                        albaran += '0001';
                    }
        
                    this.model.albaran = albaran;
                });
            }
            resolve(true);
        });

        this.formRequest.beforeSend(resolve => {
            this.model.producto1 = this.model.producto1 ?? '0';
            this.model.producto2 = this.model.producto2 ?? '0';
            this.model.producto3 = this.model.producto3 ?? '0';
            this.model.producto4 = this.model.producto4 ?? '0';
            this.model.producto5 = this.model.producto5 ?? '0';

            this.model.cantidad1 = this.model.cantidad1 ?? '0';
            this.model.cantidad2 = this.model.cantidad2 ?? '0';
            this.model.cantidad3 = this.model.cantidad3 ?? '0';
            this.model.cantidad4 = this.model.cantidad4 ?? '0';
            this.model.cantidad5 = this.model.cantidad5 ?? '0';

            this.model.lote1 = this.model.lote1 ?? '0';
            this.model.lote2 = this.model.lote2 ?? '0';
            this.model.lote3 = this.model.lote3 ?? '0';
            this.model.lote4 = this.model.lote4 ?? '0';
            this.model.lote5 = this.model.lote5 ?? '0';

            // Modificar la estructura de la fecha si tiene fecha seleccionada
            if ((typeof this.model.fecha_pedido !== 'string')) {
                
                if ( ( this.model.fecha_pedido !=  null ) ) {
                    this.model.fecha_pedido = moment(this.model.fecha_pedido ).format('DD/MM/YYYY');
                }
                // La forma antigua para rellerafecha cuando este vacia
                // this.model.fecha_exp = moment(this.model.fecha_exp || new Date()).format('DD/MM/YYYY');
            }
            if ((typeof this.model.fecha_entrega !== 'string')) {
                if ( ( this.model.fecha_entrega !=  null ) ) {
                    this.model.fecha_entrega = moment(this.model.fecha_entrega ).format('DD/MM/YYYY');
                }
                // La forma antigua para rellerafecha cuando este vacia
                // this.model.fecha_cad = moment(this.model.fecha_cad || new Date()).format('DD/MM/YYYY');
            }

            if (this.hasClients) {
                const clientId = (StorageManager.getClient() || {} as ClienteModel).id;
                const clientIdFromFinca = (this.fincas.selected as FincasModel).id_cliente;
                const clientIdFromForm = this.model['id_cliente'];
                this.model.id_cliente =
                    clientIdFromFinca ? clientIdFromFinca :
                        clientIdFromForm ? clientIdFromForm :
                            clientId ? clientId : ''; // DEFAULT


                const clientName = StorageManager.getClient()?.nombre;
                const clientNameFromFinca = (this.fincas.selected as FincasModel).nombre_cliente;
                this.nombreCliente = 
                    clientNameFromFinca ? clientNameFromFinca : 
                        clientName ? clientName : '';
            }

            const userId = (StorageManager.getUser() as UserModel).id;
            const userIdFromFinca = (this.fincas.selected as FincasModel).id_usuario;
            const userIdFromForm = this.model.id_usuario;

            this.model.id_usuario =
                userIdFromFinca ? userIdFromFinca :
                    userIdFromForm ? userIdFromForm :
                        userId ? userId : '';

            if (this.hasClients) {
                this.model.id_usuario = 
                    (this.applicationType === 'cropgest' ? 
                        (StorageManager.getClient() || {} as ClienteModel).id_usuario : 
                        (StorageManager.getClient() || {} as ClienteModel).id_usuario_asignado || this.model.id_usuario) as string;
            };


            if (this.formRequest.type !== FormRequestTypes.EDIT) {
                let email: string | null = null;
    
                try {
                    /* Se fuerza a que tengan email asignado a la finca en caso de que haya un 
                    responsable de finca y el agricultor tenga varias fincas.*/
                    email = (this.fincas.selected as FincasModel).email;
                } catch (error) {
                    console.log(error);
                    email = null;
                }
    
    
                if (email) {
                    this.pedidosApi.enviarEmail.toPromise({
                        id: this.id.toString(),
                        email,
                        pedido: JSON.stringify(this.model),
                        cliente_nombre: this.nombreCliente,
                        finca_nombre: (this.fincas.selected as FincasModel).nombre,
                        producto1: this.productos1.selected,
                        producto2: this.productos2.selected,
                        producto3: this.productos3.selected,
                        producto4: this.productos4.selected,
                        producto5: this.productos5.selected,
                    });
                }
            }

            resolve(true);

        });

        this.formRequest.afterSend(resolve => {
            resolve(true);
        });
    
        this.formRequest.load();

        this.softInit(this.getType());
    }

    getSignature( event: File ) {
        this.signatureFile = event;
    }
    
    updateFirma(event: string) {
        this.model.signature_img = event;
        this.formRequest.update();
    }
    
    public submit() {
        if (StorageManager.getUser().tipo === 'comunero') {
            this.model.id_cliente = StorageManager.getUser().id_cliente ?? '';
            this.nombreCliente = StorageManager.getUser().nombre;
        }
        
        if (!this.formRequest.checkIfValid()) {
            this.requestButton.error = 'Hay campos obligatorios';
        }    
    
        this.formRequest.send();
    }

    public formChanges() {
        this.requestButton.error = '';

    }

    private initFormData(): Promise<boolean> {
        const builds = [
            build(this.fincasApi.fincasGET, this.fincas, 'nombre'),
            build(
                this.productosApi.productos.GET, 
                [this.productos1, this.productos2, this.productos3, this.productos4, this.productos5], 
                'nombre'
            )
        ];
        return stack(builds, () => {
            this.formRequest.update();
        });
    }
}