import {
  ItemInterface,
  InputType,
} from "src/app/common/components/form-builder/form-builder.component";
import { environment } from "src/environments/environment";

export class MaquinariaFormDefinition {
  public appName = environment.appName;
  public lastFieldChanged: string = "";

  public requestType: any;
  public model: any = {};

  public formFieldsDatos: ItemInterface<any>[] = [
    {
      field: "nombre",
      label: "Nombre",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "ref_proveedor",
      label: "Ref. Proveedor",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "proveedor",
      label: "Proveedor",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "familia",
      label: "Familia",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "existencias",
      label: "Existencias",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "pvp",
      label: "P.V.P",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "pvp_iva_inc",
      label: "P.V.P IVA INC",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
  ];

  public formChanges(tag: string) {
    this.lastFieldChanged = tag;
  }
}
