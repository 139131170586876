import { Component, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, HostListener } from '@angular/core';
import SignaturePad from 'signature_pad';

@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements AfterViewInit {
    @Output() sendFile = new EventEmitter<File>();
    @Output() sendTempImg = new EventEmitter<string>();
    @ViewChild('signaturePad', { static: false }) canvasRef!: ElementRef<HTMLCanvasElement>;

    canvasWidth = 800;
    canvasHeight = 300;
    visibleSignature = false;
    private signaturePad!: SignaturePad;

    constructor() {}

    @HostListener('window:resize')
    onResize(): void {
        this.setCanvasSize();
    }

    ngAfterViewInit(): void {
        this.initSignaturePad();
    }

    clearPad(): void {
        this.signaturePad?.clear();
    }

    async saveSign() {
        if (!this.signaturePad || this.signaturePad.isEmpty()) {
            return alert('No hay ninguna firma para guardar.');
        }
        const file = this.convertDataURLToFile(this.signaturePad.toDataURL(), 'signature.png');
        const img64 = await this.getBase64(file);

        if ( img64 != null ) {
            this.sendTempImg.emit(img64 as string);
        }
        this.sendFile.emit(file);
        alert('Firma guardada correctamente.');
    }

    private setCanvasSize(): void {
        const canvas = this.canvasRef?.nativeElement;
        if (canvas) {
            Object.assign(canvas, { width: this.canvasWidth, height: this.canvasHeight });
        }
    }

    private initSignaturePad(): void {
        const canvas = this.canvasRef?.nativeElement;
        if (!canvas) {
            return console.error('El canvas no se encuentra.');
        }

        this.setCanvasSize();
        this.signaturePad = new SignaturePad(canvas, {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            penColor: 'rgb(0, 0, 0)',
        });
    }

    private convertDataURLToFile(dataURL: string, filename: string): File {
        const [header, data] = dataURL.split(',');
        const mime = header?.match(/:(.*?);/)?.[1] || '';
        const u8arr = Uint8Array.from(atob(data ?? ''), c => c.charCodeAt(0));

        return new File([u8arr], filename, { type: mime });
    }

    private getBase64(file: Blob): Promise<string | ArrayBuffer | undefined | null> {
        return new Promise( resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
  
            reader.onload = function () {
                //console.log(reader.result);
                resolve(reader.result);
            };
  
            reader.onerror = function () {
                resolve(null);
                //console.log('Error: ', error);
            };
        });
 
    }
}