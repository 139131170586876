import {Component, EventEmitter, Input, Output} from '@angular/core';
import {StorageManager} from '../../../storage-manager.class';

import type { OnInit } from '@angular/core';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { FormType } from '../form-type.enum';


@Component({
    selector: 'app-reactive-request-button',
    templateUrl: './request-button.component.html',
    styleUrls: ['./request-button.component.scss']
})


export class ReactiveRequestButtonComponent<T> implements OnInit {

    @Input() request = new RequestHandler<T>();
    @Input() lockOnSuccess = false;
    @Input() error: string | null = null;
    @Input() warning: string | null = null;
    @Input() mode: FormType = 0;
    @Output() clicked: EventEmitter<object> = new EventEmitter<object>();

    public failMessage: string[] = [];

    public REQUEST_BUTTON_TYPES = FormType;

    constructor() {}

    ngOnInit() {
            
        if (!this.mode) {
            this.mode = this.REQUEST_BUTTON_TYPES.CREATION;
        }

        if ((StorageManager.getUser() || {}).rol === 'demo') {
            this.failMessage[0] = 'Operación no permitida';
            this.failMessage[1] = '';
        } else {
            this.failMessage[0] = 'Ha ocurrido un error...';
            this.failMessage[1] = 'Volver a intentar';
        }
    }

    public getButtonLabel(): string {
        if (this.error) {
            return ' ' + this.error;
        }
        if (this.warning) {
            return  ' ' + this.warning;
        }
        if (this.request.isLoading) {
            switch (this.mode) {
            case this.REQUEST_BUTTON_TYPES.DUPLICATION:
                return ' Duplicando...';
            case this.REQUEST_BUTTON_TYPES.EDITION:
                return ' Actualizando...';
            case this.REQUEST_BUTTON_TYPES.DELETION:
                return ' Eliminando...';
            default:
                return ' Guardando...';
            }
        }
        if (this.request.hasError) {
            return this.request.hasErrorCliExists ? ' Cliente ya existe...' : this.failMessage[0] ?? '';
        }
        if (this.request.hasError === undefined) {
            switch (this.mode) {
            case this.REQUEST_BUTTON_TYPES.DUPLICATION:
                return ' Duplicar';
            case this.REQUEST_BUTTON_TYPES.EDITION:
                return ' Actualizar';
            case this.REQUEST_BUTTON_TYPES.DELETION:
                return ' Eliminar';
            default:
                return ' Guardar';
            }
        }
        if (this.request.hasError === false) {
            switch (this.mode) {
            case this.REQUEST_BUTTON_TYPES.DUPLICATION:
                return ' Duplicado';
            case this.REQUEST_BUTTON_TYPES.EDITION:
                return ' Actualizado';
            case this.REQUEST_BUTTON_TYPES.DELETION:
                return ' Eliminado';
            default:
                return ' Guardado';
            }
        }
        return '';
    }    
}
