<div style="max-width: auto; overflow: auto">
  <div #passport></div>
  <app-common-list-table
    [request]="getRequest"
    [cols]="cols"
    [filtering]="filtering"
    [globalConditionalStyle]="globalConditionalStyle"
    [globalVisualTransform]="globalVisualTransform"
    [actionButtonText]="canAddTareas ? btnAnadir : ''"
    (emitSearchText)="updateFooter()"
    (actionButtonClick)="add()"
  >
  <ng-template #header>
    <div style="margin-left: 20px;">
         <button class="mx-2 btn" (click)="uploadDialog()" style="padding: 5px; font-size: .8rem; background-color: #16A2B7; color: white">
            <i class="fa fa-upload"></i>
            Importar Clientes
        </button>
    </div>
</ng-template>
    <ng-template #thEnd>
      <th>
        <p class="mb-0 d-flex justify-content-center">Acciones</p>
      </th>
    </ng-template>

    <ng-template #tdEnd let-data>
      <td>
        <app-action-buttons
          [hideDelete]="hiddenButtonDelete"
          [showDuplicate]="canDuplicateTareas"
          (duplicateRowButtonEvent)="duplicate(data)"
          (editRowButtonEvent)="edit(data)"
          (deleteRowButtonEvent)="show('delete', data)"
          (showRowButtonEvent)="show('info', data)"
        >
        </app-action-buttons>
      </td>
    </ng-template>
  </app-common-list-table>
</div>

<p-dialog
  header="Detalle del registro"
  [(visible)]="canShow['info']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['info']" style="width: 80vw !important">
    <div class="mb-5 col-12">
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="clienteInfo"
      >
      </app-form-builder>
    </div>
  </div>
</p-dialog>

<p-dialog
  header="Eliminar registro"
  [(visible)]="canShow['delete']"
  (onShow)="scrollToTop()"
>
  <div *ngIf="canShow['delete']" style="width: 80vw !important">
    <div class="mb-5 col-12">
      <app-form-builder
        [model]="model"
        [distributionColumns]="3"
        [fields]="clienteInfo"
      >
      </app-form-builder>
    </div>
    <br />
    <div class="d-flex justify-content-center">
      <button class="btn btn-dark mx-2" (click)="hide('delete')">Cerrar</button>
      <app-request-button
        (click)="deleteRegister()"
        [request]="deleteRequest"
        [mode]="deleteRequestButtonType"
      >
      </app-request-button>
    </div>
    <br />
    <p
      *ngIf="deleteRequest.hasError === undefined && !deleteRequest.isLoading"
      style="color: red; text-align: center"
    >
      ¿Está seguro de eliminar este registro?
    </p>
  </div>
  <br />
</p-dialog>

<p-dialog width="600" header="Importar datos Calc" [closeOnEscape]="false" [closable]="false" [(visible)]="showImportar" (onShow)="scrollToTop()">

  <div *ngIf="showImportar" style="width: 36vw !important">

      <div>
          <p-fileUpload name="myfile[]" multiple="false" [showCancelButton]="false" chooseLabel="Seleccionar archivo" uploadLabel="Enviar" [url]="urlUpload" (onSelect)="checkFile($event)">
          </p-fileUpload>
      </div>

      <div >
          <br>
          <p style="max-width: 400px; text-align: center; color: red" [innerHTML]="importError"></p>
          <!-- <p style="max-width: 400px; text-align: center; color: green" [innerHTML]="importError"></p> -->

      </div>
      <br>
      <div class="d-flex justify-content-center">
          <button class="btn btn-dark mx-2" (click)="closeFUDialog();">Cerrar</button>
      </div>
      <br>
  </div>
</p-dialog>