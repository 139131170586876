import {
  ItemInterface,
  InputType,
} from "src/app/common/components/form-builder/form-builder.component";
import { environment } from "src/environments/environment";

export class ClienteFormDefinition {
  public appName = environment.appName;
  public lastFieldChanged: string = "";

  public requestType: any;
  public model: any = {};

  public formFieldsDatos: ItemInterface<any>[] = [
    {
      field: "nombre",
      label: "Nombre",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },

    {
      field: "direccion",
      label: "Dirección",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "localidad",
      label: "Localidad",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "provincia",
      label: "Provincia",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "telefono",
      label: "Teléfono",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
    {
      field: "fax",
      label: "Fax",
      inputType: { type: InputType.EDIT_TEXT },
      required: true,
    },
  ];

  public formChanges(tag: string) {
    this.lastFieldChanged = tag;
  }
}
