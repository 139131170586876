import { environment } from './../../../../../environments/environment';
import { FincasModel } from './../../../../models/form-common/fincas.module';
import { SectorModel } from './../../../../models/form-common/sector.model';
import { ParcelasModel } from './../../../../models/form-common/parcelas.model';
import { FullMapModel } from './../../../../models/form-common/full-map.model';
import { SigPACModel } from './../../../../models/form-common/sigpac.model';
import { FincasApiService } from './../../../../service/api/fincas-api.service';
import { CamposApiService } from './../../../../service/api/campos-api.service';
import { MapaParcelasApiService } from './../../../../service/api/mapa-parcelas-api.service';
import { Component, Input, Output, EventEmitter, ElementRef } from '@angular/core';
import { ViewChild } from '@angular/core';
import { Filtering } from '../../../../service/filtering/filtering';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FormCommonApiService } from '../../../../service/api/formcommon-api.service';
// @ts-expect-error Peta al no devolver los tipados que TypeScript espera.
import { google } from '@google/maps';
import { StorageManager } from '../../../../common/storage-manager.class';
import { Observable } from 'rxjs';
import * as xml2js from 'xml2js';
import { SiexApiService } from '../../../../service/api/siex-api.service';
// @ts-ignore
import { MVTSource } from '@northpoint-labs/vector-tiles-google-maps';

import type { OnDestroy, OnInit } from '@angular/core';
import type { Observer } from 'rxjs';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { TratamientoTrackModel } from 'src/app/models/tratamientos/tratamiento-track.model';
import { BaseResponseModel } from 'src/base-response.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';
import { DashboardService } from 'src/app/service/events/dashboard.service';

declare const google: any;

@Component({
    selector: 'app-mapa-add',
    templateUrl: './mapa-add.component.html',
    styleUrls: ['./mapa-add.component.scss']
})
export class MapaAddComponent implements OnInit, OnDestroy{

    @ViewChild('gmap', {read: ElementRef, static: true}) gmapElement: ElementRef | undefined;
    @ViewChild('divSelectFincas', {read: ElementRef, static: true}) divSelectFincas: ElementRef | undefined;
    @ViewChild('divSelectSectores', {read: ElementRef, static: true}) divSelectSectores: ElementRef | undefined;
    @ViewChild('divSearchMap', {read: ElementRef, static: true}) divSearchMap: ElementRef | undefined;
    @ViewChild('searchMap', {read: ElementRef, static: true}) searchMap: ElementRef | undefined;
    @ViewChild('divSigpac', {read: ElementRef, static: true}) divSigpac: ElementRef | undefined;
    @ViewChild('divNuevaParcela', {read: ElementRef, static: true}) divNuevaParcela: ElementRef | undefined;
    // @ViewChild('divGuardarCambios', {read: false, static: false}) divGuardarCambios: ElementRef | undefined;
    // @ViewChild('divCancelarEdicion', {read: false, static: false}) divCancelarEdicion: ElementRef | undefined;
    @ViewChild('divCancelaParcela', {read: false, static: false}) divCancelaParcela: ElementRef | undefined;
    @ViewChild('divGuardarCambios', { static: false }) divGuardarCambios: ElementRef | null = null;
    @ViewChild('divCancelarEdicion', { static: false }) divCancelarEdicion: ElementRef | null = null;    

    @ViewChild('divMeteo', {read: ElementRef, static: true}) divMeteo: ElementRef | undefined;
    @ViewChild('divMeteoBtn', {read: ElementRef, static: true}) divMeteoBtn: ElementRef | undefined;

    @Input() origin = '';
    @Input() finca = '';
    @Input() sector = '';
    @Input() localidad = '';
    @Input() cultivo = '';
    @Input() variedad = '';
    @Input() parcelaNombre = '';
    @Input() coordenadas = '';

    @Output() fromMapa = new EventEmitter<any>();

    map: google.maps.Map = null;
    mvtSource: MVTSource = null;
    clickOptions: any = null;
    geocoder: google.maps.Geocoder = null;

    public appName = environment.appName;
    public applicationType = environment.features.applicationType;
    public fincaName = environment.features.fincaName;
    public fincasName = environment.features.fincasName;
    public sectorName = environment.features.sectorName;
    public parcelaName = environment.features.parcelaName;
    public showMeteoBtn = environment.features.showMeteoBtn;
    public showMapaLabels = environment.features.showMapaLabels;
    public hasClients = environment.features.hasClients;
    public hasPlantas = environment.features.hasPlantas;

    public arrayFincas: any[] = [];
    public arraySectores: SectorModel[] = [];
    public arrayParcelas: ParcelasModel[] = [];
    public arrayCultivos: any[] = [];
    public arrayCoord = [];
    public parcelaSelectedid = '';

    public obs1: Observable<any> | undefined;
    public obs2: Observable<any> | undefined;
    public obs3: Observable<any> | undefined;

    public arrayVariedades: VisitasVariedadMesModel[] = [];

    public arrayMapaParcelas: {
        id: string;
        id_parcela: string;
        id_finca: string;
        id_cultivo: string;
        coordenadas: string;
        color: string;
        id_usuario: string;
    }[] = [];

    public sigPAC: SigPACModel = new SigPACModel();

    public strFinca = '';
    public fincaSelected = '';
    public fincaSelectedId = '';

    public strSector = '';
    public sectorSelected = '';
    public sectorSelectedId = '';

    public strCultivos = '';
    public cultivoSelected = '';
    public cultivoSelectedId = '';

    public strVariedades = '';
    public variedadSelected = '';
    public variedadSelectedId = '';

    //public fincasSelect: SafeHtml;
    public showCargando = false;
    public contentString = '';
    public infowindow: any = null;
    public infoWindOpened = false;
    public numPlantas = '';

    public oldmap: any = null;

    //Estilos para los poligonos
    public estilo_hover = { fillColor: 'blue', strokeWeight: 3, strokeColor: 'black' };
    public estilo_poligono_clic = { fillColor: '#ff8000', strokeWeight: 5, strokeColor: '#282828' };

    public poly_btn: HTMLButtonElement | undefined;

    //Zoom a partir del que se cargan las parcelas de catastro
    public zoomCatastro = 16;

    //Lista de poligonos cargados en el mapa
    public idsPoligonos: any[] = [];

    //Lista de poligonos que se han hecho clic dentro de la misma sesión
    public idsPoligonosClic: string[] = [];

    public arrayFincasClicked: any[] = [''];

    public markers: any = [];
    public bounds: any = new google.maps.LatLngBounds();

    public coordenadas_locatec = '';

    public miJSON: any = {};
    public parcelaObj: any = [];
    public markerLabels: any = [];
    public selectedShape: any;
    public drawingManager: google.maps.drawing.DrawingManager;

    public showSigpac = false;
    public creatingParcel = false;

    public loadCount = 0;
    public arrayFullMapObj: FullMapModel[] = [];

    public lastFinca = StorageManager.getLastFincaMap();
    public lastLatLng = StorageManager.getLastLatLng();
    public lastZoom = StorageManager.getLastZoom();
    public showWeather = false;
    // public showMeteoBtn: boolean = (environment.appName == 'manezylozano' || environment.appName == 'fitogest') ? true : false;
    public routes: any = [];

    public parcelaDatos: any = {};
    
    public currentOverlay: google.maps.OverlayView | null = null;
    
    public nombreParcela = '';
    private cultivosArray: {id: number, cultivo: string}[] = [];
    private variedadesArray: {id: number, cultivo:string, variedad: string}[] = [];


    constructor(private http: HttpClient,
        private formApi: FormCommonApiService,
        private fincasApi: FincasApiService,
        private camposApi: CamposApiService,
        private mapaParcelasApi: MapaParcelasApiService,
        private siexApi: SiexApiService,
        private dashboard: DashboardService) { }

    /**
  * Styles an element as a button
  */
    public buttonize = (
        btn: { 
            type: string; 
            setAttribute: (arg0: string, arg1: string) => void; 
            innerHTML: string; 
            addEventListener: (arg0: string, arg1: { (): void; (): void; }) => void; 
            style: { background: string; }; 
        }) => {
    // Set CSS for the control border.
        btn.type = 'button';
        btn.setAttribute('style', `
      background-color: white;
      cursor: pointer;
      padding: 0px 8px;
      margin: 5px;
      margin-left: -5px;
      border-radius: 2px;
      color:black;
      font-size: 2.2em;
      border:none;
    `);
        btn.innerHTML = '+';

        btn.addEventListener('mouseover', () => {
            btn.style.background = 'grey';
        });
        btn.addEventListener('mouseout', () => {
            btn.style.background = 'white';
        });
    };

    ngOnInit() {
        this.showCargando = true;

        this.dashboard.clientChanges$.subscribe(() => {
            // const newClient = StorageManager.getClient();
            // console.log('newClient', newClient);
            this.onClientChange();
        });

        this.autoSelectDropdowns();

        if (this.appName === 'agrosalvi') {
            this.getPosition(); //Try to get GPS device position
        }

        this.getMapData();

        this.loadScript('https://weatherwidget.io/js/widget.min.js');

        this.initializeMap();

        document.addEventListener('keydown', this.handleKeyDown.bind(this));
    }

    // handleKeyDown(event: KeyboardEvent) {
    //     if (event.key === 'Escape') {
    //         this.cancelaParcela();
    //     }
    // }

    onClientChange() {
        this.showCargando = true;
        this.clearMapData();
    
        this.getMapData();
    }

    clearMapData() {
       
        // Eliminar poligonos del mapa
        this.parcelaObj.forEach((parcel: { setMap: (arg0: null) => void; }) => {
            parcel.setMap(null);
        });
        this.parcelaObj = [];

        // Eliminar marcadores (labels)
        this.markerLabels.forEach((marker: { setMap: (arg0: null) => void; }) => {
            marker.setMap(null);
        });
        this.markerLabels = [];
        // Limpiar arrays de datos
        this.arrayParcelas = [];
        this.arrayMapaParcelas = [];
        this.arrayFullMapObj = [];
        // this.arrayParcelasTratamiento = [];
    }

    handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Escape') {
            if (this.creatingParcel && this.currentOverlay) {
                if (typeof this.currentOverlay.setMap === 'function') {
                    this.currentOverlay.setMap(null);  // Elimina el overlay actual del mapa
                }
                this.currentOverlay = null;  // Limpiar la referencia al overlay
            }
            this.cancelaParcela();  // Lógica adicional para cancelar la acción
        }
    }
    

    autoSelectDropdowns() {
        if (this.origin !== 'parcelas-form' && this.origin !== 'parcelas') {
            this.finca = this.lastFinca;
        } else {
            this.cultivoSelected = this.cultivo;
            this.variedadSelected = this.variedad;
            this.sectorSelected = this.sector;
        }
        this.fincaSelected = this.finca;
    }

    getMapData() {
        Promise.all([
            this.getFincas(),
            this.getSectores(),
            this.getParcelas(),
            this.getCultivos(),
            this.getVariedades(),
        ]).then(() => this.getMapaParcelas()).then(() => {
            this.getFullMapObject(false);
            return;
        }).then(() => {

            // Guarda en fincaSelectedId la id de la finca obtenida en StoreManager.
            const lastFinca = StorageManager.getLastFincaMap();
            const idLastFinca = this.arrayFincas.find(finca => finca.nombre === lastFinca);
            if (idLastFinca){
                this.fincaSelectedId = idLastFinca.id;
            }

            if (this.appName === 'manezylozano' && StorageManager.getUser().nombre === 'Quique') {
                this.getTracksMyL();
            }
            else if (this.appName === 'innovia') {
                this.getTareasInnovia();
            }
            return;
        }).catch (e => {
            console.log('catch en getPosition: ' + e);
        }
        );
    }

    showWeatherFunction() {
        this.showWeather = !this.showWeather;
        const divMeteo = this.divMeteo?.nativeElement;
        if (this.showWeather) {this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(divMeteo);}
        else {this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].pop(divMeteo);}
    }

    public loadScript(url: string) {
        const body = document.body as HTMLDivElement;
        const script = document.createElement('script');
        script.innerHTML = '';
        script.src = url;
        script.async = false;
        script.defer = true;
        body.appendChild(script);
    }


    ngOnDestroy() {
        document.removeEventListener('keydown', this.handleKeyDown.bind(this));

        this.fincasApi.fincasGET.unsuscribe();
        this.fincasApi.sectoresGET.unsuscribe();
        this.fincasApi.parcelasGET.unsuscribe();
        this.mapaParcelasApi.mapaParcelasGET.unsuscribe();
    }


    getWeather(lat: string, lng: string) {

        let url = 'https://api.darksky.net/forecast/b31f163d0d336d1382eccab2383fc9e1/lat,lng?lang=es&exclude=currently,daily';

        url = url.replace('lat', lat);
        url = url.replace('lng', lng);

        this.http.get(url).subscribe(() => {
            //console.log(data);
        });
    }

    initializeMap() {

        let lat: number;
        let lng: number;
        try {
            lat = parseFloat(this.lastLatLng.split(',')[0] ?? '');
            lng = parseFloat(this.lastLatLng.split(',')[1] ?? '');
        }
        catch (err) {
            console.log(err);
            lat = 38.991071;
            lng = -0.792838;
            this.lastZoom = 6;
            this.showCargando = false;
        }

        //this.getWeather(lat.toString(),lng.toString());

        this.map = new google.maps.Map(this.gmapElement?.nativeElement, {
            zoom: 14, // Sí en una sesión el usuario crea una parcela sin pasar por el nivel 15 de zoom, esta no se pintará correctamente.
            center: new google.maps.LatLng(lat, lng),
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            },
            mapTypeId: google.maps.MapTypeId.HYBRID,
            mapTypeControlOptions: {
                mapTypeIds: ['roadmap', 'hybrid'],
                position: google.maps.ControlPosition.TOP_RIGHT,
                labels: true
            },
            disableDefaultUI: false,
            fullscreenControl: false,
            streetViewControl: false,
            tilt: 0 // para que no se muestre el mapa con 45º
        });

        this.cargaMapListeners();
        this.drawUserInterface();

        //console.log(window);
        window.scrollTo(0, 0);
        window.scrollBy(0, 0);
        window.scroll(0, 0);
    }


    drawUserInterface(){
        //FILTROS DE FINCA Y SECTOR
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divSelectFincas?.nativeElement);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divSelectSectores?.nativeElement);

        //BOTÓN "+"
        this.initDrawingManager();

        //BUSCADOR DE LUCARES
        this.initPlacesSearchbox();

        //BOTON NUEVA PARCELA
        const divNueva = this.divNuevaParcela?.nativeElement;
        if (this.appName !== 'innovia') {this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(divNueva);}

        //BOTON METEO
        if (this.showMeteoBtn) {
            const divMeteoBtn = this.divMeteoBtn?.nativeElement;
            this.map.controls[google.maps.ControlPosition.BOTTOM_LEFT].push(divMeteoBtn);
        }
    }

    initDrawingManager() {
    //Bloque para añadir mapa de forma manual
        const polyOptions = {
            strokeWeight: 0,
            fillOpacity: 0.45,
            editable: true
        };
        // Creates a drawing manager attached to the map that allows the user to draw
        // markers, lines, and shapes.
        this.drawingManager = new google.maps.drawing.DrawingManager({
            drawingMode: google.maps.drawing.OverlayType.POLYGON,
            drawingControl: true,
            drawingControlOptions: {
                position: google.maps.ControlPosition.TOP_CENTER,
                drawingModes: ['']
            },
            polygonOptions: polyOptions,
            map: this.map
        });

        if (this.drawingManager.drawingMode) { this.drawingManager.setDrawingMode(null); }

        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///////////                    SE CREA LA PARCELA DE FORMA MANUAL (SIN SIGPAC)                            //
        ////////////////////////////////////////////////////////////////////////////////////////////////////////////
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event: { overlay: { getPath: () => any; }; }) => {
            this.currentOverlay = event.overlay; 
            const path = event.overlay.getPath();
            let bounds = new google.maps.LatLngBounds();
            bounds = this.setCoordLocatec(path, 1);
            this.cargaDatosSIGPAC(event, bounds);
        });

        //Boton para activar edicion de mapas
        this.poly_btn = document.createElement('button');
        this.buttonize(this.poly_btn);
        this.map.controls[google.maps.ControlPosition.TOP_CENTER].push(this.poly_btn);

        this.poly_btn.addEventListener(
            'click', (() => () => {
                this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            })()
        );

        google.maps.event.addListener(this.drawingManager, 'drawingmode_changed', this.clearSelection);
        google.maps.event.addListener(this.map, 'click', this.clearSelection);
        google.maps.event.addListener(this.map, 'maptypeid_changed', () => { console.log(this.map.getMapTypeId()); });


        //Eventos al entrar y salir el puntero de un polígono
        this.map.data.addListener('mouseover', (event: { feature: { Jg: { id: string; }; Gg: { id: string; }; }; }) => {
            //console.log('event ====>' + JSON.stringify(event, getCircularReplacer()));
            try{
                if (!this.arrayFincasClicked.includes(event.feature.Jg.id)) {this.map.data.overrideStyle(event.feature, this.estilo_hover);}
            }
            catch (error) {
                console.log(error);
                if (!this.arrayFincasClicked.includes(event.feature.Gg.id)) {this.map.data.overrideStyle(event.feature, this.estilo_hover);}
            }
        });

        this.map.data.addListener('mouseout', (event: { feature: { Jg: { id: string; }; Gg: { id: string; }; }; }) => {
            try{
                if (!this.arrayFincasClicked.includes(event.feature.Jg.id)) {this.map.data.revertStyle(event.feature);}
            }
            catch (error) {
                console.log(error);
                if (!this.arrayFincasClicked.includes(event.feature.Gg.id)) {this.map.data.revertStyle(event.feature);}
            }

        });
    }

    initPlacesSearchbox() {
        ///// BUSCADOR DE LOCALIDADES ///////
        // Create the search box and link it to the UI element.
        const divInput = this.divSearchMap?.nativeElement;
        const input = this.searchMap?.nativeElement;
        const searchBox = new google.maps.places.SearchBox(input);
        const autocomplete = new google.maps.places.Autocomplete(input);
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(divInput);
        autocomplete.setComponentRestrictions({ 'country': ['es'] });

        const map = this.map;

        searchBox.addListener('places_changed', function () {
            searchBox.set('map', null);
            const places = searchBox.getPlaces();
            if (places.length === 0) { return; }
            const boundss = new google.maps.LatLngBounds();

            places.forEach(function (place: { geometry: { viewport: any; location: any; }; }) {
                if (!place.geometry) {
                    console.log('Returned place contains no geometry');
                    return;
                }

                if (place.geometry.viewport) { boundss.union(place.geometry.viewport); }
                else { boundss.extend(place.geometry.location); }
            });

            try { map.fitBounds(boundss); }
            catch (e) { console.log('catch en fitbounds: ' + e); }

            const lat: string = boundss.getCenter().lat();
            const lng: string = boundss.getCenter().lng();

            StorageManager.saveLastLatLng(lat + ',' + lng);
        });

        autocomplete.addListener('place_changed', function () {
            searchBox.set('map', null);
            const place = autocomplete.getPlace();
            const boundss = new google.maps.LatLngBounds();

            if (!place.geometry) {
                console.log('Returned place contains no geometry');
                return;
            }

            if (place.geometry.viewport) { boundss.union(place.geometry.viewport); }
            else { boundss.extend(place.geometry.location); }

            try { map.fitBounds(boundss); }
            catch (e) { console.log('catch en fitbounds: ' + e); }

            const lat: string = boundss.getCenter().lat();
            const lng: string = boundss.getCenter().lng();
            StorageManager.saveLastLatLng(lat + ',' + lng);
        });
    }

    setSuperficie(path: any): string {
        const measurement = google.maps.geometry.spherical.computeArea(path);
        const squareMeters = measurement.toFixed(2);
        return (squareMeters / 10000).toFixed(2);
    }

    cargaMapListeners() {
        google.maps.event.addListener(this.map, 'dragend', () => {
            const lat: string = this.map.getCenter().lat();
            const lng: string = this.map.getCenter().lng();
            this.lastLatLng = lat + ',' + lng;
            StorageManager.saveLastLatLng(this.lastLatLng);
        });
        google.maps.event.addListener(this.map, 'zoom_changed', () => {
            //this.overlay();
            this.lastZoom = this.map.getZoom();
            StorageManager.saveLastZoom(this.lastZoom);

            if (this.appName !== 'innovia' && this.map.getZoom() >= 15) {
                if (this.map.overlayMapTypes.length === 0) {
                    this.initVectorTiles();
                    this.map.setOptions({ draggableCursor: 'pointer' });
                }
            } else {
                if (this.map.overlayMapTypes.length > 0) {
                    this.map.overlayMapTypes.removeAt(0);
                    this.map.setOptions({ draggableCursor: 'default' });
                }
                //alert("Debe acercarse más para poder activar SigPAC");
            }
        });

        //Sólo me interesa tenerlo cuando se carga el mapa por primera vez
        google.maps.event.addListenerOnce(this.map, 'tilesloaded', () => {
            ///// SELECCIONA FINCA DESDE PARCELAS FORM /////
            const objFinca = this.divSelectFincas?.nativeElement.firstChild;
            objFinca.value = this.finca;
            const objSector = this.divSelectSectores?.nativeElement.firstChild;
            objSector.value = this.sector;

            if (this.origin === 'parcelas-form' || this.origin === 'parcelas') {
                objFinca.disabled = true;
                if (this.arrayFincas.length > 0) {this.getFincaSelected(this.finca);}
                else {setTimeout(() => { this.getFincaSelected(this.finca); }, 1000);}
                objSector.disabled = true;
                if (this.arraySectores.length > 0) {this.getSectorSelected(this.sector);}
                else {setTimeout(() => { this.getSectorSelected(this.sector); }, 1000);}
            }
            //this.overlay();
        });
    }

    initVectorTiles() {
    // https://github.com/techjb/Vector-Tiles-Google-Maps/blob/master/DOCUMENTATION.md
        const options = {
            url: 'https://sigpac-hubcloud.es/mvt/recinto@3857@pbf/{z}/{x}/{y}.pbf',
            //sdebug: true,
            cache: true,
            sourceMaxZoom: 15,
            getIDForLayerFeature: function(
                feature: { 
                    properties: { 
                        provincia: string; 
                        municipio: string; 
                        poligono: string; 
                        parcela: string; 
                        recinto: string; 
                    }; 
                }
            ) {
                return feature.properties.provincia + '' +
                  feature.properties.municipio + '' +
                  feature.properties.poligono + '' +
                  feature.properties.parcela + '' +
                  feature.properties.recinto;
            },
        };

        // https://sigpac-hubcloud.es/mvt/recinto@3857@geojson/{z}/{x}/{y}.geojson
        // https://sigpac-hubcloud.es/mvt/linea_declaracion@3857@geojson/{z}/{x}/{y}.geojson
        // https://sigpac-hubcloud.es/mvt/e_paisaje_punto@3857@geojson/{z}/{x}/{y}.geojson

        this.mvtSource = new MVTSource(this.map, options);
        this.map.overlayMapTypes.insertAt(0, this.mvtSource);

        this.clickOptions = {
            limitToFirstVisibleLayer: true, // Trigger events only to the first visible layer (default: false)
            multipleSelection: true, // Multiple feature selection (default: false)
            setSelected: true, // Set feature selected style (default: false)
            toggleSelection: false, // Toggle feature selected style (default: true)
            delay: 50 /* If new event is triggered before delay, old event will be ignored.
            Used to avoid overload on mousemove event (default: 0)*/
        };

        this.map.addListener('click', (event: any) => {
            event['tipo'] = 1;
            event['source'] = this;
            this.mvtSource.deselectAllFeatures();
            this.mvtSource.onClick(event, this.abreInfoWindow.bind(this), this.clickOptions);
        });

    }

    cargaDatosSIGPAC(event: { overlay: any; }, bounds: { getCenter: () => { (): any; new(): any; lng: { (): string; new(): any; }; lat: { (): string; new(): any; }; }; }) {

        //Obtener información catastral a partir de coordenadas lat/long
        let url = 'https://ovc.catastro.meh.es/ovcservweb/OVCSWLocalizacionRC/OVCCoordenadas.asmx/Consulta_RCCOOR&SRS=EPSG:4326&';
        //let url = 'https://www.sedecatastro.gob.es/ovcservweb/OVCSWLocalizacionRC/OVCCoordenadas.asmx/Consulta_RCCOOR&SRS=EPSG:4326&';
        url += '&Coordenada_X=' + bounds.getCenter().lng();
        url += '&Coordenada_Y=' + bounds.getCenter().lat();

        const urlOVC = environment.serverUrl + 'ws/mapas/curl_get_xml.php?url=' + url;

        let xml, pc: any;
        this.obs1 = this.http.get(urlOVC, { responseType: 'text' });

        this.obs1.subscribe(
            data => {
                try{
                    xml = this.xmlToJson(data);
                    pc = xml.consulta_coordenadas.coordenadas[0].coord[0].pc[0];
                    pc = pc.pc1 + pc.pc2;
                }
                catch (e) { console.log('catch...',e); pc = '              '; }

                this.setSigPAC(pc, 2);
                this.abreInfoWindow(event.overlay, 2, 0, bounds);
            },
            () => {
                console.log('error ocurred...');
            }
        );
    }


    montaInfoWindowContent(superficieSigpac: string, tipo?: number) {

        let lockCultivoVariedad = '';
        let elimButton = '';
        let guardaButton = '';
        let editButton = '';
        let title = '';

        //Comprueba si infowindow abierto
        try {
            if (this.infowindow.getMap() == null) { this.infoWindOpened = false; }
            this.infowindow.close();
        }
        catch (err) { /*console.log(err);*/ }

        if (tipo === 3) { // Editando parcela. Ya está añadida a alguna finca
            elimButton = '<button id="elimina" type="button" class="btn btn-danger col-md-2" style="margin-left: 6px">Eliminar</button>';
            editButton = '<button id="edita" type="button" class="btn btn-success col-md-4" style="margin-left: 6px">Editar</button>';
        }
        else {
            guardaButton = '<button id="guarda" type="button" class="btn btn-success col-md-4" style="margin-left: 10px">Guardar</button>';
        }

        //SI ESTAMOS EN ORIGEN PARCELAS NO DEBE DEJAR EDITAR
        if (tipo === 3 && (this.origin === 'parcelas-form' || this.origin === 'parcelas')) {
            elimButton = ''; editButton = ''; guardaButton = '';
        }

        if (this.origin === 'parcelas-form' || this.origin === 'parcelas' || tipo === 3) {
            this.strCultivos = this.strCultivos.replace(' selected', '');
            this.strVariedades = this.strVariedades.replace(' selected', '');
            this.strCultivos = this.strCultivos.replace('\'' + this.cultivoSelected + '\'', '\'' + this.cultivoSelected + '\' selected');
            this.strVariedades = this.strVariedades.replace('| ' +
                this.variedadSelected + '\'', '| ' + this.variedadSelected + '\' selected'
            );
            if (tipo !== 3) {lockCultivoVariedad = 'disabled';}
        }

        //String SIGPAC para el infowindow
        let str3 = '';
        str3 = '<div class=\'row mb-4 mt-4\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>SigPAC</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.referencia.trim() + '\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Superficie</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + superficieSigpac.trim() + '\' id=\'superficie\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row mb-4 mt-4\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Provincia</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.provincia.trim() + '\' id=\'provincia\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Municipio</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.municipio.trim() + '\' id=\'municipio\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row mb-4 mt-4\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Polígono</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.poligono.trim() + '\' id=\'poligono\' type=\'text\'>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Parcela</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.parcela.trim() + '\' id=\'parcela\' type=\'text\'>';
        str3 += '</div>';
        str3 += '</div>';

        str3 += '<div class=\'row mb-4 mt-4\' style=\'margin-left:4px;\'>';
        str3 += '<div class=\'col-md-2\'>';
        str3 += '  <label>Recinto</label>';
        str3 += '</div>';
        str3 += '<div class=\'col-md-4\'>';
        str3 += '  <input class=\'form-control iwinput\' value=\'' + this.sigPAC.recinto.trim() + '\' id=\'recinto\' type=\'text\'>';
        str3 += '</div>';         
        str3 += '</div>';
        str3 += '';

        str3 += '<div class="row mb-4 mt-4" style="margin-left:4px;">' +
        '<div class="col-md-2">' +
        '<label>Nombre</label>' +
        '</div>' +
        '<div class="col-md-4">' +
        '<input class="form-control iwinput" value="' + this.nombreParcela.trim() + '" id="nombreParcela" type="text">' +
        '</div>' +
        '</div>';

        if (environment.appName === 'manezylozano') {title = 'Campo <br>' + this.fincaSelected;}
        else {
            if (tipo !== 3) {title = 'Crear Parcela<br>' + this.fincaSelected;}
            else {
                const plantas: number =  Math.trunc(+this.numPlantas);
                if (!this.hasPlantas) {
                    title = 'Parcela ' + this.parcelaNombre + ' (' + plantas + ' árboles)' + '<br>' + this.fincaSelected;
                } else if (this.hasPlantas) {
                    title = 'Parcela ' + this.parcelaNombre + ' (' + plantas + ' plantas)' + '<br>' + this.fincaSelected;
                } else {
                    title = 'Parcela ' + this.parcelaNombre + '<br>' + this.fincaSelected;
                }
            }
        }

        this.contentString =
      '<div id="contentIW" style="min-width:300px; max-width:98%; overflow:hidden; font-weight:400;">' +
      '<div class="row" style="margin-left:10px; margin-top:20px; padding:10px; background:#aaaaff">' +
      '<label style="font-size: 1.6em; text-align: center; margin: auto">' + title + '</label>' +
      '</div>' +

      str3 +

      '<div class="row"  style="margin-left:4px;">' +
      '<div class="col-md-2">' +
      '<label>Cultivo</label>' +
      '</div>' +
      '<div class="col-md-4">' +
      '<select ' + lockCultivoVariedad + ' class="form-control" name="cultivos" id="cultivos" style="font-size: 1em;">' +
      this.strCultivos +
      '</select>' +
      '</div>' +

      '<div class="col-md-2">' +
      '<label>Variedad</label>' +
      '</div>' +
      '<div class="col-md-4" id="divVariedades">' +
      '<select ' + lockCultivoVariedad + ' class="form-control" name="variedades" id="variedades" style="font-size: 1em;">' +
      this.strVariedades +
      '</select>' +
      '</div>' +
      '</div>' +
      '</div>' +

      '<div class="row">' +
      '<div id="color-palette" style="margin:0 auto; text-align: center"></div>' +
      '</div>' +

      '<div class="row" style="display: block; padding: 16px; margin: auto; text-align: center">' +
      '<button id="cancela" type="button" class="btn btn-basic col-md-2" style="margin-left: 6px;">Cerrar</button>' +
      editButton + elimButton + guardaButton +
      '</div>' +
      '</div>' +
      '</div>';

    }


    setSigPAC(refpar: string, event: any) {
    //Comprueba que la referencia es de parcela rustica y extraemos valores
        console.log('setSigPac', refpar, event);
        this.sigPAC.referencia = refpar;

        if (!isNaN(+refpar.substr(6, 3)) && !isNaN(+refpar.substr(9, 5))) {
            if (event === 2) {
                this.sigPAC.provincia = refpar.substr(0, 2);
                this.sigPAC.municipio = refpar.substr(2, 3);
                this.sigPAC.poligono = refpar.substr(6, 3);
                this.sigPAC.parcela = refpar.substr(9, 5);
                this.sigPAC.recinto = '0';
            } else {
                this.sigPAC.provincia = event.feature.properties.provincia.toString();
                this.sigPAC.municipio = event.feature.properties.municipio.toString();
                this.sigPAC.poligono = event.feature.properties.poligono.toString();
                this.sigPAC.parcela = event.feature.properties.parcela.toString();
                this.sigPAC.recinto = event.feature.properties.recinto.toString();
                this.sigPAC.superficie = (event.feature.properties.superficie / 10000).toFixed(2).toString();
                this.sigPAC.usoSigpac = event.feature.properties.uso_sigpac;
            }
        }
        else {
            console.log('Parcela Urbana');
            this.sigPAC.provincia = '';
            this.sigPAC.municipio = '';
            this.sigPAC.poligono = '';
            this.sigPAC.parcela = '';
            this.sigPAC.recinto = '';
        }
    }


    setCoordLocatec(path: any[], t: number): any {
    //Extraemos las coordenadas de la parcela seleccionada en formato Locatec
        let latInicial = '';
        let lngInicial = '';
        const bounds = new google.maps.LatLngBounds();
        let coordenadasLct = '';
        const pathParcel: any = [];

        if (t === 1) {
            path.forEach((it: { lat: () => string; lng: () => string; }) => {
                coordenadasLct += '(' + it.lat() + ',' + it.lng() + ');';
                //Guardamos las coordenadas iniciales para poder cerrar el polígono
                if (latInicial === '') { latInicial = it.lat(); lngInicial = it.lng(); }

                pathParcel.push(new google.maps.LatLng(it.lat(), it.lng()));
                bounds.extend(it);
            });
            this.sigPAC.superficie = this.setSuperficie(pathParcel);
        }
        else {
            for (const key in path) {
                if (key) {
                    const tileCoordinates =  key.split(':');
                    const coordinates = path[key].vectorTileFeature.toGeoJSON(
                        tileCoordinates[1],
                        tileCoordinates[2],
                        tileCoordinates[0]
                    ).geometry.coordinates[0];
                    coordinates.forEach((coordenadas: string[]) => {
                        coordenadasLct += '(' + coordenadas[1] + ',' + coordenadas[0] + ');';

                        //Guardamos las coordenadas iniciales para poder cerrar el polígono
                        if (latInicial === '') { latInicial = coordenadas[1] ?? ''; lngInicial = coordenadas[0] ?? ''; }
                    });
                    break;
                }
            }
        }

        //Cerramos el polígono
        this.coordenadas_locatec = coordenadasLct;
        this.coordenadas_locatec += '(' + latInicial + ',' + lngInicial + ')';

        return bounds;
    }



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    ///////                    FUNCION ABRE INFO WINDOW UNICA PARA TODOS LOS CASOS                            //
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////
    abreInfoWindow(event: any, tipo: number, id?: number, bounds?: { getCenter: () => any; } | undefined) {
    // MTV opera en un contexto donde el objeto .this que hace referencia a MapaAddComponent no existe, así que se fusionan ambos contextos.
        event.source = event.source ?? this;
        tipo = event.tipo ?? tipo;
        event.source.parcelaSelectedid = id;

        // SI ESTAN LOS BOTONES DE EDITAR ACTIVOS SE ESCONDEN Y SE MUESTRA CREAR PARCELA
        if (event.source.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 5) {
            event.source.cancelarEdicion();
        }

        //Extrae propiedades de polígono y parcela
        let refpar = '';

        ///////// Parcela NUEVA con SIGPAC SI (tipo 1) /////////
        if (tipo === 1) {
            refpar = event.feature.featureId;

            //Controlamos si el elemento ya ha sido cliclado en la misma sesión, para no añadirlo 2 veces
            if (!event.source.idsPoligonosClic.includes(refpar)) {
                event.source.setSigPAC(refpar, event);
                const path = event.feature.tiles;
                event.source.setCoordLocatec(path, 2);
            }
        }

        ////////// Parcela NUEVA con SIGPAC NO (tipo 2) ///////////
        else if (tipo === 2) {
        }

        ////////// Parcela que editamos (tipo 3) ///////////
        else if (tipo === 3) {
            this.nombreParcela = event.source.arrayFullMapObj[id ?? 0].parcela_nombre || '';
            console.log('Edit, ParcelaName: ', this.nombreParcela);

            if (environment.appName === 'manezylozano') {event.source.fincaSelected = event.sourec.arrayFullMapObj[id ?? 0].parcela_nombre;}
            else {event.source.fincaSelected = event.source.arrayFullMapObj[id ?? 0].finca;}
            event.source.sigPAC.provincia = event.source.arrayFullMapObj[id ?? 0].cod_provincia;
            event.source.sigPAC.municipio = event.source.arrayFullMapObj[id ?? 0].municipio;
            event.source.sigPAC.poligono = event.source.arrayFullMapObj[id ?? 0].poligono;
            event.source.sigPAC.parcela = event.source.arrayFullMapObj[id ?? 0].parcela;
            event.source.sigPAC.recinto = event.source.arrayFullMapObj[id ?? 0].recinto;
            event.source.sigPAC.superficie = event.source.arrayFullMapObj[id ?? 0].superficie;

            event.source.cultivoSelected = event.source.arrayFullMapObj[id ?? 0].cultivo;
            event.source.variedadSelected = event.source.arrayFullMapObj[id ?? 0].variedad;

            event.source.sigPAC.referencia = (
                event.source.arrayFullMapObj[id ?? 0].sigpac !== undefined ||
                event.source.arrayFullMapObj[id ?? 0].sigpac !== ''
            ) ?
                event.source.getRC([
                    event.source.sigPAC.provincia,
                    event.source.sigPAC.municipio,
                    event.source.sigPAC.poligono,
                    event.source.sigPAC.parcela]
                ) :
                event.source.arrayFullMapObj[id ?? 0].sigpac;

            event.source.parcelaNombre = event.source.arrayFullMapObj[id ?? 0].parcela_nombre;

            const parcela = 
                event.source.arrayParcelas.find((el: { id: string; }) => 
                    el.id === event.source.arrayFullMapObj[id ?? 0].id_parcela
                );
            if (parcela !== undefined) {event.source.numPlantas = (parcela.plantas) || '';}
        }


        //Comprueba si infowindow abierto
        try {
            if (event.source.infowindow.getMap() == null) { event.source.infoWindOpened = false; }
            event.source.infowindow.close();
            event.source.parcelaObj.forEach((element: { setEditable: (arg0: boolean) => void; }) => {
                element.setEditable(false);
            });
        }
        catch (err) { console.log(err); }

        event.source.montaInfoWindowContent(event.source.sigPAC.superficie, tipo);

        event.source.infowindow = new google.maps.InfoWindow({
            content: event.source.contentString
        });


        //ABRE EL INFOWINDOW
        event.source.infowindow.setContent(event.source.contentString);

        if (tipo === 2) {event.source.infowindow.setPosition(bounds?.getCenter());}
        else {event.source.infowindow.setPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });}
        event.source.infowindow.open(event.source.map);
        event.source.infoWindOpened = true;

        event.source.infowindow.addListener('domready', () => {

            //Parcela nueva creada
            if (tipo !== 3) {
                document.getElementById('guarda')?.addEventListener('click', () => {
                    console.log('Guardando1');

                    // Obtiene el valor del input nombreParcela y asigna el valor a la variable nombreParcela
                    const nombreParcelaInput = (document.getElementById('nombreParcela') as HTMLInputElement).value;
                    console.log('CustomName: ', nombreParcelaInput);
                    console.log('Contexto de this:', this); 
                    this.nombreParcela = nombreParcelaInput;

                    const objVariedades: any = document.getElementById('variedades');
                    let variedadSel = '';
                    for (let i = 0; i < objVariedades.options.length; i++) {
                        if (objVariedades.options[i].selected) { variedadSel = objVariedades.options[i].value; }
                    }

                    event.source.cultivoSelected = variedadSel.split('|')[0]?.trim();
                    event.source.variedadSelected = variedadSel.split('|')[1]?.trim();
                    if (event.source.origin === 'parcelas-form' || event.source.origin === 'parcelas') {
                        event.source.cultivoSelected = event.source.cultivo;
                        event.source.variedadSelected = event.source.variedad;
                    }
                    console.log('Se procede a crear');
                    console.log(event.source.sigPAC);   
                    event.source.guardarIW(event, event.source.sigPAC.superficie, tipo);
                });
            }

            //Parcela ya existente
            else if (tipo === 3 && event.source.origin !== 'parcelas-form' && event.source.origin !== 'parcelas') {

                document.getElementById('elimina')?.addEventListener('click', () => {
                    event.source.eliminarParcela(event, refpar, id);
                    this.nombreParcela = '';
                });
            
                document.getElementById('edita')?.addEventListener('click', () => {

                    const superficie = (document.getElementById('superficie') as HTMLInputElement).value;
                    const provincia = (document.getElementById('provincia') as HTMLInputElement).value;
                    const municipio = (document.getElementById('municipio') as HTMLInputElement).value;
                    const poligono = (document.getElementById('poligono') as HTMLInputElement).value;
                    const parcela = (document.getElementById('parcela') as HTMLInputElement).value;
                    const recinto = (document.getElementById('recinto') as HTMLInputElement).value;
                    const cultivo = (document.getElementById('cultivos') as HTMLSelectElement).value;
                    const variedad = (document.getElementById('variedades') as HTMLSelectElement).value;
                    const nombreParcelaInput = (document.getElementById('nombreParcela') as HTMLInputElement).value;
                    console.log('Nuevo NombreParcela: ', nombreParcelaInput);
    
                    this.parcelaDatos = {
                        superficie: superficie,
                        provincia: provincia,
                        municipio: municipio,
                        poligono: poligono,
                        parcela: parcela,
                        recinto: recinto,
                        cultivo: cultivo,
                        variedad: variedad,
                        nombreParcela: nombreParcelaInput
                    };

                    console.log(this.fincaSelectedId);
                    this.nombreParcela = '';
            
                    if (!event.source.divGuardarCambios || !event.source.divCancelarEdicion) {
                        console.error('Elementos divGuardarCambios o divCancelarEdicion no están definidos');
                        return;
                    }
            
                    if (event.source.parcelaObj[id ?? 0]) {
                        event.source.arrayCoord = event.source.parcelaObj[id ?? 0].getPath();
                 
                        if (event.source.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 4) {
                            event.source.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
                            event.source.map.controls[google.maps.ControlPosition.LEFT_TOP]
                                .push(event.source.divGuardarCambios.nativeElement);
                            event.source.map.controls[google.maps.ControlPosition.LEFT_TOP]
                                .push(event.source.divCancelarEdicion.nativeElement);
                        }
                 
                        document.getElementById('divCancelarEdicion')?.addEventListener('click', () => {
                            event.source.cancelarEdicion();
                            event.source.parcelaObj[id ?? 0].setEditable(false);
                        });
                 
                        event.source.parcelaObj[id ?? 0].setEditable(true);
                        event.source.infowindow.close();
                    } else {
                        console.error('El objeto parcela seleccionado está indefinido');
                    }
                });
            }
            

            document.getElementById('cancela')?.addEventListener('click', () => {
                this.nombreParcela = '';
                event.source.cancelarIW();
            });

            document.getElementById('cultivos')?.addEventListener('change', () => {
                //this.recargaVariedades(event.srcElement);
                const objCultivos: any = document.getElementById('cultivos');
                let objVariedades = document.getElementById('variedades');
                const divVariedades = document.getElementById('divVariedades');
                let cultivoSel = '';

                for (let i = 0; i < objCultivos.options.length; i++) {
                    if (objCultivos.options[i].selected) { cultivoSel = objCultivos.options[i].value; }
                }

                event.source.cultivoSelected = cultivoSel;

                objVariedades?.remove();

                if (divVariedades) {
                    divVariedades.innerHTML = '<select class="form-control" name="variedades" id="variedades" style="font-size: 1em;">';
                }

                objVariedades = document.getElementById('variedades');

                let opt = '';
                for (const variedad of event.source.arrayVariedades) {
                    if (variedad.cultivo === cultivoSel) {
                        opt +=
                        '<option value=\'' +
                          cultivoSel + ' | ' +
                          variedad.variedad + '\'>' +
                          variedad.variedad +
                        '</option>';
                    }
                }

                if (objVariedades) {
                    objVariedades.innerHTML = opt;
                }
            });
        });
    }


    cancelEditPolygon(id: number) {
    /* Al cerrar el InfoWindow si la parcela tiene editable = true lo cambiamos a false */
        const cerrar = document.getElementsByClassName('gm-ui-hover-effect')[0];

        if (cerrar) {
            cerrar.addEventListener('click', () => {
                this.parcelaObj[id].setEditable(false);
            });
        }
    }

    actualizarParcela() {
        console.log('Boton guardado presionado');
    
        if (Object.keys(this.parcelaDatos).length > 0){
            console.log('Valor despues de pulsar el boton guardado: ', this.parcelaDatos);

            let cultivo = '';
            let variedad = '';

            if (this.parcelaDatos.variedad.length > 0){
                [cultivo, variedad] = this.parcelaDatos.variedad.split('|').map((str: string) => str.trim());
            }
            
            console.log(cultivo, variedad);

            const cultivoEncontrado = this.cultivosArray.find(item => item.cultivo === cultivo);
            console.log(cultivoEncontrado);


            // const variedadEncontrada = (variedad !== 'SIN VARIEDAD') 
            //     ? (this.cultivosArray.find(item => item.cultivo === variedad) || variedad)
            //     : variedad;


            const variedadEncontrada = this.variedadesArray.find(
                item => item.variedad === variedad && item.cultivo === cultivoEncontrado?.cultivo);


            console.log(variedadEncontrada);
            
            
            const datosParcela = {
                operacion: 'editar',
                usuario: StorageManager.getUser().id,
                cliente: StorageManager.getClient().id,
                finca: this.fincaSelectedId,
                provincia: this.parcelaDatos.provincia,
                municipio: this.parcelaDatos.municipio,
                poligono: this.parcelaDatos.poligono,
                parcela: this.parcelaDatos.parcela,
                recinto: this.parcelaDatos.recinto,
                // cultivo: this.parcelaDatos.cultivo,
                cultivo: cultivoEncontrado?.id,
                // variedad: this.parcelaDatos.variedad,
                variedad: variedadEncontrada?.id,
                // cultivoNombre: this.parcelaDatos.cultivoNombre,
                cultivoNombre: cultivoEncontrado?.cultivo,
                // variedadNombre: this.parcelaDatos.variedadNombre,
                variedadNombre: variedadEncontrada?.variedad,

                usoSigpac: this.sigPAC.usoSigpac,
                coord: this.coordenadas_locatec,
                color: this.parcelaDatos.color || '#88dd66',
                refpar: this.parcelaDatos.refpar,
                ha: this.parcelaDatos.superficie,
                nombreParcela: this.parcelaDatos.nombreParcela
            };
            console.log('Objeto preparado para el endpoint:', datosParcela);

            const url: string =  environment.serverUrl + 'ws/mapas/guardaParcela.php';
            const headers = new HttpHeaders({'Content-Type': 'application/json'});


            this.http.post(url, JSON.stringify(datosParcela), { headers: headers, responseType: 'text' })
                .subscribe(
                    response => {
                        console.log('Respuesta del servidor:', response);
                        if (response.includes('OK')) {
                            alert('Parcela actualizada correctamente.');
                            this.recargaMapa();
                        } else {
                            console.log('Hubo un error actualizando la parcela.');
                        }
                    },
                    error => {
                        console.error('Error al conectarse al servidor:', error);
                    }
                );

            this.parcelaDatos = {};
        }      

        this.guardarCambios(this.arrayCoord, +this.parcelaSelectedid);
        this.cancelarEdicion();
        this.parcelaObj[this.parcelaSelectedid].setEditable(false);
    }


    resetParcelasMapa() {

        this.parcelaObj.forEach((el: { setMap: (arg0: null) => void; }) => {
            el.setMap(null);
        });

        if (this.showMapaLabels) {
            this.markerLabels.forEach((el: { setMap: (arg0: null) => void; }) => {
                el.setMap(null);
            });
        }

    }

    pintaParcelasFinca(finca: string) {

        this.parcelaObj.forEach((el: { idFinca: any; setMap: (arg0: google.maps.Map) => void; }) => {
            if (el.idFinca === finca) {el.setMap(this.map);}
        });

        this.markerLabels.forEach((el: { idFinca: any; setMap: (arg0: google.maps.Map) => void; }) => {
            if (el.idFinca === finca) {el.setMap(this.map);}
        });

    }


    pintaParcelas() {

        const idFincaSelected = this.arrayFincas.find((el: {nombre: string}) => el.nombre === this.finca)?.id.toString();

        let coords: any[] = [];
        let coord: any[] = [];
        let aux = '';
        let k = 0;
        let j = 0;
        let color = '';
        let bounds = new google.maps.LatLngBounds();
        let nomParcela = '';
        let varParcela = '';

        // COGEMOS LA INFORMACIÓN DEL PRIMER ELEMENTO PARA GENERERAR EL NÚM. REFERENCIA
        let provincia = this.arrayFullMapObj[0]?.cod_provincia;
        let municipio = this.arrayFullMapObj[0]?.municipio;
        let poligono = this.arrayFullMapObj[0]?.poligono;
        let parcela = this.arrayFullMapObj[0]?.parcela;
        let refparAnterior = this.getRC([provincia ?? '', municipio ?? '', poligono ?? '', parcela ?? '']);
        refparAnterior = '';

        const noIcon = {
            path: 'M 125,5 125,5',
            fillOpacity: 0.1,
            scale: 0.1,
        };


        this.arrayFullMapObj.forEach(el => {

            coord = [];

            coord = (el.coordenadas || '').split(';');
            for (j = 0; j < coord.length; j++) {
                aux = coord[j] ?? '';
                aux = aux.replace('(', '');
                aux = aux.replace(')', '');

                coords.push(new google.maps.LatLng((aux.split(',')[0] || '').trim(), (aux.split(',')[1] || '').trim()));
                bounds.extend(coords[j]);
            }

            // GENERAMOS EL NÚMERO DE REFERENCIA
            provincia = el.cod_provincia;
            municipio = el.municipio;
            poligono = el.poligono;
            parcela = el.parcela;
            const refpar = this.getRC([provincia, municipio, poligono, parcela]);

            // if (el.finca === '') { color = '222222'; }
            // else { color = '009900'; } //'00D500';
            // color = (el.color || '').replace('#', '');

            // Si la parcela es de la finca seleccionada, se pinta de color azul, si no, verde
            if (el.id_finca === idFincaSelected){
                color = '1111aa';
            }else{
                color = '88dd66';
            }

            console.log(color);
            // color = '88dd66';

            try {
                if (this.applicationType === 'cropgest') {
                    nomParcela = this.arrayFincas.find(it => it.id === el.id_finca).cliente_nombre + ' > \n' || '';
                    nomParcela += this.arrayParcelas.find(it => it.id === el.id_parcela)?.nombre || '';
                } else {
                    nomParcela = this.arrayParcelas.find(it => it.id === el.id_parcela)?.nombre || '';
                }
            }
            catch (e) { nomParcela = ''; /*console.log("el: ",el);*/ }
            try { varParcela = this.arrayParcelas.find(it => it.id === el.id_parcela)?.variedad || ''; }
            catch (e) { varParcela = ''; /*console.log("el: ",el);*/ }

            //nomParcela = ((this.arrayParcelas.find(it => it.id == el.id_parcela)).nombre) || '';

            // COMPROBAMOS SI YA SE HA PINTADO UNA PARCELA CON EL MISMO NÚMERO DE REFERENCIA
            if (refpar.trim() !== refparAnterior.trim() || true) {
                // Construct the polygon.
                this.parcelaObj[k] = new google.maps.Polygon({
                    paths: coords,
                    // strokeColor: '#' + color,
                    strokeColor: '#88dd66',
                    strokeOpacity: 0.8,
                    strokeWeight: 3,
                    fillColor: '#' + color,
                    fillOpacity: 0.5, // 0.35
                    id: k,
                    idParcela: el.id,
                    idFinca: el.id_finca
                });


                const parcel = this.parcelaObj[k];

                google.maps.event.addListener(parcel, 'click', (event: any) => {
                    //Extrae propiedades de polígono y parcela
                    this.setSelection(parcel);
                    this.abreInfoWindow(event, 3, parcel.id);
                });

                //Añade label nombre parcela. Solo para Vginer y demo
                if (this.showMapaLabels) {

                    const marker = new google.maps.Marker({
                        position: bounds.getCenter(),
                        label: (
                            this.appName === 'amoros' ||
                            this.appName === 'almassora' ||
                            this.appName === 'covidai'
                        ) ? varParcela : nomParcela,
                        icon: noIcon,
                        idFinca: el.id_finca,
                        map: this.map
                    });

                    this.markerLabels.push(marker);

                    google.maps.event.addListener(marker, 'click', (event: any) => {
                        //Extrae propiedades de polígono y parcela
                        this.setSelection(parcel);
                        this.abreInfoWindow(event, 3, parcel.id);
                    });

                    bounds = new google.maps.LatLngBounds();
                    marker.setMap(this.map);
                }

                parcel.setMap(this.map);
                refparAnterior = refpar;
            }
            coords = [];
            k++;
        });
        window.scrollTo(0, 0);
    }


    setSelection(parcel: any) {
        try {
            if (this.infowindow.getMap() == null) { this.infoWindOpened = false; }
            this.infowindow.close();
        }
        catch (err) { console.log(err); }

        const shape = parcel;
        this.clearSelection();
        this.selectedShape = shape;
        try {
            // shape.setEditable(true);
            //google.maps.event.addListener(shape.getPath(), 'set_at', calcar);
            //google.maps.event.addListener(shape.getPath(), 'insert_at', calcar);

            const arrayCoord = shape.getPath();
            const numCoord = arrayCoord.length;

            const bounds = new google.maps.LatLngBounds();
            for (let i = 0; i < numCoord; i++) {
                bounds.extend(arrayCoord.getAt(i));
            }
        }
        catch (e) { }

    }


    clearSelection() {
        if (this.selectedShape) {
            try { this.selectedShape.setEditable(false); }
            catch (e) { }
            this.selectedShape = null;
        }
    }


    montaGeoJSON() {

        let coord = '';
        let coords: string[];
        let c = '';
        let miJS: number[] = [];
        let misJS: number[][] = [];
        const misJS2: any[][] = [];
        let lat: number;
        let lng: number;

        this.arrayMapaParcelas.forEach(el => {
            coord = el.coordenadas;
            coords = coord.split(';');
            coords.forEach(co => {
                c = co.replace('("', '');
                c = c.replace('")', '');
                c = c.replace('(', '');
                c = c.replace(')', '');

                lat = parseFloat(c.split(',')[0]?.toString() ?? '');
                lng = parseFloat(c.split(',')[1]?.toString() ?? '');

                miJS.push(lat);
                miJS.push(lng);
                misJS.push(miJS);
                miJS = [];
            });
            misJS2.push(misJS);
            misJS = [];
            //miJSON.features[0].geometry.coordinates.push();
        });

        this.miJSON = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: misJS2
                    }
                }
            ]
        };

    }

    getIWObjects(): any {
        const objSuperficie = document.getElementById('superficie') as HTMLInputElement;
        const objProvincia = document.getElementById('provincia') as HTMLInputElement;
        const objMunicipio = document.getElementById('municipio') as HTMLInputElement;
        const objPoligono = document.getElementById('poligono') as HTMLInputElement;
        const objParcela = document.getElementById('parcela') as HTMLInputElement;
        const objRecinto = document.getElementById('recinto') as HTMLInputElement;

        const objs: any = {
            superficie: objSuperficie.value,
            provincia: objProvincia.value,
            municipio: objMunicipio.value,
            poligono: objPoligono.value,
            parcela: objParcela.value,
            recinto: objRecinto.value,
        };

        return objs;
    }


    guardarIW(event: { feature: any; }, ha: string, tipo?: number, refparcel?: any) {

        console.log('GuardarIW');
        if (this.fincaSelected === '' || this.fincaSelected == null) {
            alert('Debe seleccionar una finca primero...');
            return;
        }

        const url: string =  environment.serverUrl + 'ws/mapas/guardaParcela.php';
        
        const style = { fillColor: 'green', strokeWeight: 5, strokeColor: 'black', fillOpacity: 0.8 };
        let refpar = '';
        let provincia = '';
        let municipio = '';
        let poligono = '';
        let parcela = '';
        let recinto = '';
        let operacion = '';

        if (tipo === 2) {
            //this.arrayFincasClicked.push(refparcel);
            this.map.data.overrideStyle(event.feature, style);
            refpar = refparcel;
        }
        else {
            if (tipo === 3) {operacion = 'editar';}
            //this.arrayFincasClicked.push(event.feature.g.id); //l
            this.map.data.overrideStyle(event.feature, style);
            refpar = this.sigPAC.referencia;
        }

        //this.setSigPAC(refpar, tipo);

        const objs: any = this.getIWObjects();

        if (objs != null && objs !== undefined) {
            provincia = objs.provincia;
            municipio = objs.municipio;
            poligono = objs.poligono;
            parcela = objs.parcela;
            recinto = objs.recinto;
        }

        this.infoWindOpened = false;
        this.infowindow.close();

        const color = '#88dd66';

        this.arrayFincas.forEach(el => {
            if (el.nombre === this.fincaSelected) { this.fincaSelectedId = el.id; }
        });

        let encontrado = false;
        this.arrayVariedades.forEach(el => {
            if (el.cultivo === this.cultivoSelected && !encontrado) {
                this.cultivoSelectedId = el.codigo_cultivo ?? '';
                encontrado = true;
            }
            if (encontrado && el.codigo_cultivo === this.cultivoSelectedId && el.variedad === this.variedadSelected) {
                this.variedadSelectedId = el.id ?? '';
            }
        });

        let url2 = '';
        url2 += 'finca=' + this.fincaSelectedId + '&provincia=' + objs.provincia + '&municipio=' + objs.municipio;
        url2 += '&poligono=' + objs.poligono + '&parcela=' + objs.parcela + '&recinto=' + objs.recinto;
        url2 += '&cultivo=' + this.cultivoSelectedId + '&variedad=' + this.variedadSelectedId;
        url2 += '&cultivo_nombre=' + this.cultivoSelected + '&variedad_nombre=' + this.variedadSelected;
        url2 += '&usoSigpac' + this.sigPAC.usoSigpac + '&coords=' + this.coordenadas_locatec + '&color=' + color;
        url2 += '&refpar=' + refpar + '&ha=' + ha;

        const usuario = StorageManager.getUser().id;
        let cliente = (StorageManager.getClient() || {}).id;
        const finca = this.fincaSelectedId;

        if (cliente === 0 || cliente == null) {
            this.arrayFincas.forEach(el => {
                if (el.id === finca) { cliente = el.id_cliente; }
            });
        }

        const headers2 = new HttpHeaders({ 'Content-Type': 'application/json' });

        const cultivo = this.cultivoSelectedId;
        const variedad = this.variedadSelectedId;
        const cultivoNombre = this.cultivoSelected;
        const variedadNombre = this.variedadSelected;
        const coord = this.coordenadas_locatec;
        const origin = this.origin;
        const appName = environment.appName;
        const usoSigpac = this.sigPAC.usoSigpac;
        const nombreParcela = this.nombreParcela;

        const datos = JSON.stringify({
            finca,
            provincia,
            municipio,
            poligono,
            parcela,
            recinto,
            cultivo,
            variedad,
            usoSigpac,
            cultivoNombre,
            variedadNombre,
            coord,
            color,
            cliente,
            usuario,
            refpar,
            ha,
            operacion,
            origin,
            appName,
            nombreParcela
        });

        console.log(datos);

        if (this.origin !== 'parcelas-form' && this.origin !== 'parcelas') {
            this.http.post(url, datos, { headers: headers2, responseType: 'text' })
                .subscribe(
                    res => {
                        if (!res.includes('ERROR')) {
                            RequestHandler.globalCache['parcelas/get'] = {};
                            alert('Parcela Guardada Correctamente. Recargar mapa');

                            //location.reload();
                            this.recargaMapa();
                            this.cancelaParcela();

                            //Actualizamos la lista de ids de poligonos
                            this.idsPoligonosClic[this.idsPoligonosClic.length] = refpar;
                        }
                        else {
                            alert('Hubo un error guardando la parcela!');
                        }
                    },
                    error => {
                        console.log('Error occured: ', error);
                        alert('Error conectando con Base de Datos!');
                    }
                );
        }

        //req.unsubscribe();
        if (this.origin === 'parcelas-form') { this.fromMapa.emit(datos); }
    }


    eliminarParcela(_event: any, _refpar: any, id: number) {

        const idFinca = this.arrayFullMapObj[id]?.id_finca;
        const idParcela = this.arrayFullMapObj[id]?.id_parcela;

        const url = environment.serverUrl + 'ws/mapas/eliminaParcela.php?finca=' + idFinca + '&parcela=' + idParcela;

        this.http.get(url, { responseType: 'text' })
            .subscribe(
                res => {
                    //console.log(res);
                    if (!res.includes('ERROR')) {
                        RequestHandler.globalCache['parcelas/get'] = {};
                        alert('Parcela Eliminada Correctamente');
                        this.parcelaObj[id].setMap(null);
                        this.recargaMapa();
                    } else {
                        alert('Hubo un error eliminando la parcela!');
                    }
                },
                err => {
                    console.log('Error occured: ' + err);
                    alert('Error conectando con Base de Datos!');
                }
            );

        this.infoWindOpened = false;
        this.infowindow.close();
    }

    cancelarIW() {
        this.idsPoligonosClic.pop();
        this.infoWindOpened = false;
        this.infowindow.close();
    }

    recargaMapa() {
        this.showCargando = true;

        while (this.arrayParcelas.length > 0) {
            this.arrayParcelas.pop();
        }
        while (this.arrayMapaParcelas.length > 0) {
            this.arrayMapaParcelas.pop();
        }
        while (this.arrayFullMapObj.length > 0) {
            this.arrayFullMapObj.pop();
        }

        this.getParcelas();
        this.getMapaParcelas();
        setTimeout(() => { this.getFullMapObject(true); }, 3000);

    //this.initializeMap();
    }



    //Esta función introduce la imagen del Catastro en el mapa
    overlay() {
        if (!this.showSigpac) { return; }

        this.bounds = this.map.getBounds() || null;
        const ne = this.bounds.getNorthEast() || null;
        const sw = this.bounds.getSouthWest() || null;

        if (this.oldmap != null) {
            this.oldmap.setMap(null);  //'Despinta' la imagen anterior para que no se solapen mucho
            this.oldmap = null;
        }

        const layer = 'parcela,txtparcela';

        this.oldmap = new google.maps.GroundOverlay(
            'https://ovc.catastro.meh.es/Cartografia/WMS/ServidorWMS.aspx?SERVICE=WMS&SRS=EPSG:4326&REQUEST=GETMAP&bbox=' +
            sw.lng() + ',' + sw.lat() + ',' + ne.lng() + ',' + ne.lat() +
            '&width=' + this.gmapElement?.nativeElement.offsetWidth +
            '&height=' + this.gmapElement?.nativeElement.offsetHeight +
            '&format=PNG&transparent=Yes&layers=' + layer, this.map.getBounds()
        );
        this.oldmap.setMap(this.map);


        let urlGetPoligonosExtension = environment.serverUrl + 'ws/mapas/curl_get.php?';
        urlGetPoligonosExtension += 'url=https://ovc.catastro.meh.es/INSPIRE/wfsCP.aspx&service=wfs&' +
          'request=getfeature&Typenames=cp.cadastralparcel&SRSname=EPSG::4326&bbox=' +
          sw.lat() + ',' + sw.lng() + ',' + ne.lat() + ',' + ne.lng();

        if (this.map.getZoom() > 15) {this.getCatastroXML(urlGetPoligonosExtension);}
        else {alert('Debe acercarse más para poder activar SigPAC');}

    }



    getFincaSelected(finca: any) {
        finca = typeof(finca) === 'string' ? finca : finca.value;

        let numParcelas = 0;
        const bounds = new google.maps.LatLngBounds();
        let c, lat, lng = '';
        let localidad, cp = '';

        StorageManager.saveLastFincaMap(finca);

        try {
            this.fincaSelected = finca;

            // SACAMOS EL ID DE LA FINCA
            this.arrayFincas.forEach(f => {
                if (f.nombre === this.fincaSelected) {
                    this.fincaSelectedId = f.id;
                    localidad = f.localidad;
                    cp = f.codigo_postal;
                }
            });

            if (this.appName === 'cartagenafresh' || this.appName === 'hernandorena') {
                this.resetParcelasMapa();
                this.pintaParcelasFinca(this.fincaSelectedId);
            }

            // SACAMOS LA PRIMERA PARCELA Y EL PRIMER PAR DE COORDENADAS PARA CENTRAR EL MAPA
            if (this.origin === 'parcelas') {
                c = this.coordenadas.split(';')[0];
                lat = c?.split(',')[0]?.replace('(', '') ?? '';
                lng = c?.split(',')[1]?.replace(')', '') ?? '';
                bounds.extend(new google.maps.LatLng(lat, lng));
                numParcelas++;
            } else{
                this.arrayFullMapObj.forEach(parcela => {
                    if (environment.appName === 'manezylozano') {
                        if (parcela.id_parcela === this.fincaSelectedId) {
                            numParcelas++;
                            c = parcela.coordenadas.split(';')[0];
                            lat = c?.split(',')[0]?.replace('(', '') ?? '';
                            lng = c?.split(',')[1]?.replace(')', '') ?? '';
                            bounds.extend(new google.maps.LatLng(lat, lng));
                        }
                    }
                    else {
                        if (parcela.id_finca === this.fincaSelectedId) {
                            numParcelas++;
                            if (parcela.coordenadas !== '') {
                                c = parcela.coordenadas.split(';')[0];
                                lat = c?.split(',')[0]?.replace('(', '');
                                lng = c?.split(',')[1]?.replace(')', '') ?? '';
                                bounds.extend(new google.maps.LatLng(lat, lng));
                            }
                        }
                    }
                });
            }

            if (numParcelas > 0) {
                this.map.fitBounds(bounds);
                this.map.panToBounds(bounds);
                this.map.setZoom(17);
                this.lastLatLng = bounds.getCenter().lat() + ',' + bounds.getCenter().lng();
                StorageManager.saveLastLatLng(this.lastLatLng);
            }
            else {
                if (environment.appName !== 'innovia') {
                    if (environment.appName !== 'manezylozano') {
                        alert('No hay parcelas registradas todavía para esta finca');
                        if (cp !== '' && cp !== null) {this.codeAddressObs(cp).subscribe();}
                        else if (localidad !== '' && localidad != null) {this.codeAddressObs(localidad).subscribe();}
                    }
                    else {alert('Este campo aun no está creado en mapas!');}
                }
            }
        }
        catch (e) { console.log(e); }

        //INNOVIA -> MOSTRAMOS SOLO LA RUTA SELECCIONADA
        if (environment.appName === 'innovia') {
            this.routes.forEach((it: { id_cliente: string; setOptions: (arg0: { strokeOpacity: number; }) => void; }) => {
                if (it.id_cliente === this.fincaSelectedId) {it.setOptions({ strokeOpacity: 1 });}
                else {it.setOptions({ strokeOpacity: 0 });}
            });
            this.markers.forEach((it: { id_cliente: string; setVisible: (arg0: boolean) => void; }) => {
                if (it.id_cliente === this.fincaSelectedId) {it.setVisible(true);}
                else {it.setVisible(false);}
            });
        }

        //CARTAGENA FRESH -> OCULTAMOS RESTO DE FINCAS
        else if (environment.appName === 'cartagenafresh') {
            /*
      this.parcelaObj.forEach(it => {
        if (it.idFinca == this.fincaSelectedId) it.setOptions({ fillColor: '#1111aa', fillOpacity: 0.8 });
        else it.setOptions({ fillColor: '#009900', fillOpacity: 0 });
      });
      */
        }

        //PARA EL RESTO DESTACAR EN COLOR LA FINCA SELECCIONADA
        else {
            this.parcelaObj.forEach((it: { idFinca: string; setOptions: (arg0: { fillColor: string; }) => void; }) => {
                if (it.idFinca === this.fincaSelectedId) {it.setOptions({ fillColor: '#1111aa' });}
                else {it.setOptions({ fillColor: '#009900' });}
            });
        }
    }

    getSectorSelected(sector: any) {
        sector = typeof(sector) === 'string' ? sector : sector.value;
        let numParcelas = 0;
        const bounds = new google.maps.LatLngBounds();
        let c, lat, lng = '';

        StorageManager.saveLastSectorMap(sector);


        try {
            this.sectorSelected = sector;

            // SACAMOS EL ID DEL SECTOR
            this.arraySectores.forEach(s => {
                if (s.nombre === this.sectorSelected) {
                    this.sectorSelectedId = s.id ?? '';
                }
            });

            // SACAMOS LA PRIMERA PARCELA Y EL PRIMER PAR DE COORDENADAS PARA CENTRAR EL MAPA
            this.arrayFullMapObj.forEach(parcela => {

                if (environment.appName === 'manezylozano') {
                    if (parcela.id_sector === this.sectorSelectedId) {
                        numParcelas++;
                        c = parcela.coordenadas.split(';')[0];
                        lat = c?.split(',')[0]?.replace('(', '');
                        lng = c?.split(',')[1]?.replace(')', '') ?? '';
                        bounds.extend(new google.maps.LatLng(lat, lng));
                    }
                }
                else {
                    if (parcela.id_sector === this.sectorSelectedId) {
                        numParcelas++;
                        c = parcela.coordenadas.split(';')[0];
                        lat = c?.split(',')[0]?.replace('(', '');
                        lng = c?.split(',')[1]?.replace(')', '') ?? '';
                        bounds.extend(new google.maps.LatLng(lat, lng));
                    }
                }
            });

            if (numParcelas > 0) {
                this.map.fitBounds(bounds);
                this.map.panToBounds(bounds);
                this.map.setZoom(17);
                this.lastLatLng = bounds.getCenter().lat() + ',' + bounds.getCenter().lng();
                StorageManager.saveLastLatLng(this.lastLatLng);
            }
            else {
                if (environment.appName === 'cartagenafresh') {
                    alert('No hay parcelas registradas todavía para este sector');
                }
            }
        }
        catch (e) { console.log(e); }

        //DESTACAR EN COLOR EL SECTOR SELECCIONADO
        if (environment.appName !== 'innovia') {
            this.parcelaObj.forEach((it: { idSector: string; setOptions: (arg0: { fillColor: string; }) => void; }) => {
                if (it.idSector === this.sectorSelectedId) {it.setOptions({ fillColor: '#1111aa' });}
                else {it.setOptions({ fillColor: '#009900' });}
            });
        }
        //PARA INNOVIA MOSTRAMOS SOLO LA RUTA SELECCIONADA
        else {
            this.routes.forEach((it: { id_cliente: string; setOptions: (arg0: { strokeOpacity: number; }) => void; }) => {
                if (it.id_cliente === this.sectorSelectedId) {it.setOptions({ strokeOpacity: 1 });}
                else {it.setOptions({ strokeOpacity: 0 });}
            });
            this.markers.forEach((it: { id_cliente: string; setVisible: (arg0: boolean) => void; }) => {
                if (it.id_cliente === this.sectorSelectedId) {it.setVisible(true);}
                else {it.setVisible(false);}
            });

        }
    }

    codeAddressObs(address: string): Observable<google.maps.GeocoderResult[]> {
        return Observable.create((observer: Observer<google.maps.GeocoderResult[]>) => {
            // Invokes geocode method of Google Maps API geocoding.
            this.geocoder = new google.maps.Geocoder();
            this.geocoder.geocode({ address: address, region: 'es' }, (
                (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        this.map.setCenter(results[0].geometry.location, 10);
                        observer.next(results[0].geometry.location);
                        observer.complete();

                        this.lastLatLng = results[0].geometry.location.lat() + ',' + results[0].geometry.location.lng();
                        StorageManager.saveLastLatLng(this.lastLatLng);
                    } else {
                        console.log('geocode was not successful for the following reason: ' + status);
                        observer.error(status);
                    }
                })
            );
        });
    }



    //////////////////////////////////////////////////////////////////////////////
    /////////////////////  FUNCIONES DE CARGA DE DATOS  //////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    getFincas() {

        const clientSelected = (StorageManager.getClient() || {} as any).id;
        const idUsuario = (StorageManager.getUser() || {} as any).id;
        const rolUsuario = (StorageManager.getUser() || {} as any).rol;

        if (environment.appName === 'manezylozano') {
            return new Promise<void>((resolve) => {
                this.camposApi.campos.GET.safePerform();
                this.camposApi.campos.GET.response((value: any[]) => {
                    if (value) {

                        if (rolUsuario !== 'admin') {
                            this.arrayFincas = value.filter(it => it.id_usuario === idUsuario).sort(Filtering.sort('nombre', 1));
                        } else {
                            this.arrayFincas = value.filter(it => it).sort(Filtering.sort('nombre', 1));
                        }
                        if (this.origin === 'parcelas-form' || this.origin === 'parcelas') {
                            this.arrayFincas.push({ nombre: this.finca });
                        }
                        this.arrayFincas.forEach(it => {
                            if (it.id_usuario === idUsuario) {
                                this.strFinca += '<option value=\'' + it.nombre + '\'>' + it.nombre + '</option>';
                            }
                        });
                        this.strFinca = this.strFinca.replace('\'' + this.fincaSelected + '\'', '\'' + this.fincaSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
        else {
            return new Promise<void>((resolve) => {

                this.fincasApi.fincasGET.safePerform();
                this.fincasApi.fincasGET.response((value: FincasModel[]) => {

                    if (value) {
                        this.arrayFincas = value.filter(it => it).sort(Filtering.sort('nombre', 1));

                        this.arrayFincas.forEach(it => {
                            if (it.id_cliente === clientSelected) {
                                this.strFinca +=
                                  '<option value=\'' + it.nombre + '\'>' + it.nombre + '(' + it.cliente_nombre + ')' + '</option>';
                                this.strFinca =
                                  this.strFinca.replace('\'' + this.fincaSelected + '\'', '\'' + this.fincaSelected + '\' selected');
                                this.loadCount++;
                            }
                        });
                    }
                    resolve();
                });
            });
        }
    }

    getSectores() {
        return new Promise<void>((resolve) => {
            this.fincasApi.sectoresGET.safePerform();
            this.fincasApi.sectoresGET.response((value: any[]) => {
                this.arraySectores = value;
                this.loadCount++;
                resolve();
            });
        });
    }

    getParcelas() {
        const idUsuario = (StorageManager.getUser() || {} as any).id;
        const rolUsuario = (StorageManager.getUser() || {} as any).rol;

        if (environment.appName === 'manezylozano') {
            return new Promise<void>((resolve) => {
                this.camposApi.campos.GET.safePerform();
                this.camposApi.campos.GET.response((value: any[]) => {
                    if (value) {
                        if (rolUsuario !== 'admin') {
                            this.arrayParcelas = value.filter(it => it.id_usuario === idUsuario).sort(Filtering.sort('nombre', 1));
                        } else {
                            this.arrayParcelas = value.filter(it => it).sort(Filtering.sort('nombre', 1));
                        }
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
        else {
            return new Promise<void>((resolve) => {
                this.fincasApi.parcelasGET.safePerform();
                this.fincasApi.parcelasGET.response((value: any[]) => {
                    this.arrayParcelas = value;
                    this.loadCount++;
                    resolve();
                });
            });
        }
    }


    getCultivos() {
        if (!environment.features.activateSIEX || this.hasPlantas) {
            return new Promise<void>((resolve) => {
                const arrayAux: string[] = [''];

                this.strCultivos = '<option value=\'\'>Selecciona...</option>';
                this.formApi.cultivosGET.safePerform();
                this.formApi.cultivosGET.response((value: any[]) => {
                    if (value) {
                        this.arrayCultivos = value.filter(it => it).sort(Filtering.sort('cultivo', 1));
                        this.arrayCultivos.forEach(it => {
                            if (!arrayAux.includes(it.cultivo)) {
                                arrayAux.push(it.cultivo);
                                this.strCultivos += '<option value=\'' + it.cultivo + '\'>' + it.cultivo + '</option>';
                            }
                        });

                        this.strCultivos =
                          this.strCultivos.replace('\'' + this.cultivoSelected + '\'', '\'' + this.cultivoSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        } else{
            return new Promise<void>((resolve) => {
                const arrayAux: string[] = [''];

                this.strCultivos = '<option value=\'\'>Selecciona...</option>';
                this.siexApi.cultivosGET.safePerform();
                this.siexApi.cultivosGET.response((value: any[]) => {
                    if (value) {
                        value.forEach((item) => {
                            this.cultivosArray.push({id: item.id, cultivo: item.cultivo});
                        });

                        this.arrayCultivos = value.filter(it => it).sort(Filtering.sort('cultivo', 1));
                        this.arrayCultivos.forEach(it => {
                            if (!arrayAux.includes(it.cultivo)) {
                                arrayAux.push(it.cultivo);
                                this.strCultivos += '<option value=\'' + it.cultivo + '\'>' + it.cultivo + '</option>';
                            }
                        });

                        this.strCultivos =
                          this.strCultivos.replace('\'' + this.cultivoSelected + '\'', '\'' + this.cultivoSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
    }

    getVariedades() {

        if (!environment.features.activateSIEX || this.hasPlantas) {
            return new Promise<void>((resolve) => {
                this.formApi.variedadesGET.safePerform();
                this.formApi.variedadesGET.response((value: any[]) => {
                    if (value) {

                        this.arrayVariedades = value.filter(it => it).sort(Filtering.sort('variedad', 1));
                        this.arrayVariedades.forEach(it => {
                            this.strVariedades += '<option value=\'' + it.cultivo + ' | ' + it.variedad + '\'>' + it.variedad + '</option>';
                        });
                        this.strVariedades =
                          this.strVariedades.replace('\'' + this.variedadSelected + '\'', '\'' + this.variedadSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }else{
            return new Promise<void>((resolve) => {
                this.siexApi.variedadesGET.safePerform();
                this.siexApi.variedadesGET.response((value: VisitasVariedadMesModel[]) => {
                    value.forEach((item) => {
                        this.variedadesArray.push({id: +(item.id || 0), cultivo: item.cultivo,   variedad: item.variedad});
                    });

                    if (value) {
                        this.arrayVariedades = value.filter(it => it).sort(Filtering.sort('variedad', 1));
                        this.arrayVariedades.forEach(it => {
                            this.strVariedades += '<option value=\'' + it.cultivo + ' | ' + it.variedad + '\'>' + it.variedad + '</option>';
                        });
                        this.strVariedades =
                          this.strVariedades.replace('\'' + this.variedadSelected + '\'', '\'' + this.variedadSelected + '\' selected');
                        this.loadCount++;
                    }
                    resolve();
                });
            });
        }
    }


    getMapaParcelas() {
        const clientSelected = (StorageManager.getClient() || {} as any).id;
        const idUsuario = StorageManager.getUser().id;
        const rolUsuario = StorageManager.getUser().rol;
        return new Promise<void>(resolve => {
            this.mapaParcelasApi.mapaParcelasGET.safePerform();
            this.mapaParcelasApi.mapaParcelasGET.response((value: FullMapModel[]) => {
                if (value) {
                    if (this.appName === 'manezylozano' && rolUsuario !== 'admin') {
                        this.arrayMapaParcelas = value.filter(it => it.id_usuario === idUsuario);
                    }
                    else {this.arrayMapaParcelas = value;}
                    this.loadCount++;
                }
                else {
                    this.showCargando = false;
                }

                if (this.applicationType !== 'cropgest' && clientSelected !== undefined) {
                    this.arrayMapaParcelas = value.filter(it => it.id_cliente === clientSelected);
                }
                resolve();
            });
        });

    }


    getFullMapObject(eliminado: boolean) {

        let idCultivo = '';
        let idVariedad = '';
        let idx = 0;
        let obj: any = {};

        const fincaIds = this.arrayFincas.map(finca => finca.id);

        if (this.arrayMapaParcelas.length > 0) {
            this.arrayMapaParcelas
                .filter(el => fincaIds.includes(el.id_finca))
                .forEach(el => {
                    obj = {};

                    obj.id = el.id;
                    obj.id_parcela = el.id_parcela;
                    obj.id_cultivo = el.id_cultivo;
                    obj.id_finca = el.id_finca;
                    obj.coordenadas = el.coordenadas;
                    obj.color = el.color;

                    //EXTRA DATA
                    try { obj.finca = this.arrayFincas.find(el2 => el2.id === el.id_finca).nombre || ''; }
                    catch (err) { obj.finca = ''; }

                    idx = this.arrayParcelas.findIndex(el2 => el2.id === el.id_parcela);

                    if (idx === -1) {
                        obj.id = '';
                        obj.parcela_nombre = '';
                        obj.cod_provincia = '';
                        obj.municipio = '';
                        obj.poligono = '';
                        obj.parcela = '';
                        obj.recinto = '';
                        obj.superficie = '';
                        obj.sigpac = '';
                        obj.cultivo = '';
                        obj.variedad = '';
                    }
                    else {
                        obj.parcela_id = this.arrayParcelas[idx]?.id;
                        obj.parcela_nombre = this.arrayParcelas[idx]?.nombre;
                        obj.cod_provincia = this.arrayParcelas[idx]?.cod_provincia || '';
                        obj.municipio = this.arrayParcelas[idx]?.municipio || '';
                        obj.poligono = this.arrayParcelas[idx]?.poligono || '';
                        obj.parcela = this.arrayParcelas[idx]?.numero || '';
                        obj.recinto = this.arrayParcelas[idx]?.recinto || '';
                        if (environment.appName === 'pyf' || environment.appName === 'amoros') {
                            obj.superficie = this.arrayParcelas[idx]?.superficie_cultivada || '';
                        }
                        else {obj.superficie = this.arrayParcelas[idx]?.superficie_sigpac || '';}
                        obj.sigpac = this.arrayParcelas[idx]?.sigpac || '';

                        idCultivo = this.arrayParcelas[idx]?.id_cultivo || this.arrayParcelas[idx]?.id_cultivo_legacy || '';
                        idVariedad = this.arrayParcelas[idx]?.id_variedad || this.arrayParcelas[idx]?.id_variedad_legacy || '';


                        if (environment.features.activateSIEX) {
                            if (this.arrayVariedades.find(el2 => el2.id_cultivo === idCultivo) !== undefined &&
                this.arrayVariedades.find(el2 => el2.id === idVariedad) !== undefined) {
                                obj.cultivo = this.arrayVariedades.find(el2 => el2.id_cultivo === idCultivo)?.cultivo || '';
                                obj.variedad = this.arrayVariedades.find(el2 => el2.id === idVariedad)?.variedad || '';
                            }
                        } else {
                            if (this.arrayVariedades.find(el2 => el2.id === idVariedad) !== undefined) {
                                obj.cultivo = this.arrayVariedades.find(el2 => el2.id === idVariedad)?.cultivo || '';
                                obj.variedad = this.arrayVariedades.find(el2 => el2.id === idVariedad)?.variedad || '';
                            }
                        }
                    }
                    this.arrayFullMapObj.push(obj);
                });

            if (!eliminado) {this.pintaParcelas();}
        }
        this.showCargando = false;
    }


    getPosition() {
        if (navigator.geolocation) {
            try {
                this.getGPSPosition().then(pos => {
                    console.log(`Positon: ${pos.lng} ${pos.lat}`);
                    this.map.setCenter(pos, 8);
                    return;
                }).catch (e => {
                    console.log('catch en getPosition: ' + e);
                }
                );
            }
            catch (e) { console.log('catch en getPosition: ' + e); }
        }
        else {
            console.log('Get GPS Position not supported!');
        }
    }

    getGPSPosition(): Promise<any> {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(resp => {
                resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
            },
            err => {
                if (err.code === 0) {
                    // Unknown error
                }
                if (err.code === 1) {
                    // Access denied by user
                }
                if (err.code === 2) {
                    // Position unavailable
                }
                if (err.code === 3) {
                    // Timed out
                }
                //reject(err);
            });
        });
    }




    //////////////////////////////////////////////////////////////////////////////
    ///////////////////////  FUNCIONES DE DATOS SIGPAC  //////////////////////////
    //////////////////////////////////////////////////////////////////////////////
    //Obtiene poligonos del servicio WFS del Catastro
    getCatastroXML(xmlURL: string) {

        this.showCargando = true;

        this.http.get(xmlURL, { responseType: 'text' }).subscribe((data) => {
            const jsonText = this.xmlToJson(data);
            const geoJSONAnyadirMapa = this.convertirJson2Geojson(jsonText);
            let geoJ: any = {};
            try {
                geoJ = JSON.parse(geoJSONAnyadirMapa);

                //Añade las parcelas al mapa
                if (geoJSONAnyadirMapa.length > 0) {
                    this.map.data.addGeoJson(geoJ);
                }
                this.showCargando = false;

            }
            catch (err) { console.log(err); this.showCargando = false; }
        });
    }


    xmlToJson(xml: string | xml2js.convertableToString): any {
        const parser: xml2js.Parser = new xml2js.Parser();
        let json: object = {};
        parser.parseString(xml, (err, result) => {

            if (err) {
                throw err;
            }

            json = result;
        });
        return json;
    }


    // Changes XML to JSON
    // Modified version from here: http://davidwalsh.name/convert-xml-json
    xmlToJson2(
        xml: { 
            nodeType: number; 
            attributes: { 
                length: number; 
                item: (arg0: number) => any; 
            }; 
            nodeValue: {}; 
            hasChildNodes: () => any; 
            childNodes: { nodeValue: {}; }[]; 
        }) {

        // Create the return object
        let obj: any = {};

        if (xml.nodeType === 1) { // element
            // do attributes
            if (xml.attributes.length > 0) {
                obj['@attributes'] = {};
                for (let j = 0; j < xml.attributes.length; j++) {
                    const attribute = xml.attributes.item(j);
                    obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
                }
            }
        }
        else if (xml.nodeType === 3) { // text
            obj = xml.nodeValue;
        }

        // do children
        // If just one text node inside
        // @ts-ignore
        if (xml.hasChildNodes() && xml.childNodes.length === 1 && xml.childNodes[0]?.nodeType === 3) {
            obj = xml.childNodes[0].nodeValue;
        }
        else if (xml.hasChildNodes()) {
            for (let i = 0; i < xml.childNodes.length; i++) {
                // @ts-ignore
                const item = xml.childNodes.item(i);
                const nodeName = item.nodeName;
                if (typeof (obj[nodeName]) == 'undefined') {
                    obj[nodeName] = this.xmlToJson(item);
                }
                else {
                    if (typeof (obj[nodeName].push) == 'undefined') {
                        const old = obj[nodeName];
                        obj[nodeName] = [];
                        obj[nodeName].push(old);
                    }
                    obj[nodeName].push(this.xmlToJson(item));
                }
            }
        }
        return obj;
    }

    // Convierte json convertido desde XML del WFS del Catastro en geojson
    // Solo mantiene los atributos de geometria y localId
    convertirJson2Geojson(jsonText: Record<string, Record<string, any>>): string {

        let surfaceMember: any;
        let coordenadas: any;

        //cabecera
        let geoJSONSalida = '{' +
          '"type": "FeatureCollection",' +
          '"crs": { '+
              '"type": "name",' +
              '"properties": { ' +
                  '"name": "urn:ogc:def:crs:OGC:1.3:CRS84" ' +
              '}' +
          '},' +
          '"features": [';

        let members: any;
        if (jsonText['FeatureCollection']) {
            members = jsonText['FeatureCollection']['member'];
        }
        // Iteramos elemento por elemento
        for (const member of  members) {
            //Controlamos si el elemento ya ha sido añadido al mapa, para no añadirlo 2 veces
            const idPoligono = member['cp:CadastralParcel'][0]['cp:inspireId'][0]['Identifier'][0]['localId'];
            if (!this.idsPoligonos.includes(idPoligono)) {
                geoJSONSalida +=
                  '{ "type": "Feature", "properties": { "id": "' + idPoligono + '" }, "geometry": { "type": "Polygon", "coordinates": [ [ ';
                surfaceMember = member['cp:CadastralParcel'][0]['cp:geometry'][0]['gml:MultiSurface'][0]['gml:surfaceMember'];
                // Puede haber más de un sufacemember -> REVISAR. De momento solo consideramos el 1er elemento
                if (surfaceMember.length > 1) {
                    coordenadas = member['cp:CadastralParcel'][0]
                        ['cp:geometry'][0]
                        ['gml:MultiSurface'][0]
                        ['gml:surfaceMember'][0]
                        ['gml:Surface'][0]
                        ['gml:patches'][0]
                        ['gml:PolygonPatch'][0]
                        ['gml:exterior'][0]
                        ['gml:LinearRing'][0]
                        ['gml:posList'][0]._;
                } else {
                    coordenadas = member['cp:CadastralParcel'][0]
                        ['cp:geometry'][0]
                        ['gml:MultiSurface'][0]
                        ['gml:surfaceMember'][0]
                        ['gml:Surface'][0]
                        ['gml:patches'][0]
                        ['gml:PolygonPatch'][0]
                        ['gml:exterior'][0]
                        ['gml:LinearRing'][0]
                        ['gml:posList'][0]._;
                }
                for (let j = 0; j < (coordenadas.split(' ').length); j = j + 2) {
                    geoJSONSalida += '[ ' + coordenadas.split(' ')[j + 1] + ', ' + coordenadas.split(' ')[j] + ' ],';
                }
                geoJSONSalida = geoJSONSalida.slice(0, -1);
                geoJSONSalida += ' ] ] } },';
                //Actualizamos la lista de ids de poligonos
                this.idsPoligonos[this.idsPoligonos.length] = idPoligono;
            }
        }
        geoJSONSalida = geoJSONSalida.slice(0, -1);

        //final del archivo
        geoJSONSalida += ']}';
        return geoJSONSalida;
    }

    // GENEREMOS EL REGISTRO CATASTRAL
    getRC(data: string[]): string {

        let provincia = '';
        if (data[0]) {
            provincia = (data[0].length < 2) ? '0' + data[0] : data[0];
        }

        let municipio = data[1];

        if (municipio) {
            while (municipio.length < 3) { municipio = '0' + municipio; }
        }

        let poligono = data[2];

        if (poligono) {
            while (poligono.length < 3) { poligono = '0' + poligono; }
        }

        let parcela = data[3];

        if (parcela) {
            while (parcela.length < 5) { parcela = '0' + parcela; }
        }

        const rc = provincia + municipio + 'A' + poligono + parcela;
        return rc;
    }



    toggleSigpac() {

        if (this.showSigpac) {
            this.overlay();
            this.showSigpac = true;
            if (this.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 4) {
                this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
            }

            this.creatingParcel = false;
            this.drawingManager.setDrawingMode(null);
        }
        else {
            this.showCargando = false;
            this.drawingManager.setDrawingMode(null);
            if (this.map.controls[google.maps.ControlPosition.LEFT_TOP].length < 4) {
                this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divNuevaParcela?.nativeElement);
            }

            if (this.oldmap != null) {
                this.oldmap.setMap(null);  //'Despinta' la capa sigpac anterior
                this.oldmap = null;
            }

            const geoJ = this.map.data;
            geoJ.forEach((el: any) => {
                geoJ.remove(el);
            });

            this.idsPoligonos = [];
            this.idsPoligonos = [];
            this.showSigpac = false;
        }
    }

    cancelaParcela() {
        if (this.drawingManager) {
            this.drawingManager.setDrawingMode(null);  // Detener el modo de dibujo
            if (this.currentOverlay) {
                this.currentOverlay.setMap(null);  // Eliminar la parcela dibujada del mapa
                this.currentOverlay = null;  // Limpiar la referencia al overlay
            }
        }
        this.creatingParcel = false;
    
        // Asegúrate de que el botón 'Nueva Parcela' esté siempre visible
        const el = this.divNuevaParcela?.nativeElement;
        const controls = this.map.controls[google.maps.ControlPosition.LEFT_TOP];
    
        // Verifica si el botón ya está en los controles para no agregarlo dos veces
        const isButtonInControls = controls.getArray().includes(el);
        if (!isButtonInControls) {
            controls.push(el);  // Agrega el botón si no está ya en los controles
        }
    
        // Restaura los controles de búsqueda si han sido removidos
        const inputElement = this.divSearchMap?.nativeElement;
        if (inputElement && !controls.getArray().includes(inputElement)) {
            controls.push(inputElement);
        }
    }
    
    nuevaParcela() {
        if (this.fincaSelected === '' || this.fincaSelected == null) {
            alert('Debe seleccionar una finca primero...');
            return;
        }

        if (this.drawingManager) {
            this.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
            this.creatingParcel = true;
        }
        // this.creatingParcel = true;
        const el = this.divCancelaParcela?.nativeElement;
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(el);

        document.getElementById('divCancelaParcela')?.addEventListener('click', () => {
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divNuevaParcela?.nativeElement);
        });

    }



    public guardarCambios(arrayCoord: any, id: number): void {

        let coordenadasLct = '';
        let latInicial = '';
        let lngInicial = '';
        const pathParcel: any[] = [];
        let i = 0;

        arrayCoord.forEach(function (path: { lat: () => string; lng: () => string; }) {
            if (i === 0) {
                latInicial = path.lat();
                lngInicial = path.lng();
                coordenadasLct = '(' + latInicial + ',' + lngInicial + ');';
            }
            i++;
            pathParcel.push(new google.maps.LatLng(path.lat(), path.lng()));
            coordenadasLct += '(' + path.lat() + ',' + path.lng() + ');';
        });

        coordenadasLct = coordenadasLct.slice(0, -1);
        this.cancelEditPolygon(id);
    }


    public cancelarEdicion(): void {

        // OCULTAMOS EL BOTÓN DE -GUARDAR- Y -CANCELAR- Y VOLVEMOS A MOSTRAR EL DE -NUEVA PARCELA-
        if (this.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 5) {
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
        }

        if (this.map.controls[google.maps.ControlPosition.LEFT_TOP].length === 4) {
            this.map.controls[google.maps.ControlPosition.LEFT_TOP].pop();
        }

        this.map.controls[google.maps.ControlPosition.LEFT_TOP].push(this.divNuevaParcela?.nativeElement);
    }






    ///////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////  PRUEBAS RECORRIDO GPS MYL  /////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    private getTracksMyL() {
        const url = 'https://appmyl.com/ws/index.php?p1=tratamientos&p2=get_coord_dismuntel';  //DISMUNTEL

        this.http.get(url).subscribe(data => {
            this.drawTracks(data);
        });
    }

    private drawTracks(data: any) {

        let tracks: any = [];

        data.data.forEach((track: { lat: string; lon: string; }) => {
            tracks.push(new google.maps.LatLng(track.lat, track.lon));
        });

        //console.log("Traks: "+tracks);

        tracks = new google.maps.Polyline({
            path: tracks,
            strokeColor: '#ff3333',
            geodesic: true,
            strokeOpacity: 1,
            strokeWeight: 5,
        });

        tracks.setMap(this.map);
    }


    ///////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////  PRUEBAS RECORRIDO GPS MYL  /////////////////////////////////
    ///////////////////////////////////////////////////////////////////////////////////////////

    private getTareasInnovia() {
    //let url = 'https://innovia.fitosgest.com/ws/index.php?p1=tareas&p2=fitosanitarios_get';
        const url = 'https://innovia.fitosgest.com/ws/index.php?p1=track&p2=tratamientos_track_get';
        this.http.get<BaseResponseModel<TratamientoTrackModel>>(url).subscribe(data => {
            this.drawTareasInnovia2(data);
        });


    }


    private drawTareasInnovia2(data: BaseResponseModel<TratamientoTrackModel>){

        let marker: string;
        let tracks: string[] = [];
        let route: string | null;
        let idTarea: number;
        let idTareaAnt = -1;
        let i = 1;
        let k = 0;
        let cliente: string, fecha, hora = '';
        const dataLength: number = +data.data.length;
        let numRoutes = 0;

        //console.log("dataLength: " + dataLength);
        const myIcon = {
            url: './assets/icon/point.png',
            //size: new google.maps.Size(50, 50),
            //origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(13, 13),
            scaledSize: new google.maps.Size(25, 25)
        };

        (data.data as TratamientoTrackModel[]).forEach((track: TratamientoTrackModel) => {

            idTarea = track.id_tarea;

            // Primera lectura de track
            if (idTareaAnt === -1) {idTareaAnt = idTarea;}

            // Si hay cambio de tratamiento
            if (idTarea !== idTareaAnt || i === dataLength) {

                route = new google.maps.Polyline({
                    path: tracks,
                    strokeColor: '#ff3333',
                    geodesic: true,
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    id: k,
                    id_cliente: track.id_cliente,
                    text: '',
                    title: '',
                    fecha: track.fecha,
                    id_tarea: idTareaAnt
                });

                this.routes.push(route);

                this.parcelaObj[k] = route;

                this.routes[numRoutes].addListener('click',  (e: { latLng: string; }) => {

                    if (this.infowindow != null) {this.infowindow.close();}

                    this.arrayFincas.forEach(el => {
                        if (el.id === track.id_cliente) { cliente = el.nombre; }
                    });

                    fecha = track.fecha.substring(0,10);
                    fecha = fecha.split('-')[0]+'/'+fecha.split('-')[1] + '/' + fecha.split('-')[2];
                    hora = track.fecha.substring(11);

                    this.infowindow = new google.maps.InfoWindow({
                        content: '<div><h4>Cliente: ' + cliente + '<br />Fecha: ' + fecha  + '<br />Hora: ' + hora + '</h4></div>'
                    });

                    this.infowindow.setPosition(e.latLng);
                    this.infowindow.open(this.map);
                });

                this.routes[numRoutes].setMap(this.map);

                k++;
                tracks = [];
                route = null;
                numRoutes++;
            }

            marker = new google.maps.Marker({
                position: new google.maps.LatLng(track.lat,track.lng),
                id: k,
                id_cliente: track.id_cliente,
                fecha: track.fecha,
                id_tarea: idTareaAnt,
                icon: myIcon
            });

            this.markers.push(marker);
            this.markers[i-1].setMap(this.map);

            this.markers[i-1].addListener('click',  (e: { latLng: string; }) => {
                if (this.infowindow != null) {this.infowindow.close();}

                this.arrayFincas.forEach(el => {
                    if (el.id === track.id_cliente) { cliente = el.nombre; }
                });

                fecha = track.fecha.substring(0,10);
                fecha = fecha.split('-')[0]+'/'+fecha.split('-')[1] + '/' + fecha.split('-')[2];
                hora = track.fecha.substring(11);

                this.infowindow = new google.maps.InfoWindow({
                    content: '<div><h4>Cliente: ' + cliente + '<br />Fecha: ' + fecha  + '<br />Hora: ' + hora + '</h4></div>'
                });

                this.infowindow.setPosition(e.latLng);
                this.infowindow.open(this.map);
            });

            tracks.push(new google.maps.LatLng(track.lat,track.lng));
            idTareaAnt = idTarea;
            i++;

        });

    }


}


