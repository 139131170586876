import { AlbaranApiService } from "../../../../../service/api/albaran-api.service";
import { FormCommonApiService } from "../../../../../service/api/formcommon-api.service";
import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DashboardService } from "../../../../../service/events/dashboard.service";
import { BaseForm } from "../../../../base-form";
import { FincasApiService } from "../../../../../service/api/fincas-api.service";
import { TrabajadoresApiService } from "../../../../../service/api/trabajadores-api.service";
import { TratamientosApiService } from "../../../../../service/api/tratamientos-api.service";
import { AlbaranesFormDefinition } from "./form-control/form-definition";
import { AppFormRequest } from "../../../../app-common/form-request/app-form-request";
import { FormRequestTypes } from "../../../../../common/classes/form-request";
import { environment } from "../../../../../../environments/environment";
import { StorageManager } from "../../../../../common/storage-manager.class";
import moment from "moment";
import { RequestButtonComponent } from "../../../../../common/components/request-button/request-button.component";
import { SiexApiService } from "../../../../../service/api/siex-api.service";
import { UsuariosApiService } from "../../../../../service/api/usuarios-api.service";

import type { OnInit } from "@angular/core";
import { ItemInterface } from "../../../../../common/components/form-builder/form-builder.component";
import { build, pool } from "src/app/common/classes/request-builder";

import { AlbaranModel } from "src/app/models/ventas/albaran.model";
import { ClienteAlbaranApiService, ChoferApiService, MaquinariaApiService, ObraApiService } from "src/app/service/api/tablas-maestras-api.service";

@Component({
  selector: "app-formulario-pulverizacion",
  templateUrl: "./albaranes-form.component.html",
  styleUrls: ["./albaranes-form.component.scss"],
})
export class AlbaranesFormComponent extends BaseForm<AlbaranModel> implements OnInit {
  @ViewChild(RequestButtonComponent, {
    read: RequestButtonComponent,
    static: true,
  })
  requestButton: RequestButtonComponent<AlbaranModel> = new RequestButtonComponent<AlbaranModel>();
  public form: AlbaranesFormDefinition = new AlbaranesFormDefinition();
  public formRequest: AppFormRequest<AlbaranModel> = new AppFormRequest<AlbaranModel>();
  public override model: any = {
    fecha_inicio: moment().toDate(),
  };

  public distributionOrderFormTratamientos = [2, 3, 2];

  public rol = StorageManager.getUser().rol;

  public appName = environment.appName;
  public showTareasFitoObservaciones =
    environment.features.showTareasFitoObservaciones;

  public horaFechaFinActualPorDefecto =
    environment.features.horaFechaFinActualPorDefecto;
  public showClimatologia = environment.features.showClimatologia;
  public tipoSuperficieParcelas = environment.features.hasSuperficieCultivada
    ? "Sup.\xa0Cultivada"
    : "S.\xa0Sigpac";
  public serverUrl =
    window.location.hostname === "localhost" ? "" : environment.serverUrl;

  public auxServerUrl = environment.serverUrl;
  public hasClients = environment.features.hasClients;
  public userCanSeeClientes = environment.features.userCanSeeClientes
    ? true
    : this.rol === "admin";
  public filterAplicadoresByClient =
    environment.features.filterAplicadoresByClient;
  public clienteName = environment.features.clienteName;
  public showTareasFitoHoras = environment.features.showTareasFitoHoras;
  public timeStartStopTareas = environment.features.timeStartStopTareas;
  public showFitoValidado = environment.features.showFitoValidado
    ? this.rol === "admin"
    : false;
  public parcelasName = environment.features.parcelasName;
  public showTareasFitoMochila = environment.features.showTareasFitoMochila;
  public showTareasFitoAplicacion =
    environment.features.showTareasFitoAplicacion;
  public textoSuperficie = environment.features.textoSuperficie;
  public showClientesNotas = environment.features.showClientesNotas;

  public getRequest =
    this.getType() === FormRequestTypes.DUPLICATE
      ? this.albaranesApi.albaranesGET
      : this.albaranesApi.albaranesGET;

  public updatingTratamientos = false;
  public loadingData = true;

  /*** innovia litros aplicados */
  public showLitros = false;

  /*** TRACTOR CONFIG ***/
  public idxMaquinaria: any;

  /*** Firma DonJardin ***/
  public signatureFile: File = new File([], "");

  constructor(
    public override route: ActivatedRoute,
    public override dashboard: DashboardService,
    public formApi: FormCommonApiService,
    public usuariosApi: UsuariosApiService,
    public fincasApi: FincasApiService,
    public trabajadoresApi: TrabajadoresApiService,
    public tratamientosApi: TratamientosApiService,
    public clientesApi: ClienteAlbaranApiService,
    public siexApi: SiexApiService,
    public override router: Router,
    public dashboardEvent: DashboardService,
    public albaranesApi: AlbaranApiService,
    public chofersApi: ChoferApiService,
    public maquinariaApi: MaquinariaApiService,
    public obrasApi: ObraApiService
  ) {
    super(
      route,
      router,
      dashboard,
      albaranesApi.albaranesPUT,
      albaranesApi.albaranesPOST,
      "albaranes",
      "Actualizar Albarán",
      "Generar Albarán",
      "Duplicar Albarán"
    );
  }

  ngOnInit() {
    this.dashboard.clientChanges$.subscribe(() => {
      this.fincasApi.fincasGET.unsuscribe();
      this.fincasApi.sectoresGET.unsuscribe();
      this.fincasApi.parcelasGET.unsuscribe();
      this.tratamientosApi.tratamientosList.unsuscribe();
      if (this.filterAplicadoresByClient) {
        this.trabajadoresApi.trabajadoresGET.unsuscribe();
        this.trabajadoresApi.maquinariaGET.unsuscribe();
      }
    });

    if (environment.features.horaFechaFinActualPorDefecto) {
      if (this.getType() === 0 || this.getType() === 2) {
        const date = new Date();
        this.model.hora_fin = date;
      }
    }
    const fieldsToSend = (["id"] as any).concat(
      this.form.formFieldsLugar
        .filter((it) => it && it.visible !== false)
        .map((it) => it.field),
      this.form.formFieldsTratamiento
        .filter((it) => it && it.visible !== false)
        .map((it) => it.field)
    );

    this.expandFormFields();

    this.expandFormRequest();

    this.formRequest
      .setType(this.getType())
      .isGeneric(this.getType() === FormRequestTypes.DUPLICATE)
      .setRegisterId(this.getRegisterId())
      .setModel(this.model)
      .setGetRequest(this.getRequest)
      .setPostRequest(this.albaranesApi.albaranesPOST)
      .setPutRequest(this.albaranesApi.albaranesPUT)
      .setFormFields(
        Array.prototype.concat(
          this.form.formFieldsLugar.map((it) => it.field),
          this.form.formFieldsTratamiento.map((it) => it.field)
        )
      )
      .setFieldsToSend(fieldsToSend);

    this.formRequest.load();

    this.softInit(this.getType());
    this.form.requestType = this.getType();
    this.form.model = this.model;
    if (this.showClientesNotas && this.getType() === FormRequestTypes.CREATE) {
      this.model["notas_cliente"] = StorageManager.getClient()["notas"];
    }
    if (localStorage.getItem("tratamiento_temp")) {
      this.model = JSON.parse(localStorage.getItem("tratamiento_temp") ?? "");
      localStorage.removeItem("tratamiento_temp");
    }

    this.initFormData();

    setTimeout(() => {
      this.convertDate(this.model.fecha);
      this.loadingData = false;
    }, 2000);
  }

  convertDate(date: string) {
    if (date != undefined) {
      this.model.fecha = moment(date, "DD/MM/YYYY").toDate();
    }
  }

  updateImageModel(event: string) {
    this.model["imagen"] = event;
  }

  updateAfterImageModel(event: string) {
    this.model["image_after"] = event;
  }

  getSignature(event: File) {
    this.signatureFile = event;
  }

  updateFirma(event: any) {
    this.model["signature_img"] = event;
    this.formRequest.update();
  }

  public override getItemPosition(
    formFields: ItemInterface<any>[],
    field: string
  ) {
    let position = -1;
    formFields.forEach((item, index) => {
      if (item.field === field) {
        position = index;
      }
    });
    return position;
  }

  public formChanges(_tag: string) {
    for (let i = 1; i <= 7; i++) {
      if (
        (_tag === `cantidad${i}` && this.model[`precio${i}`]) ||
        (_tag === `precio${i}` && this.model[`cantidad${i}`])
      ) {
        var precio = String(this.model[`precio${i}`]);
        var cantidad = String(this.model[`cantidad${i}`]);

        if (precio.includes(",")) {
          precio = precio.replace(/\./g, "");
        }

        if (cantidad.includes(",")) {
          cantidad = cantidad.replace(/\./g, "");
        }

        console.log(precio, cantidad)

        this.model[`total${i}`] = (parseFloat(precio) * parseFloat(cantidad))
          .toFixed(2)
          .replace(".", ",");
      }
    }
  }

  public async submit() {
    for (let i = 1; i <= 7; i++) {
      ["total", "cantidad", "precio"].forEach((key) => {
        console.log(this.model[`${key}${i}`]);

        if (
          this.model[`${key}${i}`] !== undefined &&
          this.model[`${key}${i}`] !== null
        ) {
            var dataToConvert = String(this.model[`${key}${i}`]);

        if (dataToConvert.includes(",")) {
            dataToConvert = dataToConvert.replace(/\./g, "");
        }
          this.model[`${key}${i}`] = String(dataToConvert).replace(
            ",",
            "."
          );
        }
      });
    }

    if (!this.formRequest.checkIfValid()) {
      this.requestButton.error = "Hay campos obligatorios";
    }

    this.model.fecha = moment(this.model.fecha).format("DD/MM/YYYY");

    this.formRequest.send();

    if (this.getType() === FormRequestTypes.DUPLICATE) {
      this.router.navigate(["dashboard", this.pathToGoBack]);
    }
  }

  private expandFormFields() {}

  private expandFormRequest() {
    this.formRequest.afterLoad((resolve) => {
      resolve(true);
    });

    this.formRequest.beforeSend((resolve) => {
      resolve(true);
    });

    this.formRequest.afterSend((resolve) => {
      StorageManager.saveSessionDataDonJardin(
        this.model.id_finca,
        this.model.hora_inicio,
        this.model.hora_fin
      );
      resolve(true);
    });

    this.formRequest.afterFinish((resolve) => {
      resolve(true);
    });
  }

  private initFormData(): Promise<any> {
    this.loadingData = true;

    const buildsRequest = [
        build(this.chofersApi.choferGET, this.form.chofers, { or: ['razon_social', 'nombre'] }),
        build(this.clientesApi.clienteGET, this.form.clientes, { or: ['razon_social', 'nombre'] }),
        build(this.maquinariaApi.maquinariaGET, this.form.maquinarias, { or: ['razon_social', 'nombre'] }),
        build(this.obrasApi.obraGET, this.form.obras, { or: ['razon_social', 'nombre'] }),
    ];

    return pool( buildsRequest, () => {
       
        setTimeout(() => {
            this.formRequest.update();
            if ( this.form.chofers.filtered.length === 2  && this.getType() === 0 ){
                this.model['id_finca'] = this.form.chofers.filtered[1].value.id;
            }
        }, 100);
    });
}
}

export interface Datum {
  year: string;
  numero: string;
}

export interface RespReferencia {
  statusCode: number;
  statusMessage: string;
  data: Datum[];
}
