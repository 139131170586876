import { Component, Input } from '@angular/core';
import { InputType } from './input-type.enum';
import { FormGroup } from '@angular/forms';
import type { FormInterface, ItemInterface } from './item.interface';

@Component({
    selector: 'app-field',
    templateUrl: './field.component.html',
    styleUrls: ['./field.component.scss'],
})
export class FieldComponent {
    @Input() field!: ItemInterface<any>;
    @Input() model!: FormGroup;
    @Input() form!: FormInterface<any>;
    @Input() originalModel: Record<string, any> = {};
    @Input() filterInputParcelas!: boolean;
    @Input() undoEnabled = false;

    INPUT = InputType;

    /*get isValid() {
        return this.model.controls[this.field.field ?? '']?.valid;
    }*/
    
    public isFieldEdited(field: ItemInterface<any>): boolean {
        const originalValue = this.originalModel[field.field ?? ''];
        const currentValue = this.model.get(field.field ?? '')?.value;
        return (field.undoEnabled || this.undoEnabled) && currentValue !== originalValue;
    }
          
    public getEditedClass(field: ItemInterface<any>): string {
        return this.isFieldEdited(field) ? 'edited' : '';
    }

    public isEditable(): boolean {
        return this.form.type <= 2 && this.field.isEditable !== false;
    }

    public sum(field: ItemInterface<any>): string {
        if (this.form.type < 3) {
            return `(${field.sum?.name}: ${
                (field.values.selected || []).reduce((sum: number, value: any) => 
                    sum + parseFloat(value?.[field.sum?.field ?? '0'] || '0.00'), 0)
            } ${field.sum?.uds})`;
        }
        return '';
    }
    
    public undoField(field: ItemInterface<any>): void {
        const originalValue = this.originalModel[field.field ?? ''];
        this.model.patchValue({ [field.field ?? '']: originalValue });
        this.applySelected(field, originalValue);
    }

    public getAudio(field: string | number, event: string ) {
        this.model.patchValue({ [field]: event });
    }

    public updateModel(field: ItemInterface<any>) {
        if (field.onClick) {
            const data: any = field.onClick(field);

            
            data.then((data: any) => {
                Object.entries(data).forEach((element: any) => {
                    this.model.patchValue({[element[0]] : element[1]});
                });
                return;
            }).catch((error: string) => {
                console.log(error);
            });
        }
    }

    public updateImageModel(event: string, fieldName: string  ){
        this.model.patchValue({[fieldName]: event});
        console.log(event, this.model.get(fieldName)?.value);
        console.log(this.model);
    }

    private applySelected(field: ItemInterface<any>, value: any): void {
        if (field.values) {
            field.values.selected = value;
        }
    }
}
  