import { Injectable } from "@angular/core";
import { SyncService } from "../OffService/sync.service";
import {
  ApiRequestService,
  HttpRequestType,
} from "../OffService/api-request.service";
import { RequestHandler } from "../OffService/request-handler";
import {
  ChoferModel,
  ClienteAlbaranModel,
  MaquinariaAlbaranModel,
  ObraModel,
} from "src/app/models/ventas/tablas-maestras.model";

@Injectable({
  providedIn: "root",
})
export class ChoferApiService {
  public choferGET = new RequestHandler<ChoferModel[]>();
  public choferPUT = new RequestHandler<ChoferModel[]>();
  public choferPOST = new RequestHandler<ChoferModel[]>();
  public choferDELETE = new RequestHandler<ChoferModel[]>();

  constructor(
    private syncServiceChofer: SyncService<ChoferModel[]>,
    private apiChofer: ApiRequestService<ChoferModel[]>
  ) {
    this.defineChoferGET();
    this.defineChoferPOST();
    this.defineChoferPUT();
    this.defineChoferDELETE();
  }

  private defineChoferGET() {
    this.choferGET.fetch(HttpRequestType.GET, "albaran/chofer");

    this.apiChofer.registerRequest(this.choferGET);

    this.syncServiceChofer.register(
      this.syncServiceChofer.baseSyncPolicy(this.choferGET)
    );
  }

  private defineChoferPOST() {
    this.choferPOST.fetch(HttpRequestType.POST, "albaran/chofer");

    this.apiChofer.registerRequest(this.choferPOST);

    this.syncServiceChofer.register(
      this.syncServiceChofer.baseSyncPolicy(this.choferPOST)
    );
  }

  private defineChoferPUT() {
    this.choferPUT.fetch(HttpRequestType.PUT, "albaran/chofer");

    this.apiChofer.registerRequest(this.choferPUT);

    this.syncServiceChofer.register(
      this.syncServiceChofer.baseSyncPolicy(this.choferPUT)
    );
  }

  private defineChoferDELETE() {
    this.choferDELETE.fetch(HttpRequestType.DELETE, "albaran/chofer");

    this.apiChofer.registerRequest(this.choferDELETE);

    this.syncServiceChofer.register(
      this.syncServiceChofer.baseSyncPolicy(this.choferDELETE)
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class ClienteAlbaranApiService {
  public clienteGET = new RequestHandler<ClienteAlbaranModel[]>();
  public clientePUT = new RequestHandler<ClienteAlbaranModel[]>();
  public clientePOST = new RequestHandler<ClienteAlbaranModel[]>();
  public clienteDELETE = new RequestHandler<ClienteAlbaranModel[]>();

  constructor(
    private syncServiceCliente: SyncService<ClienteAlbaranModel[]>,
    private apiCliente: ApiRequestService<ClienteAlbaranModel[]>
  ) {
    this.defineClienteGET();
    this.defineClientePOST();
    this.defineClientePUT();
    this.defineClienteDELETE();
  }

  private defineClienteGET() {
    this.clienteGET.fetch(HttpRequestType.GET, "albaran/cliente");

    this.apiCliente.registerRequest(this.clienteGET);

    this.syncServiceCliente.register(
      this.syncServiceCliente.baseSyncPolicy(this.clienteGET)
    );
  }

  private defineClientePOST() {
    this.clientePOST.fetch(HttpRequestType.POST, "albaran/cliente");

    this.apiCliente.registerRequest(this.clientePOST);

    this.syncServiceCliente.register(
      this.syncServiceCliente.baseSyncPolicy(this.clientePOST)
    );
  }

  private defineClientePUT() {
    this.clientePUT.fetch(HttpRequestType.PUT, "albaran/cliente");

    this.apiCliente.registerRequest(this.clientePUT);

    this.syncServiceCliente.register(
      this.syncServiceCliente.baseSyncPolicy(this.clientePUT)
    );
  }

  private defineClienteDELETE() {
    this.clienteDELETE.fetch(HttpRequestType.DELETE, "albaran/cliente");

    this.apiCliente.registerRequest(this.clienteDELETE);

    this.syncServiceCliente.register(
      this.syncServiceCliente.baseSyncPolicy(this.clienteDELETE)
    );
  }
}

@Injectable({
  providedIn: "root",
})
export class MaquinariaApiService {
  public maquinariaGET = new RequestHandler<MaquinariaAlbaranModel[]>();
  public maquinariaPUT = new RequestHandler<MaquinariaAlbaranModel[]>();
  public maquinariaPOST = new RequestHandler<MaquinariaAlbaranModel[]>();
  public maquinariaDELETE = new RequestHandler<MaquinariaAlbaranModel[]>();

  constructor(
    private syncServiceMaquinaria: SyncService<MaquinariaAlbaranModel[]>,
    private apiMaquinaria: ApiRequestService<MaquinariaAlbaranModel[]>
  ) {
    this.defineMaquinariaGET();
    this.defineMaquinariaPOST();
    this.defineMaquinariaPUT();
    this.defineMaquinariaDELETE();
  }

  private defineMaquinariaGET() {
    this.maquinariaGET.fetch(HttpRequestType.GET, "albaran/maquinaria");

    this.apiMaquinaria.registerRequest(this.maquinariaGET);

    this.syncServiceMaquinaria.register(
      this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaGET)
    );
  }

  private defineMaquinariaPOST() {
    this.maquinariaPOST.fetch(HttpRequestType.POST, "albaran/maquinaria");

    this.apiMaquinaria.registerRequest(this.maquinariaPOST);

    this.syncServiceMaquinaria.register(
      this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaPOST)
    );
  }

  private defineMaquinariaPUT() {
    this.maquinariaPUT.fetch(HttpRequestType.PUT, "albaran/maquinaria");

    this.apiMaquinaria.registerRequest(this.maquinariaPUT);

    this.syncServiceMaquinaria.register(
      this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaPUT)
    );
  }

  private defineMaquinariaDELETE() {
    this.maquinariaDELETE.fetch(HttpRequestType.DELETE, "albaran/maquinaria");

    this.apiMaquinaria.registerRequest(this.maquinariaDELETE);

    this.syncServiceMaquinaria.register(
      this.syncServiceMaquinaria.baseSyncPolicy(this.maquinariaDELETE)
    );
  }
}
4;
@Injectable({
  providedIn: "root",
})
export class ObraApiService {
  public obraGET = new RequestHandler<ObraModel[]>();
  public obraPUT = new RequestHandler<ObraModel[]>();
  public obraPOST = new RequestHandler<ObraModel[]>();
  public obraDELETE = new RequestHandler<ObraModel[]>();

  constructor(
    private syncServiceObra: SyncService<ObraModel[]>,
    private apiObra: ApiRequestService<ObraModel[]>
  ) {
    this.defineObraGET();
    this.defineObraPOST();
    this.defineObraPUT();
    this.defineObraDELETE();
  }

  private defineObraGET() {
    this.obraGET.fetch(HttpRequestType.GET, "albaran/obra");

    this.apiObra.registerRequest(this.obraGET);

    this.syncServiceObra.register(
      this.syncServiceObra.baseSyncPolicy(this.obraGET)
    );
  }

  private defineObraPOST() {
    this.obraPOST.fetch(HttpRequestType.POST, "albaran/obra");

    this.apiObra.registerRequest(this.obraPOST);

    this.syncServiceObra.register(
      this.syncServiceObra.baseSyncPolicy(this.obraPOST)
    );
  }

  private defineObraPUT() {
    this.obraPUT.fetch(HttpRequestType.PUT, "albaran/obra");

    this.apiObra.registerRequest(this.obraPUT);

    this.syncServiceObra.register(
      this.syncServiceObra.baseSyncPolicy(this.obraPUT)
    );
  }

  private defineObraDELETE() {
    this.obraDELETE.fetch(HttpRequestType.DELETE, "albaran/obra");

    this.apiObra.registerRequest(this.obraDELETE);

    this.syncServiceObra.register(
      this.syncServiceObra.baseSyncPolicy(this.obraDELETE)
    );
  }
}
