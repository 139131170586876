import { list } from 'src/app/common/classes/request-builder';
import { TypeAheadInterface } from 'src/app/common/components/reactive-form-builder/filter.interface';
import { ClienteModel } from 'src/app/models/cliente.model';
import { FincasModel } from 'src/app/models/form-common/fincas.module';
import { ParcelasModel } from 'src/app/models/form-common/parcelas.model';
import { SectorModel } from 'src/app/models/form-common/sector.model';
import { ProductosModel } from 'src/app/models/productos/productos.model';
import { MantenimientoModel } from 'src/app/models/trabajadores/mantenimiento.model';
import { MaquinariaModel } from 'src/app/models/trabajadores/maquinaria.model';
import { TratamientosModel } from 'src/app/models/tratamientos/tratamientos.model';
import { UserModel } from 'src/app/models/usuarios/user.model';
import { VisitasVariedadMesModel } from 'src/app/models/visitas_variedad_mes/visitas_variedad_mes.model';

export class FitosanitariosListsDefinition {
    public fincas: TypeAheadInterface<FincasModel> = list();
    public sectores: TypeAheadInterface<SectorModel | SectorModel[]> = list();
    public parcelas: TypeAheadInterface<ParcelasModel | ParcelasModel[]> = list();
    public cultivos: TypeAheadInterface<VisitasVariedadMesModel> = list();
    public trabajadores: TypeAheadInterface<MantenimientoModel> = list();
    public usuarios: TypeAheadInterface<UserModel> = list();
    public maquinarias: TypeAheadInterface<MaquinariaModel> = list();
    public tratamientos: TypeAheadInterface<TratamientosModel> = list();
    public productos: TypeAheadInterface<ProductosModel> = list();

    public justificaciones: TypeAheadInterface<string> = list();
    public rpmValues: TypeAheadInterface<string> = list(['...', null], '1000', '1100', '1200', '1300', '1400',
        '1500', '1600', '1700', '1800', '1900', '2000');
    public presionLaplana: TypeAheadInterface<string> = list(
        ['...', null], '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23',
        '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41',
        '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60');
    public presionAll: TypeAheadInterface<string> = list(['...', null], '3', '4', '8', '9', '10', '11', '12',
        '13', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44',
        '45', '46', '47', '48', '49', '50');
    public presionHerbicida: TypeAheadInterface<string> = list(['...', null], '3', '4');
    public presionTurbo: TypeAheadInterface<string> = list(['...', null], '8', '9', '10', '11', '12', '13');
    public presionDisparador: TypeAheadInterface<string> = list(['...', null], '30', '31', '32', '33', '34',
        '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50');

    public ph_caldo: TypeAheadInterface<string> = list(['...', null], '6,5', '7', '7,5', '8', '8,5');
    public eficacia: TypeAheadInterface<string> = list(['...', null], ['Buena', '1'], ['Regular', '2'], ['Mala', '3']);
    public clientes: TypeAheadInterface<ClienteModel> = list();
    public mano_obra: TypeAheadInterface<string> = list(['...', null], '0', '0.02', '0.03', '0.04', '0.05', '0.06');
    public desplazamiento: TypeAheadInterface<string> = list(
        ['...', null], ['10 €', '10'], ['15 €', '15'], ['20 €', '20'], ['25 €', '25']
    );
    public tipo_plaga: TypeAheadInterface<string> = list(['...', null],
        'Ácaros',
        'Aguado',
        'Alternaria',
        'Amarre fruto',
        'Antracnosis',
        'Caracoles',
        'Carencias',
        'Ceratitis',
        'Conservación',
        'Cotonet',
        'Cuajado',
        'Diaspinos',
        'Engorde',
        'Estímulo',
        'Gusano blanco',
        'Gusano de alambre',
        'Gusano gris',
        'Heladas',
        'Hormigas',
        'Hongos',
        'Malas hierbas',
        'Mejorante',
        'Melaza',
        'Minador',
        'Mosca blanca',
        'Nutrición',
        'Phytophtora',
        'Pulgones',
        'Sciara',
        'Trips',
        'Enfermedades tronco');
    public motivo: TypeAheadInterface<string> = list(['...', null],
        '>60% pet. Caidos',
        'Acidificante',
        'Aumento brotación',
        'Bajo calibre',
        'Capturas en trampas',
        'Condiciones climáticas',
        'Defoliación intensa',
        'Dispersante melaza',
        'Fruta picada',
        'Mayor % frutos cuajados',
        'Mejora aplicación',
        'Mejora calidad fruto',
        'Muestreo plaga',
        'Presencia',
        'Presencia en fruto',
        'Presencia en fruto en campaña anterior',
        'Prevención',
        'Ramas secas',
        'Síntomas visuales',
        '2ªG Diaspinos',
        'Aviso oficial de tratamiento de diaspinos',
        'Heridas en la base del tronco');

    public momentosAplicacion: TypeAheadInterface<string> = list(['...', null],
        'Brotación',
        'Cambio Color',
        'Cuajado',
        'Engorde Frutos',
        'Floración',
        'Parada Invernal'
    );

    public tecnica: TypeAheadInterface<string> = list(
        ['...', null],
        ['Inyección', '1'],
        ['Inmersión', '2'],
        ['Espolvoreo', '3'],
        ['Granulado', '4'],
        ['Pulverización', '5'],
        ['Atomización', '6'],
        ['Cebo', '7'],
        ['Agua de riego', '8'],
        ['Otros', '0']
    );

    public tiposTratamiento: TypeAheadInterface<string> = list(['...', null], 'Foliar', 'Radicular');
    public estadoTarea: TypeAheadInterface<string> = list(['...', null], 'Orden de Trabajo', 'Parte de Trabajo');

    public plan_abonado_list = list([]);
    public tipo_fertilizacion = list([]);
}