import { environment } from './../../../../environments/environment';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BehaviorSubject, firstValueFrom, Subject } from 'rxjs';
import { RequestHandler } from 'src/app/service/OffService/request-handler';
import { FormRequest } from './form-request';
import { InputType } from './input-type.enum';
import type { FormInterface, ItemInterface } from './item.interface';
import { FileUploadAnswer } from '../../utils';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-reactive-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReactiveFormBuilderComponent extends FormRequest implements OnInit {

    @Input() form!: FormInterface<any>;
    @Input() serverUrl = '';
    @Input() request!: RequestHandler<any>;
    @Input() undoEnabled = false;
    @Input() previousData: any | null = null;
    @Input() dropdownLoadTrigger!: Subject<void>;    
    @Output() formChanges: EventEmitter<any> = new EventEmitter<any>();
    @Output() goBack: EventEmitter<any> = new EventEmitter<any>();

    public appName = environment.appName;
    public filterInputParcelas = environment.features.filterInputParcelas;
    public INPUT = InputType;
    public error = '';

    public model!: FormGroup;
    public originalModel: Record<string, any> = {};
    public readonly modelSubject = new BehaviorSubject<FormGroup | null>(null);

    private previousModel: Record<string, any> = {};
    private isProgrammaticChange = false;

    constructor(private http: HttpClient) {
        super();
        this.afterSend(resolve => {
            this.finish();
            resolve(true);
        });
    }

    ngOnInit(): void {
        this.initializeForm();
    }
    
    public isModelInitialized(): boolean {
        return !!this.model && typeof this.model.get === 'function';
    }

    public returnToMainView(): void {
        this.goBack.emit();
    }

    public async getSignature( event: File ) {
        this.model.patchValue({ ['signature_img']: event });

        const signatureName = this.model.get('signature_img')?.value;

        if ( signatureName != null ) {
            const formData = new FormData();
            formData.append('fileToUpload', signatureName);
            formData.append('database', 'tareas_fitosanitarias');
            formData.append('folder', 'fitosanitarios');
            formData.append('field', 'signature_img');

            const signature = 
                await firstValueFrom(
                    this.http.post<FileUploadAnswer>( this.serverUrl + 'ws/tareas/fileUploadGeneric.php', formData));
            
            this.model.patchValue({ ['signature_img']: signature.target_file.replace('../../images/fitosanitarios/', '') });
        }// if();
    }
    
    public updateFirma(event: string) {
        this.model.patchValue({ ['signature_img']: event });
    }

    public submit(): void {
        if (this.model.valid) {
            const payload = this.updateObjectWithIds(this.model.getRawValue());
            if (this.form.type === 2) {
                delete payload['id'];
            }
            this.sendRequest(payload);
        } else {
            this.displayErrorsOnButton();
        }
    }

    private displayErrorsOnButton() {
        const invalidFields = Object.keys(this.model.controls)
            .filter(field => this.model.controls[field]?.invalid)
            .map(field => {
                const fieldInfo = this.getFieldByControlName(field);
                const errors = this.model.controls[field]?.errors;
                if (errors?.['required']) {
                    return `${fieldInfo?.name} es obligatorio.`;
                } else if (errors?.['maxlength']) {
                    return `${fieldInfo?.name} ha excedido la longitud máxima.`;
                } else if (errors?.['invalidTime']) {
                    return `${fieldInfo?.name} ${errors?.['invalidTime']}`;
                } else {
                    return `${fieldInfo?.name}: Error desconocido.`;
                }
            });
    
        this.error = `El formulario no es válido. Revisa los campos:<br>- ${invalidFields.join('<br>- ')}`;
        this.model.markAllAsTouched();
    }

    private initializeForm(): void {
        if (this.form.type > 2) {
            this.initializeModel();
            return;
        }

        if (this.dropdownLoadTrigger) {
            const subscription = this.dropdownLoadTrigger.subscribe(() => {
                this.initializeModel();
                this.subscribeToFormChanges();
                this.originalModel = { ...this.model.value };
                this.executeFieldsLogic(null);

                subscription.unsubscribe();
            });
        }
    }

    private initializeModel(): void {
        this.model = this.toFormGroup(this.form);
        this.modelSubject.next(this.model);
    }
    
    private subscribeToFormChanges(): void {
        this.model.valueChanges.subscribe(newValues => {
            const changedFields = Object.keys(newValues).filter(
                control => newValues[control] !== this.previousModel?.[control]
            );
    
            if (changedFields.length > 0) {
                changedFields.forEach(changedField => {
                    this.formChanges.emit([newValues, changedField]);
                    this.applySelected(
                        this.getFieldByControlName(changedField) ?? {},
                        newValues[changedField]
                    );
                    this.executeFieldsLogic(changedField);
                });
            }
    
            // Actualitzar previousModel amb els nous valors
            this.previousModel = { ...newValues };
        });
    }

    private sendRequest(payload: any): void {
        this.send();
        this.onSend(resolve => {
            this.request.safePerform(payload);
            this.request.response(() => resolve(true));
        });
        this.onFinish(resolve => {
            setTimeout(() => this.request.unsuscribe(), 1000);
            this.returnToMainView();
            resolve(true);
        });
    }

    private updateObjectWithIds(data: any): Record<string, any> {
        return Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
                if (Array.isArray(value)) {
                    return [key, value.map((item: any) => item?.id).filter(Boolean).join(';')];
                } else if ((value as any)?.id) {
                    return [key, (value as any)?.id];
                }
                return [key, value];
            })
        );
    }

    private toFormGroup(form: FormInterface<any>): FormGroup {
        const controls = form.sections
            .filter(section => section.visible)
            .flatMap(section => section.fields)
            .filter(field => field.visible && !field.retain)
            .reduce((group, field) => {
                const defaultValue = this.setDefaultValues(field);
                group[field.field ?? ''] = new FormControl(
                    defaultValue,
                    this.setValidators(field)
                );
                this.applySelected(this.getFieldByControlName(field.field ?? '') ?? {}, defaultValue);
                return group;
            }, {} as Record<string, FormControl>);
        return new FormGroup(controls);
    }

    private setDefaultValues(field: ItemInterface<any>): any {
        if (this.form.type === 2 && field.rememberOnDuplicate === false) {
            return;   
        }

        if (this.previousData?.[field.field ?? ''] && this.previousData?.[field.field ?? ''] !== 'null') {
            const previousValue = this.previousData[field.field ?? ''];
            if (field.valuePrimaryKey && this.form.type < 3) {
                return this.getFilteredValues(previousValue, field);
            }
            return previousValue;
        }
        return field.defaultValue ?? (
            field.values?.filtered?.length === 1 ?
                field.values?.filtered?.[1]?.value : (field.multiSelect ? [] : ''));
    }

    private getFilteredValues(previousValue: string, field: ItemInterface<any>): any {
        const ids = previousValue.split(';');
        const filteredValues = field.values.filtered
            .filter((value: { value: { id: string } }) => ids.includes(value.value?.id))
            .map((value: { value: any }) => value.value);
    
        return field.multiSelect ? filteredValues : filteredValues[0] ?? '';
    }    

    private setValidators(field: ItemInterface<any>): ValidatorFn[] {
        const validators: ValidatorFn[] = [];
    
        if (field.required) { validators.push(Validators.required); }
        if (field.maxLength) { validators.push(Validators.maxLength(field.maxLength)); }
        if (field.field?.includes('hora')) { validators.push(this.timeValidator()); }
    
        return validators;
    }
    

    private timeValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value;
    
            if (!value) {
                return null;
            }
    
            const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
            if (!timePattern.test(value) || value.startsWith('-')) {
                return { invalidTime: 'no es un tiempo válido (HH:mm) o es negativo.' };
            }
    
            return null;
        };
    }
    

    private applySelected(field: ItemInterface<any>, value: any): void {
        if (field.values) {
            field.values.selected = value;
        }
    }

    private executeFieldsLogic(changedField: string | null) {
        this.form.sections.forEach((section) => {
            section.fields
                .filter((field) => 
                    (field.filter && field.values && 
                        (changedField === null || field.vinculatedFields === undefined ||
                            field.vinculatedFields.includes(changedField))) || 
                    (field.transform && field.values) || 
                    (field.updateValue && !this.isProgrammaticChange) ||
                    (field.swap) || 
                    (field.turnVisible)
                )
                .forEach((field) => {
                    if (field.transform) { this.applyTransforms(field); }
                    if (field.filter) { this.applyFilter(field, changedField);}
                    if (field.swap) { field.swap(field); }
                    if (field.turnVisible) { field.turnVisible(field); }

                    if (field.updateValue && this.isModelInitialized() && !this.isProgrammaticChange) {
                        const value = field.updateValue(field);

                        if(value) {
                            this.isProgrammaticChange = true;
                            this.model.patchValue({ [field.field ?? '']: field.multiSelect ? [value] : value });
                            this.applySelected(field, '');
                            this.isProgrammaticChange = false;
                        }
                    }
                });
        });
    }

    private applyTransforms(field: ItemInterface<any>): void {
        field.transform?.forEach(transform => transform(field, this.model));
    }

    private applyFilter(field: ItemInterface<any>, changedField: string | null): void {
        field.values.filtered = [
            { label: field.placeholder || '...', value: null },
            ...(field.values.values || []).filter(field.filter),
        ];

        if (changedField && field.vinculatedFields?.includes(changedField) && field.values.selected?.includes) {
            const selected: ItemInterface<any>[] = [];

            field.values.filtered.forEach((value: any) => {
                if (field.values.selected?.includes(value.value)) {
                    selected.push(value.value);
                }
            });
            
            this.applySelected(field, selected);
            if (!this.isProgrammaticChange) {
                this.isProgrammaticChange = true;
                this.model.patchValue({ [field.field ?? '']: selected }, {emitEvent: false});
                this.model.get(field.field ?? '')?.markAsUntouched();
                this.model.get(field.field ?? '')?.markAsPristine();
                this.isProgrammaticChange = false;   
            }
        }
    }

    private getFieldByControlName(controlName: string): ItemInterface<any> | undefined {
        return this.form.sections
            .flatMap(section => section.fields)
            .find(field => field.field === controlName);
    }
}


export interface DropdownInterface<T> {
    label: string;
    value: T | null;
}