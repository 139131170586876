<!-- FORM FIELDS -->
<p-panel>
    <div style="display: flex; gap:10px; ">
        <h4>Datos de la tarea</h4>
        <div *ngIf="loadingData">
            <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="6" animationDuration="1.5s">
            </p-progressSpinner>
        </div>
    </div>
    
    <div class="row">            
        <app-reactive-form-builder
                (formChanges)="formChanges($event[0], $event[1])"
                (goBack)="goBack()"
                [form]="form.formFields"
                [serverUrl]="env.auxServerUrl"
                [dropdownLoadTrigger]="dropdownLoadTrigger"
                [previousData]="getModel()"
                [request]="this.getType() === 1 ? this.tareasApi.fitosanitariosPUT : this.tareasApi.fitosanitariosPOST">
        </app-reactive-form-builder>
    </div>
</p-panel>