<div id="photo-camera">
  <div *ngIf="nombre_imagen === null && tipo !=='list'" class="photo-camera-distribution">
    <i class="fa fa-picture-o icon-picture" aria-hidden="true" (click)="verImagen()" *ngIf="modelImagen.imagen"></i>
    <app-reactive-camera *ngIf="formType < 3" (sendCameraFile)="fileChange($event)"></app-reactive-camera>
  </div>
  <div *ngIf="tipo === 'list' && nombre_imagen === null" >
    <div *ngIf="modelImagen.imagen" >
      <!-- <h4>Antes</h4> -->
      <i class="fa fa-picture-o icon-picture-list" aria-hidden="true" (click)="verImagen()"></i>

    </div>
  </div>
  
  <div *ngIf="tipo === 'list' && nombre_imagen !== null">
    <div *ngIf="modelImagen.image_after" >
      <!-- <h4>Después</h4> -->
      <i class="fa fa-picture-o icon-picture-list" aria-hidden="true" (click)="verImagen()"></i>
    </div>
  </div>
  
  <div *ngIf="nombre_imagen !== null && tipo !== 'list'" class="photo-camera-distribution">
    <i class="fa fa-picture-o icon-picture" aria-hidden="true" (click)="verImagen()" *ngIf="modelImagen.imagen || modelImagen.image_after"></i>
    <app-reactive-camera *ngIf="formType < 3" (sendCameraFile)="fileChange($event)"></app-reactive-camera>
  </div>
</div>
